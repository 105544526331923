import { useState } from "react";
import { Icons } from "../../constants/Icons";
import "./table.css";

const StudentsDocumentsTable = () => {
    const [subjects, setSubjects] = useState([
        { id: 1, name: "lorem ipsum", file: "doc.zero", date: "23-23-23", tag: "Birth Certificate" },
      ]);
    
  return (
    <>
     <div className="studentTableContainer">
      <table className="table">
        <thead>
          <tr>
            <th>Name</th>
            <th>File</th>
            <th>Date</th>
            <th>Tag</th>
            <th className="delete-cell">Action</th>
          </tr>
        </thead>
        <tbody>
          {subjects.map(subject => (
            <tr key={subject.id}>
              <td>{subject.name}</td>
              <td>{subject.file}</td>
              <td>{subject.date}</td>
              <td>{subject.tag}</td>
              <td className="delete-cell">
                <img src={Icons.actionsIcon}
                alt="edit"
                  className="delete-icon"
                  />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};
export default StudentsDocumentsTable;