// import React, { useEffect, useState } from "react";
// import { ErrorMessage, Field, useFormik } from "formik";
// import * as Yup from "yup";
// import { Modal } from "react-bootstrap";
// import { Icons } from "../../../constants/Icons";
// import TextInputWithLabel from "../../applicationInput/ApplicationInput";
// import ApplicationButton from "../../applicationButton/ApplicationButton";


// const TeacherAddressModal = ({ show, handleClose, title }) => {
//   const formik = useFormik({
//     initialValues: {
//       email: "",
//       subject: "",
//       message: "",
//     },
//     validationSchema: Yup.object({
//       email: Yup.string().required("Email is required"),
//       subject: Yup.string().required("Subject is required"),
//       message: Yup.string().required("Message  is required"),
//     }),
//     onSubmit: (values, { resetForm }) => {
//       // setStudents(prevData => [...prevData, values]);
//       resetForm();
//     },
//   });

//   return (
//     <>
//       <Modal
//         show={show}
//         onHide={handleClose}
//         className="customModal"
//         backdrop="static"
//         centered
//         size="lg"
//       >
//         <Modal.Header>
//           <div className="d-flex align-items-center justify-content-between w-100">
//             <p className="mb-0 font16 fw500">{title}</p>
//             <div>
//               <img
//                 src={Icons.crossSky}
//                 alt="cross"
//                 className="crossModal"
//                 onClick={handleClose}
//               />
//             </div>
//           </div>
//         </Modal.Header>
//         <Modal.Body>
//           <form onSubmit={formik.handleSubmit}>
//             <div className="row w-100 paddingLeft20">
//               <div className="col-md-12 paddingBottom6">
//                 <TextInputWithLabel
//                   label="Email"
//                   name="email"
//                   placeHolder="Notification@custoking.com"
//                   customInputStyle=""
//                   customLabelStyle="fw600"
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.email}
//                   error={
//                     formik.touched.email && formik.errors.email
//                       ? formik.errors.email
//                       : null
//                   }
//                 />
//               </div>
//               <div className="col-md-12 paddingBottom6">
//                 <TextInputWithLabel
//                 type="text"
//                   label="Subject"
//                   name="subject"
//                   customInputStyle=""
//                   customLabelStyle="fw600"
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.subject}
//                   error={
//                     formik.touched.subject && formik.errors.subject
//                       ? formik.errors.subject
//                       : null
//                   }
//                 />
//               </div>
//               <div className="col-md-12 paddingBottom6">
//                 <label
//                   htmlFor="message"
//                   className="label-text-style fw600 "
//                 >
//                   Message
//                 </label>
//                 <textarea
//                   className="form-control"
//                   id="message"
//                   rows="6"
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.message}
//                   placeholder="0/50"
//                 ></textarea>
//                 {formik.touched.message && formik.errors.message ? (
//                   <div className="Inputerror">{formik.errors.message}</div>
//                 ) : null}
//               </div>
//             </div>
//             <div className=" w-100 row paddingTop30 paddingLeft20">
//               <div className="col-md-6">
//                 <ApplicationButton
//                   btnText={"Cancel"}
//                   className={"whiteBtn40"}
//                   onClick={handleClose}
//                 />
//               </div>
//               <div className="col-md-6">
//                 <ApplicationButton
//                   btnText={"Email"}
//                   className={"button40"}
//                   type="submit"
//                 />
//               </div>
//             </div>
//           </form>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default TeacherAddressModal;


import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../../applicationButton/ApplicationButton";
import CommonDropdown from "../../applicationDropdown/ApplicationDropdown";
import ToastBootstrap from "../../toast/Toast";
import { api } from "../../../services/commonApiService/CommonApiServices";
import { getApiUrl } from "../../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../../services/endPoints/ApiUrls";
import IndianStatesAndUTs from "../../../DTO/IndianStateAndUTs";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentAddressDetails } from "../../../redux/reduxToolkit/actions/StudentAction";
import { Modal } from "react-bootstrap";
import { Icons } from "../../../constants/Icons";
import { postTeacherAddress } from "../../../redux/reduxToolkit/actions/TeacherAction";
const studentAddressType = ["CURRENT", "PERMANENT"];
const TeacherAddressModal = ({ show, handleClose, title }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const getTeacherDetailsData = useSelector((state) => state.teacher.getTeacherDetailsData);
  const { techerAddressData, isLoadingtecherAddressDataStatus, techerAddressDataError} = useSelector((state) => state?.teacher);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      addressType: techerAddressData?.response ? techerAddressData?.response?.addressType : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.addressType: "",
      city: techerAddressData?.response ? techerAddressData?.response?.city : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.city: "",
      houseOrFlatNumber: "",
      streetName: techerAddressData?.response ? techerAddressData?.response?.street : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.street: "",
      landmark: "",
      stateName:techerAddressData?.response ? techerAddressData?.response?.state : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.state: "",
      district: techerAddressData?.response ? techerAddressData?.response?.district : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.district: "",
    },
    validationSchema: yup.object({
      addressType: yup.string().required("Address Type required"),
      city: yup.string().required("City Name Type required"),
      houseOrFlatNumber: yup.string().required("House/Flat Number  is required"),
      streetName: yup.string().required("Street Name is required"),
      landmark: yup.string().required("Landmark is required"),
      stateName: yup.string().required("State Name is required"),
      district: yup.string().required("district Name is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const teacherDto=localStorage.getItem("teacherDto")
      const payload= {
        "teacherDTO" :{
            "id" : teacherDto
        },
        "id": techerAddressData?.response ? techerAddressData?.response?.id : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.id: null ,
        "uuid": techerAddressData?.response ? techerAddressData?.response?.uuid : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.uuid: null,
        "createdDate": techerAddressData?.response ? techerAddressData?.response?.createdDate : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.createdDate: null,
        "addressType" : values.addressType,
        "city" : values.city,
        "state" : values.stateName,
        "district" : values.district,
        "street" : values.streetName
    
    }
    dispatch(postTeacherAddress(payload));
    setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
    if (techerAddressData?.httpStatusCode === 200) {
      handleClose();
      formik.resetForm();
      Swal.fire({
        icon: 'success',
        title: 'Teacher Address  Details',
        text: 'Teacher  Address Details saved',
        confirmButtonText: 'OK',
        timer: 5000,
        timerProgressBar: true,
      });
    } else if (techerAddressDataError) {
       setErrorMessage(techerAddressDataError);
       setShowErrorMessage(true);
    }
  }
  }, [hasSubmitted, techerAddressData, techerAddressDataError]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="customModal"
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="mb-0 font16 fw500">{title}</p>
            <div>
              <img
                src={Icons.crossSky}
                alt="cross"
                className="crossModal"
                onClick={handleClose}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
            <div className="col-md-6">
                <CommonDropdown
                  label={"Address Type"}
                  placeHolder={"Address Type"}
                  name="addressType"
                  customLabelStyle="greyLabel"
                  title={formik.values.addressType}
                  onSelect={(selectedValue) => formik.setFieldValue('addressType', selectedValue)}
                  items={studentAddressType}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.addressType}
                  error={
                    formik.touched.addressType && formik.errors.addressType
                      ? formik.errors.addressType
                      : null
                  }
                />
              </div>
              <div className="col-md-6">
                <TextInputWithLabel
                  label={"City"}
                  placeHolder={"Enter City"}
                  name="city"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.city}
                  error={
                    formik.touched.city && formik.errors.city
                      ? formik.errors.city
                      : null
                  }
                />
              </div>
              <div className="col-md-6  mt-2">
                <TextInputWithLabel
                  label={"House No"}
                  placeHolder={"Enter House No"}
                  name="houseOrFlatNumber"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.houseFlatNumber}
                  error={
                    formik.touched.houseFlatNumber && formik.errors.houseFlatNumber
                      ? formik.errors.houseFlatNumber
                      : null
                  }
                />
              </div>
              <div className="col-md-6  mt-2">
                <TextInputWithLabel
                  label={"Street"}
                  placeHolder={"Enter Street name"}
                  name="streetName"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.streetName}
                  error={
                    formik.touched.streetName && formik.errors.streetName
                      ? formik.errors.streetName
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <CommonDropdown
                  label={"State"}
                  placeHolder={"Enter State Name"}
                  name="stateName"
                  customLabelStyle="greyLabel"
                  items={IndianStatesAndUTs}
                  title={formik.values.stateName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onSelect={(selectedValue) =>
                    formik.setFieldValue("stateName", selectedValue)
                  }
                  value={formik.values.stateName}
                  error={
                    formik.touched.stateName && formik.errors.stateName
                      ? formik.errors.stateName
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <TextInputWithLabel
                  label={"Landmark"}
                  placeHolder={"Enter Landmark"}
                  name="landmark"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.landmark}
                  error={
                    formik.touched.landmark && formik.errors.landmark
                      ? formik.errors.city
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <TextInputWithLabel
                  label={"District"}
                  placeHolder={"Enter District"}
                  name="district"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.district}
                  error={
                    formik.touched.district && formik.errors.district
                      ? formik.errors.district
                      : null
                  }
                />
              </div>
             

            </div>
            {errorMessage ?
              <ToastBootstrap showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage} className={"toastErrorMessage"} message={errorMessage} /> : null
            }
            <div className="row pt30 justify-content-end">
              <div className="col-md-2">
                <ApplicationButton btnText={"Save"} type="submit" className="blueheight40" />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TeacherAddressModal;

