import React, { useCallback, useEffect, useState } from "react";
import "./Attandence.css";
import UserLayout from "../../components/layout/UserLayout";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";
import { Icons } from "../../constants/Icons";
import AttendanceTable from "./AttandenceTable";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import decodeJWT from "../../utils/Decode";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getApiUrl } from "../../services/urlConfig/UrlConfig";
import ToastBootstrap from "../../components/toast/Toast";
import _debounce from 'lodash/debounce';
import { useDispatch, useSelector } from "react-redux";
import { fetchMatchingStudentName } from "../../redux/reduxToolkit/actions/StudentAction";
import { clearMatchingStudentName } from "../../redux/reduxToolkit/slices/StudentSlice";
import { fetchGetAttandence } from "../../redux/reduxToolkit/actions/AttandenceAction";
import { cFormatDate} from "../../utils/YearMonthDay";
import CustomAttendanceTable from "./CustomAttandence";
const Attendance = () => {
  localStorage.removeItem("eventResponse");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const selectedDate = cFormatDate(new Date());
  const [searchTerm, setSearchTerm] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [studentsCountData, setStudentCountData] = useState("");
  const [studentsData, setStudentData] = useState("");
  const [dateFromDatePicker, setDateFromDatePicker] = useState("");
  const [dateFrom7Dayes, setDateFrom7Dayes] = useState("");
  const [dateFromYesterDay, setDateFromFromYesterDay,] = useState("");
  const [dateFromToday, setDateFromToday] = useState("");
  const token = localStorage.getItem("token");
  const encodedToken = decodeJWT(token);
  const workspaceIdd = encodedToken?.payload?.workspaceId;
  const dispatch = useDispatch();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
  const handleChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    let selectedStartDateCalender = new Date(start);
    let selectedEndDateCalender = new Date(end);
    setSelectedDateRange(
      `custom date: ${selectedStartDateCalender.toLocaleDateString()} - ${selectedEndDateCalender.toLocaleDateString()}`
    );
    if (start && end) {
      setIsOpen(false);
    }
    const selectiondate = {
      "startDate": cFormatDate(selectedStartDateCalender),
      "endDate": cFormatDate(selectedEndDateCalender)
    };
    setDateFromDatePicker(selectiondate);
    dispatch(fetchGetAttandence(selectiondate));
    setDateFrom7Dayes('');
    setDateFromFromYesterDay('');
    setDateFromToday('');
  };
  const openCalender = () => {
    setIsOpenCard(false);
    setIsOpen(true);
  };

  const [isOpenCard, setIsOpenCard] = useState(false);

  const handleMouseEnter = () => {
    setIsOpenCard(true);
  };

  const handleMouseLeave = () => {
    setIsOpenCard(false);
  };
 
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const handleYesterdayClick = () => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const formattedDate = cFormatDate(yesterday);
    setSelectedDateRange(formattedDate);
    setIsOpenCard(false);
      const selectiondate = {
        "startDate": formattedDate,
        "endDate": formattedDate
      };
      dispatch(fetchGetAttandence(selectiondate));
      setDateFromFromYesterDay(selectiondate)
      setDateFromDatePicker('')
      setDateFrom7Dayes('')
      setDateFromToday('');
  };
  const todayRecords=()=>{
    const selectiondate = {
      "startDate": cFormatDate(new Date()),
      "endDate": cFormatDate(new Date())
    };
    dispatch(fetchGetAttandence(selectiondate));
    setDateFromDatePicker('')
    setDateFrom7Dayes('')
    setDateFromToday(selectiondate);
    setDateFromFromYesterDay('')
  }
  
  const handleLast7DaysClick = () => {
    const today = new Date();
    const last7Days = new Date();
    last7Days.setDate(today.getDate() - 7);
    setSelectedDateRange(
      `Last 7 Days: ${last7Days.toLocaleDateString()} - ${today.toLocaleDateString()}`
    );
    setIsOpenCard(false);
    const selectiondate = {
      "startDate":cFormatDate(last7Days),
      "endDate":cFormatDate(today), 
    };
    dispatch(fetchGetAttandence(selectiondate));
    setDateFrom7Dayes(selectiondate)
    setDateFromDatePicker('');
    setDateFromFromYesterDay('');
    setDateFromToday('');
  };
  const getStudentCountApi = () => {
    api
      .userGet(getApiUrl() + apiUrls.student.getStudentCount(workspaceIdd))
      .then((response) => {
        if (response.data.httpStatusCode === 200) {
          setStudentCountData(response?.data?.response);
          return response?.data?.response;
        } else {
          setErrorMessage(response.data.message);
          setShowErrorMessage(true);
        }
      })
      .catch((error) => {
        setErrorMessage(
          error.response?.data?.message ||
            "An unexpected error occurred. Please try again."
        );
        setShowErrorMessage(true);
      });
  };

  const studentTableApi = () => {
    api
      .userGet(getApiUrl() + apiUrls.student.getStudent(workspaceIdd))
      .then((response) => {
        if (response.data.httpStatusCode === 200) {
          setStudentData(response?.data?.response);
        } else {
          //   setErrorMessage(response.data.message);
          //   setShowErrorMessage(true);
        }
      })
      .catch((error) => {
        // setErrorMessage(
        //   error.response?.data?.message ||
        //     "An unexpected error occurred. Please try again."
        // );
        // setShowErrorMessage(true);
      });
  };
  useEffect(() => {
    studentTableApi();
    getStudentCountApi();
  }, []);

  const searchStudentName = async (inputValue) => {
    try {
        if (inputValue) {
            dispatch(fetchMatchingStudentName(inputValue));
        } else {
            dispatch(clearMatchingStudentName());
        }
    } catch (error) {
        console.error("Error fetching data:", error);
    }
};

const debounceFn = useCallback(
    _debounce((inputValue) => searchStudentName(inputValue), 1000),
    []
);

// Handle input change
const handleChangeDate = (event) => {
  const inputValue = event.target.value.trim();
  localStorage.setItem("eventResponse", inputValue);
  setValue(inputValue);
  debounceFn(inputValue);
};

  return (
    <UserLayout>
      <ApplicationHeader
        title="Attandence"
        calender
        currentDate={selectedDateRange ? selectedDateRange : selectedDate}
        attandenceTable={studentsData}
      />
      {studentsCountData>0?<>
        <div className="secondHeader">
        <div className="leftSecondHeader">
          <div
            className="tagBox position-relative"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={Icons.greyCalenderIcon}
              className="filterImage"
              alt="filter"
            />
            {isOpenCard && (
              <div className="card-custom">
                <ul>
                <li onClick={todayRecords}> Today</li>
                  <li onClick={handleYesterdayClick}>Yesterday</li>
                  <li onClick={handleLast7DaysClick}>Last 7 days</li>
                  <li onClick={openCalender}>Custom</li>
                </ul>
              </div>
            )}

            <p className="mb-0 font14 fw500 filterText">Filter</p>
          </div>
          <DatePicker
            selected={startDate}
            onChange={handleChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            onClickOutside={() => setIsOpen(false)}
            onCalendarClose={() => setIsOpen(false)}
            open={isOpen}
            onInputClick={() => setIsOpen(true)}
            placeholderText="Select a date range"
            customInput={<input style={{ display: "none" }} />}
            dateFormat="dd/MM/yyyy"
            maxDate={new Date()}
            minDate={oneYearAgo}
          />
          <div className="totalStudentsCount">
            <p className="mb-0 font14 fw500 totalStudents text-nowrap">
              {studentsCountData} Students
            </p>
          </div>
        </div>
        <div className="rightSecondHeader">
          <div className="searchBox">
            <input
              type="text"
              placeholder="Search student here...."
              value={value} onChange={handleChangeDate}
            />
            <div className="searchIcon">
              <img src={Icons.searchIcon} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>
      </>:null}
    
      <div className="studentTableContainer attandenceTableContainer">
        {dateFromDatePicker ||dateFrom7Dayes || dateFromYesterDay || dateFromToday?(<CustomAttendanceTable dateFromDatePicker={dateFromDatePicker || dateFromYesterDay || dateFromToday ?dateFromDatePicker || dateFromYesterDay || dateFromToday:dateFrom7Dayes}/>):(  <AttendanceTable/>)}
      </div>
      {errorMessage ? (
        <ToastBootstrap
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          className={"toastErrorMessage"}
          message={errorMessage}
        />
      ) : null}
    </UserLayout>
  );
};

export default Attendance;
