import React from "react";
import UserLayout from "../../../../components/layout/UserLayout";
import ApplicationHeader from "../../../../components/applicationHeader/ApplicationHeader";
import "./SetUp.css";
import { Images } from "../../../../constants/Images";
import ApplicationButton from "../../../../components/applicationButton/ApplicationButton";

const SetUp = () => {
    return (
        <UserLayout>
            <ApplicationHeader title="Setup" />
            <div className="setUpContainer">
                <div className="setUpBox">
                    <div className="setupLeftBox">
                        <img src={Images.setUpImage} className="setUpImage" />
                        <p className="mb-0 font28 fw600">Class</p>
                    </div>
                    <ApplicationButton btnText={"Setup"} className={"blueheight40"} />
                </div>
                <div className="setUpBox">
                    <div className="setupLeftBox">
                        <img src={Images.setUpImage} className="setUpImage" />
                        <p className="mb-0 font28 fw600">Students</p>
                    </div>
                    <ApplicationButton btnText={"Setup"} className={"blueheight40"} />
                </div>
                <div className="setUpBox">
                    <div className="setupLeftBox">
                        <img src={Images.setUpImage} className="setUpImage" />
                        <p className="mb-0 font28 fw600">Employee</p>
                    </div>
                    <ApplicationButton btnText={"Setup"} className={"blueheight40"} />
                </div>
            </div>
        </UserLayout>
    )
}

export default SetUp;