const FirstLastNameAvatar = ({ name = '' }) => {
    if (typeof name !== 'string') {
      console.error('RenderAvatar expects a string as the name prop');
      return null;
    }
  
    const nameParts = name.split(' ');
  
    const firstInitial = nameParts[0] ? nameParts[0][0] : '';
    const lastInitial = nameParts.length > 1 ? nameParts[nameParts.length - 1][0] : '';
  
    const initials = firstInitial + lastInitial;
  
    return <div>{initials.toUpperCase()}</div>;
  };
  
  export default FirstLastNameAvatar;