export function formatDate(dateInput) {
    const date = new Date(dateInput);
  
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
  
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    const formattedDateWithSpaces = `${month} ${day} ${year}`;
  
    return isValidDateFormat(formattedDate) ||
      isValidDateFormat(formattedDateWithSpaces)
      ? formattedDate
      : "Invalid Date";
  }
  
  function isValidDateFormat(dateString) {
    const dateFormatRegex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;
    const dateFormatWithSpacesRegex =
      /^(0[1-9]|1[0-2]) (0[1-9]|[12][0-9]|3[01]) \d{4}$/;
  
    return (
      dateFormatRegex.test(dateString) ||
      dateFormatWithSpacesRegex.test(dateString)
    );
  }
  