import React, { useState } from 'react';
import './Toast.css';
import Toast from 'react-bootstrap/Toast';

const ToastBootstrap = ({ className, message, showErrorMessage, setShowErrorMessage }) => {
  return (
    <Toast className={className} onClose={() => setShowErrorMessage(false)} show={showErrorMessage} delay={8000} autohide>
      <Toast.Header>
        <img
          src="holder.js/20x20?text=%20"
          className="rounded me-2"
          alt=""
        />
       </Toast.Header>
      <Toast.Body>{message}</Toast.Body>
    </Toast>
  );
};

export default ToastBootstrap;
