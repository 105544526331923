import React, { useState } from 'react';
import { Modal, Form } from 'react-bootstrap';
import { Icons } from '../../constants/Icons';
import "./StudentSideNavbar.css"

const InformationSection = ({
    modalBodyComponent: ModalBodyComponent,
    modalTitle,
    show,
    handleShow,
    handleClose,
    handleToggle,
    informationBody,
    title,
    activeSwitch
}) => {
    const [showMore, setShowMore] = useState(false);
    const displayedItems = showMore ? informationBody : informationBody.slice(0, 3);
    return (
        <>
            <div className="informationBox">
                <div className="infomationHeader">
                    <div className="informationHeaderLeft cursor-pointer" onClick={handleShow}>
                        <p className="mb-0 font16 fw600">{title}</p>
                        <img src={Icons.editBlack} alt="edit" className="edit" />
                    </div>
                    {informationBody?.length > 3 ?
                        <div className="informationHeaderRight">
                            <button className="seeMore" onClick={() => setShowMore(!showMore)}>
                                {showMore ? 'See less' : 'See more'}
                            </button>
                        </div>
                        : null}
                </div>
                <div className="informationBody">
                    {displayedItems?.map((item, index) => {
                        return (
                            <React.Fragment key={item.id}>
                                <div className="informationBodyli">
                                    {item.icon?
                                    <img src={item.icon} alt="icon" />
                    : null}
                                    <p className="text font12 fw500">{item.text} : {item.infoValue?item.infoValue: "..." }</p>
                                </div>
                                {index < displayedItems.length - 1 && <div className="informationSeparator" />}
                            </React.Fragment>
                        );
                    })}
                    {activeSwitch ?
                        <div className="activeSwitch">
                            <h6 className="mb-0 font14 fw600">Active</h6>
                            <div>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="custom-switch"
                                        onChange={handleToggle}
                                        className="switchStatus"
                                    />
                                </Form>
                            </div>
                        </div>
                        : null}
                </div>
            </div>
            <Modal show={show} onHide={handleClose} className="customModal" backdrop="static" centered size="lg">
                <Modal.Header>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <p className="mb-0 font16 fw500">{modalTitle}</p>
                        <div>
                            <img src={Icons.crossSky} alt="cross" className="crossModal" onClick={handleClose} />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {ModalBodyComponent && <ModalBodyComponent handleClose={handleClose} />}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default InformationSection;
