import axios from "axios";
import { baseURL } from "../baseUrl/BaseUrl";
import GetCookie from "../../persistance/cookies/GetCookies";
import handleLogout from "./Logout";
// import { emitEvent } from '../navigation/EventEmitter';

// const baseURL = baseURL;

const apiInterceptor = axios.create({ baseURL });

// Request Interceptor to attach token if available
apiInterceptor.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers['authToken'] = token;
    }
    return config;
}, error => Promise.reject(error));

// Response Interceptor to handle unauthorized access
apiInterceptor.interceptors.response.use(
    response => response,
    async error => {
        if (error.response && error.response.status === 401) {
            // Perform logout when 401 is received
            handleLogout();
        }
        return Promise.reject(error);
    }
);

// Handle API responses and errors
const handleResponse = async (promise) => {
    try {
        const response = await promise;
        return { success: true, data: response.data };
    } catch (error) {
        console.error('API Error:', error);
        return {
            success: false,
            data: error.response ? error.response.data : error.message
        };
    }
};

// API call methods
const userPost = async (url, data) => {
    const token = localStorage.getItem('token'); // Get the authToken
    const headers = token ? { 'authToken': token } : {}; // Include token in headers if available
    return handleResponse(apiInterceptor.post(url, data, { headers }));
};
const publicPost = (url, data) => {
    const headers = { 'Content-Type': 'application/json' };
    return handleResponse(axios.post(url, data, { headers }));
};
const publicGet = (url, authtoken) => {
    const headers = {
        'Content-Type': 'application/json',
        "authToken": authtoken // Ensure this header is expected by your API
    };
    return handleResponse(axios.get(url, { headers }));
};
const userGet = async (url) => {
    const token = localStorage.getItem('token'); // Get the authToken
    const headers = token ? { 'authToken': token } : {}; // Include token in headers if available
    return handleResponse(apiInterceptor.get(url, { headers }));
};

// Uncomment if needed
// const userGetWithLoading = async (url) => {
//     // ApplicationLoader.show();
//     try {
//         const response = await apiInterceptor.get(url);
//         // ApplicationLoader.hide();
//         return { success: true, data: response.data };
//     } catch (error) {
//         // ApplicationLoader.hide();
//         return {
//             success: false,
//             data: error.response ? error.response.data : error.message
//         };
//     }
// };

// Uncomment if needed
// const logout = async () => {
//     try {
//         await apiInterceptor.post('/logout');
//         localStorage.removeItem('token');
//     } catch (error) {
//         console.error('Error during logout:', error);
//     }
// };

export const api = {
    userPost,
    publicPost,
    publicGet,
    userGet,
    // userGetWithLoading,
    // logout
};
