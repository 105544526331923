import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import ToastBootstrap from "../toast/Toast";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentFinancialDetails } from "../../redux/reduxToolkit/actions/StudentAction";
const FinancialDetailsModal = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const studentsDetails = useSelector(
    (state) => state.student.getStudentDetails
  );
  const {
    studentFinancilDetails,
    isLoadingstudentFinancialDetailsStatus,
    studentFinancilDetailsError,
  } = useSelector((state) => state?.student);
  const formik = useFormik({
    initialValues: {
      accountNo: studentFinancilDetails ? studentFinancilDetails?.response?.bankAccountNo : studentsDetails?.StudentFinancialDTO ? studentsDetails?.StudentFinancialDTO?.bankAccountNo: "",
      bankName: studentFinancilDetails ? studentFinancilDetails?.response?.bankName : studentsDetails?.StudentFinancialDTO ? studentsDetails?.StudentFinancialDTO?.bankName: "",
      ifscCode: studentFinancilDetails ? studentFinancilDetails?.response?.ifscCode : studentsDetails?.StudentFinancialDTO ? studentsDetails?.StudentFinancialDTO?.ifscCode: "",
      securityDeposit: studentFinancilDetails ? studentFinancilDetails?.response?.securityDeposit : studentsDetails?.StudentFinancialDTO ? studentsDetails?.StudentFinancialDTO?.securityDeposit: "",
      fineAmount: studentFinancilDetails ? studentFinancilDetails?.response?.fineAmount : studentsDetails?.StudentFinancialDTO ? studentsDetails?.StudentFinancialDTO?.fineAmount: "",
    },
    validationSchema: yup.object({
      accountNo: yup.string().required("Account Number  is required"),
      bankName: yup.string().required("Bank Name is required"),
      ifscCode: yup.string().required("IFSC  Code  is required"),
      securityDeposit: yup.string().required("Security Deposit  is required"),
      fineAmount: yup.string().required("Fine Amount  is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const studentDto = localStorage.getItem("studentDto");
      const payload = {
        "studentDTO": {
          "id": studentDto,
        },
        "id": studentFinancilDetails
          ? studentFinancilDetails?.response?.id
          : studentsDetails?.StudentFinancialDTO
          ? studentsDetails?.StudentFinancialDTO?.id
          : null,
        "uuid": studentFinancilDetails
          ? studentFinancilDetails?.response?.uuid
          : studentsDetails?.StudentFinancialDTO
          ? studentsDetails?.StudentFinancialDTO?.uuid
          : null,
        "createdDate": studentFinancilDetails
          ? studentFinancilDetails?.response?.createdDate
          : studentsDetails?.StudentFinancialDTO
          ? studentsDetails?.StudentFinancialDTO?.createdDate
          : null,
        "bankName": values.bankName,
        "bankAccountNo": values.accountNo,
        "ifscCode": values.ifscCode,
        "securityDeposit": values.securityDeposit,
        "fineAmount": values.fineAmount,
      };
      dispatch(fetchStudentFinancialDetails(payload));
      setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
      if (studentFinancilDetails.httpStatusCode === 200) {
        props.handleClose();
        formik.resetForm();
        Swal.fire({
          icon: "success",
          title: " Financial Details",
          text: "Student Financial Details saved",
          confirmButtonText: "OK",
          timer: 5000,
          timerProgressBar: true,
        });
      } else if (studentFinancilDetailsError) {
         setErrorMessage(studentFinancilDetailsError);
         setShowErrorMessage(true);
      }
    }
  }, [hasSubmitted, studentFinancilDetails, studentFinancilDetailsError]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Account No"}
              placeHolder={"Enter Account No"}
              name="accountNo"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.accountNo}
              error={
                formik.touched.accountNo && formik.errors.accountNo
                  ? formik.errors.accountNo
                  : null
              }
            />
          </div>
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Bank Name"}
              placeHolder={"Enter Bank Name"}
              name="bankName"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.bankName}
              error={
                formik.touched.bankName && formik.errors.bankName
                  ? formik.errors.bankName
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"IFSC code"}
              placeHolder={"Enter IFSC code"}
              name="ifscCode"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.ifscCode}
              error={
                formik.touched.ifscCode && formik.errors.ifscCode
                  ? formik.errors.ifscCode
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Security Deposit"}
              placeHolder={"Security Deposit"}
              name="securityDeposit"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.securityDeposit}
              error={
                formik.touched.securityDeposit && formik.errors.securityDeposit
                  ? formik.errors.securityDeposit
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Fine Amount"}
              placeHolder={"Enter Fine Amount"}
              name="fineAmount"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fineAmount}
              error={
                formik.touched.fineAmount && formik.errors.fineAmount
                  ? formik.errors.fineAmount
                  : null
              }
            />
          </div>
        </div>
        {errorMessage ? (
          <ToastBootstrap
            showErrorMessage={showErrorMessage}
            setShowErrorMessage={setShowErrorMessage}
            className={"toastErrorMessage"}
            message={errorMessage}
          />
        ) : null}
        <div className="row pt30 justify-content-end">
          <div className="col-md-2">
            <ApplicationButton
              btnText={"Save"}
              type="submit"
              className="blueheight40"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default FinancialDetailsModal;
