import React, { useEffect, useState } from "react";
import UserLayout from "../../components/layout/UserLayout";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";
import TeacherTable from "./TeacherTable";
import TeacherHeader from "../../components/applicationHeader/teacherHeader/TeacherHeader";
import { useSelector } from "react-redux";
import CommonSpinner from "../../components/spinner/Spinner";
import TeacherEmptyState from "../../components/emptyState/NoTeacherFound";


const Teacher = () => {
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const handlePostSuccess = () => {
    setShouldFetchData(true);
  };
  useEffect(() => {
    localStorage.removeItem("eventResponse")
  }, [])
  const { teacherCountData, isLoadingTeacherCountDataStatus, isLoadingmatchStudentStatus } = useSelector((state) => state?.teacher);
  return (
    <UserLayout>
      <ApplicationHeader title="Teacher" />
      <TeacherHeader onSuccess={handlePostSuccess} />
      <div className="studentTableContainer">
      {isLoadingTeacherCountDataStatus && isLoadingmatchStudentStatus ?
          <CommonSpinner /> :
          teacherCountData > 0 ?
            <TeacherTable shouldFetchData={shouldFetchData} setShouldFetchData={setShouldFetchData} />
            :
            <TeacherEmptyState /> 
        }
      </div>
    </UserLayout>
  )
}

export default Teacher;