import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../../applicationButton/ApplicationButton";
import CommonDropdown from "../../applicationDropdown/ApplicationDropdown";
import IndianStatesAndUTs from "../../../DTO/IndianStateAndUTs";
import ToastBootstrap from "../../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Icons } from "../../../constants/Icons";
import { postTeacherPersonalDetails } from "../../../redux/reduxToolkit/actions/TeacherAction";
import Swal from "sweetalert2";

const categoryData = ["General", "OBC", "EWS", "SC", "ST"];
const bloodGroups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];
const PersonalDetailsTeacher = ({ show, handleClose, title }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const getTeacherDetailsData = useSelector((state) => state.teacher.getTeacherDetailsData);
  const {teacherPersonalDetailsData, isLoadingteacherPersonalDetailsDataStatus, teacherPersonalDetailsDataError} = useSelector((state) => state?.teacher);

  console.log(teacherPersonalDetailsData,"teacherPersonalDetailsData");
  console.log(getTeacherDetailsData,"teacherDetailsss");
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      domicile: teacherPersonalDetailsData ? teacherPersonalDetailsData?.response?.domicile : getTeacherDetailsData?.teacherPersonalDTO? getTeacherDetailsData?.teacherPersonalDTO?.domicile: "",
      category: teacherPersonalDetailsData ? teacherPersonalDetailsData?.response?.category : getTeacherDetailsData?.teacherPersonalDTO? getTeacherDetailsData?.teacherPersonalDTO?.category: "",
      bloodGroup: teacherPersonalDetailsData ? teacherPersonalDetailsData?.response?.bloodGroup : getTeacherDetailsData?.teacherPersonalDTO? getTeacherDetailsData?.teacherPersonalDTO?.bloodGroup: "",
      allergies: teacherPersonalDetailsData ? teacherPersonalDetailsData?.response?.allergies : getTeacherDetailsData?.teacherPersonalDTO? getTeacherDetailsData?.teacherPersonalDTO?.allergies: "",
      medicalConditions: teacherPersonalDetailsData ? teacherPersonalDetailsData?.response?.medicalConditions : getTeacherDetailsData?.teacherPersonalDTO? getTeacherDetailsData?.teacherPersonalDTO?.medicalConditions: "",
    },
    validationSchema: yup.object({
      domicile: yup.string().required("Domicile is required"),
      category: yup.string().required("Category is required"),
      bloodGroup: yup.string().required("Blood Group is required"),
      allergies: yup.string().required("Allergies is required"),
      medicalConditions: yup
        .string()
        .required("Medical Conditions is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const teacherDto = localStorage.getItem("teacherDto");
      const payload = {
        "teacherDTO": {
          "id": teacherDto,
        },
        "id": teacherPersonalDetailsData ? teacherPersonalDetailsData?.response?.id : getTeacherDetailsData?.teacherPersonalDTO? getTeacherDetailsData?.teacherPersonalDTO?.id: null ,
        "uuid": teacherPersonalDetailsData ? teacherPersonalDetailsData?.response?.uuid : getTeacherDetailsData?.teacherPersonalDTO? getTeacherDetailsData?.teacherPersonalDTO?.uuid: null,
        "createdDate": teacherPersonalDetailsData ? teacherPersonalDetailsData?.response?.createdDate : getTeacherDetailsData?.teacherPersonalDTO? getTeacherDetailsData?.teacherPersonalDTO?.createdDate: null,
        "bloodGroup": values.bloodGroup,
        "category": values.category,
        "domicile": values.domicile,
        "foodHabits": values.foodHabits,
        "allergies": values.allergies,
      };
      dispatch(postTeacherPersonalDetails(payload));
      setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
    if (teacherPersonalDetailsData?.httpStatusCode === 200) {
      handleClose();
      formik.resetForm();
      Swal.fire({
        icon: 'success',
        title: 'Teacher Address  Details',
        text: 'Teacher  Address Details saved',
        confirmButtonText: 'OK',
        timer: 5000,
        timerProgressBar: true,
      });
    } else if (teacherPersonalDetailsDataError) {
       setErrorMessage(teacherPersonalDetailsDataError);
       setShowErrorMessage(true);
    }
  }
  }, [hasSubmitted, teacherPersonalDetailsData, teacherPersonalDetailsDataError]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="customModal"
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="mb-0 font16 fw500">{title}</p>
            <div>
              <img
                src={Icons.crossSky}
                alt="cross"
                className="crossModal"
                onClick={handleClose}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 mt-2">
                <CommonDropdown
                  label={"Domicile"}
                  placeHolder={"Enter Domicile"}
                  name="domicile"
                  customLabelStyle="greyLabel"
                  items={IndianStatesAndUTs}
                  title={formik.values.domicile}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onSelect={(selectedValue) =>
                    formik.setFieldValue("domicile", selectedValue)
                  }
                  value={formik.values.domicile}
                  error={
                    formik.touched.domicile && formik.errors.domicile
                      ? formik.errors.domicile
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <CommonDropdown
                  label={"Category"}
                  placeHolder={"Enter Category"}
                  name="category"
                  customLabelStyle="greyLabel"
                  items={categoryData}
                  title={formik.values.category}
                  onSelect={(selectedValue) =>
                    formik.setFieldValue("category", selectedValue)
                  }
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.category}
                  error={
                    formik.touched.category && formik.errors.category
                      ? formik.errors.category
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <CommonDropdown
                  label={"Blood Group"}
                  placeHolder={"Enter Blood Group"}
                  name="bloodGroup"
                  customLabelStyle="greyLabel"
                  items={bloodGroups}
                  title={formik.values.bloodGroup}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  onSelect={(selectedValue) =>
                    formik.setFieldValue("bloodGroup", selectedValue)
                  }
                  value={formik.values.bloodGroup}
                  error={
                    formik.touched.bloodGroup && formik.errors.bloodGroup
                      ? formik.errors.bloodGroup
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <TextInputWithLabel
                  label={"Allergies"}
                  placeHolder={"Enter Your Allergies"}
                  name="allergies"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.allergies}
                  error={
                    formik.touched.allergies && formik.errors.allergies
                      ? formik.errors.allergies
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <TextInputWithLabel
                  label={"Medical Conditions"}
                  placeHolder={"Enter Medical Conditions"}
                  name="medicalConditions"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.medicalConditions}
                  error={
                    formik.touched.medicalConditions &&
                      formik.errors.medicalConditions
                      ? formik.errors.medicalConditions
                      : null
                  }
                />
              </div>
            </div>
            <div className="row pt30 justify-content-end">
              <div className="col-md-2">
                <ApplicationButton
                  btnText={"Save"}
                  type="submit"
                  className="blueheight40"
                />
              </div>
            </div>
          </form>
          {errorMessage ? (
            <ToastBootstrap
              showErrorMessage={showErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
              className={"toastErrorMessage"}
              message={errorMessage}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PersonalDetailsTeacher;

