import React, { useEffect, useState } from "react";
import "./UserCard.css";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getApiUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import { useNavigate } from "react-router-dom";
import SetCookie from "../../persistance/cookies/SetCookies";
import ToastBootstrap from "../../components/toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { WorkspaceSignInPost } from "../../redux/reduxToolkit/actions/authAction";

const CardManager = ({ workspaceData }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [selectedCardId, setSelectedCardId] = useState(null);
  const dispatch = useDispatch();
  const {workspaceSignIn, isLoadingWorkspaceSignIn, workspaceSignInError} = useSelector((state) => state?.auth);
  console.log(workspaceSignIn,"workspaceSignIn")
  const getWorkspaceById = (id) => {
    const payload = {
      id: id,
    };
    dispatch(WorkspaceSignInPost(payload));
  };
  useEffect(() => {
    if (!isLoadingWorkspaceSignIn && workspaceSignIn) {
        localStorage.setItem("token", workspaceSignIn?.response);
      navigate('/'); 
    }
  }, [isLoadingWorkspaceSignIn, workspaceSignIn]);
  const handleRadioChange = (id) => {
    setSelectedCardId(id);
    getWorkspaceById(id);
  };
  return (
    <>
      <div className="row userCard">
        {workspaceData?.map((data) => (
          <div className="col-md-2" key={data.id}>
            <input
              type="radio"
              id={`card-${data.id}`}
              name="workspaceCard"
              checked={selectedCardId === data.id}
              onChange={() => handleRadioChange(data.id)}
            />
            <label htmlFor={`card-${data.id}`} className="workspaceCard">
              <p className="mb-0 font16 fw600">{data?.name}</p>
            </label>
          </div>
        ))}
      </div>
      {errorMessage ? (
        <ToastBootstrap
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          className={"toastErrorMessage"}
          message={errorMessage}
        />
      ) : null}
    </>
  );
};

export default CardManager;
