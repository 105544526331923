import { useState } from "react";
import { Icons } from "../../constants/Icons";
import "./table.css";

const SubjectTable = () => {
    const [subjects, setSubjects] = useState([
        { id: 1, name: "English", duration: "2023-24", type: "Compulsory", marks: "" },
        // You can add more subjects here
      ]);
    
      const handleDelete = (id) => {
        const updatedSubjects = subjects.filter(subject => subject.id !== id);
        setSubjects(updatedSubjects);
      };
    
      const handleMarksChange = (id, value) => {
        const updatedSubjects = subjects.map(subject =>
          subject.id === id ? { ...subject, marks: value } : subject
        );
        setSubjects(updatedSubjects);
      };
    
  return (
    <>
     <div className="studentTableContainer">
      <table className="table">
        <thead>
          <tr>
            <th>Subject</th>
            <th>Duration</th>
            <th>Subject Type</th>
            <th>Marks</th>
            <th className="delete-cell"></th>
          </tr>
        </thead>
        <tbody>
          {subjects.map(subject => (
            <tr key={subject.id}>
              <td>{subject.name}</td>
              <td>{subject.duration}</td>
              <td>{subject.type}</td>
              <td>
                <input
                  type="text"
                  value={subject.marks}
                  onChange={(e) => handleMarksChange(subject.id, e.target.value)}
                  className="marks-input"
                />
              </td>
              <td className="delete-cell">
                <img src={Icons.delete}
                alt="delete"
                  className="delete-icon"
                  onClick={() => handleDelete(subject.id)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    </>
  );
};
export default SubjectTable;