import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import decodeJWT from "../../../utils/Decode";
import { Icons } from "../../../constants/Icons";
import ApplicationButton from "../../applicationButton/ApplicationButton";
import { Dropdown, Modal } from "react-bootstrap";
import TeacherAddModalBody from "../../../pages/teacher/TeacherModalBody.js";
import ToastBootstrap from "../../toast/Toast.js";
import { fetchExportDataTeacher, fetchMatchingTeacherName, fetchTeacherCount } from "../../../redux/reduxToolkit/actions/TeacherAction.js";
import _debounce from "lodash/debounce";
const TeacherHeader = ({ onSuccess }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const handleShowEmail = (title) => {
    setModalTitle(title);
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  const [value, setValue] = useState("");
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const encodedToken = decodeJWT(token);
  const workspaceIdd = encodedToken?.payload?.workspaceId;
  let { teachersData, isLoadingTeachersDataStatus, teachersDataError,exportDataTeacher, isLoadingExportDataTeacherStatus,exportDataTeacherError} =
    useSelector((state) => state?.teacher);
    const [clicked, setClicked] = useState(false); 
    const showExportDataTeacher = () => {
      setClicked(true); 
      dispatch(fetchExportDataTeacher())
    };
    
    const searchTeacherName = async (inputValue) => {
      try {
        if (inputValue) {
          dispatch(fetchMatchingTeacherName(inputValue));
        } else {
          dispatch(fetchMatchingTeacherName());
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const debounceFn = useCallback(
      _debounce((inputValue) => searchTeacherName(inputValue), 1000),
      []
    );
    const handleChange = (event) => {
      const inputValue = event.target.value.trim();
      localStorage.setItem("eventResponse", inputValue);
      setValue(inputValue);
      debounceFn(inputValue);
    };

    useEffect(() => {
      if (clicked && exportDataTeacher) { 
        const downloadUrl = exportDataTeacher;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'file.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setClicked(false);
      }
    }, [exportDataTeacher, clicked]);
    useEffect(() => {
      dispatch(fetchTeacherCount(workspaceIdd));
    }, [dispatch]);
    const { teacherCountData } = useSelector((state) => state?.teacher);
  return (
    <>
      <div className="secondHeader">
        <div className="leftSecondHeader">
        {teacherCountData > 0 ? (
          <>
            <div className="tagBox">
              <div className="tagImage">
                <img src={Icons.tag} className="img-fluid" alt="tag" />
              </div>
              <p className="mb-0 font14 fw500 tagText">Tag</p>
            </div>
            <div className="totalStudentsCount">
              <p className="mb-0 font14 fw500 totalStudents">
                {teacherCountData} Teacher
              </p>
            </div>
          </>
           ) : null}
        </div>
        <div className="rightSecondHeader">
        {teacherCountData > 0 ? (
          <div className="searchBox">
            <input name="studentName"    value={value}
                onChange={handleChange} placeholder="Enter Teacher Name...." />
            <div className="searchIcon">
              <img
                alt="icon-search"
                src={Icons.searchIcon}
                className="img-fluid"
              />
            </div>
          </div>
  ) : null}
          <div className="addStudentButton">
            <ApplicationButton
              btnText="+ Add Teacher"
              className={"button40"}
              onClick={handleShow}
            />
          </div>
          {/* <div className="shareBtnStudent">
            <Dropdown>
              <Dropdown.Toggle variant="undefined" id="dropdown-basic-student">
                Share
                <img
                  src={Icons.downArrow}
                  className="horizontalDot downarrowShareBtn"
                  alt="options"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="customDropdownMenu">
                <Dropdown.ItemText>Email All Pages</Dropdown.ItemText>
                <Dropdown.Item onClick={() => handleShowEmail("Email as CSV")}>
                  As CSV
                </Dropdown.Item>
                <Dropdown.Item onClick={() => handleShowEmail("Email as PDF")}>
                  As PDF
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() => handleShowEmail("Email as Excel")}
                >
                  As Excel
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>  
          </div> */}
          <div className="exportBtnStudent">
            <Dropdown>
              <Dropdown.Toggle variant="undefined" id="dropdown-basic-export">
                <img
                  src={Icons.export}
                  className="horizontalDot downarrowShareBtn"
                  alt="options"
                />
              </Dropdown.Toggle>
              <Dropdown.Menu className="customDropdownMenu">
                <Dropdown.ItemText>Export All Pages</Dropdown.ItemText>
                <Dropdown.Item onClick= {showExportDataTeacher}>As CSV</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          className="customModal"
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header>
            <div className="d-flex align-items-center justify-content-between w-100">
              <p className="mb-0 font16 fw500">Add Teacher</p>
              <div>
                <img
                  src={Icons.crossSky}
                  alt="cross"
                  className="crossModal"
                  onClick={handleClose}
                />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <TeacherAddModalBody
              handleClose={handleClose}
              onSuccess={onSuccess}
            />
          </Modal.Body>
        </Modal>
      </div>
      {errorMessage ? (
        <ToastBootstrap
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          className={"toastErrorMessage"}
          message={errorMessage}
        />
      ) : null}
    </>
  );
};
export default TeacherHeader;
