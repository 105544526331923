import React from "react";
import UserLayout from "../../../components/layout/UserLayout";
import ApplicationHeader from "../../../components/applicationHeader/ApplicationHeader";
import "./Home.css";
import { Icons } from "../../../constants/Icons";
import ApplicationButton from "../../../components/applicationButton/ApplicationButton";
import AttendanceChart from "../../../emptyPages/student/NoAttandenceChart";
import Donut from "../../../components/charts/Donut";
import { useNavigate } from "react-router-dom";

const Home = () => {
    localStorage.removeItem("eventResponse");
    const teacherAttendanceData = [
        { name: 'Category A', y: 30 },
        { name: 'Category B', y: 20 },
        { name: 'Category C', y: 40 },
        { name: 'Category D', y: 10 },
    ];
    const studentAttendanceData = [
        { name: 'Category A', y: 30 },
        { name: 'Category B', y: 20 },
        { name: 'Category C', y: 40 },
        { name: 'Category D', y: 10 },
    ]
    const navigation = useNavigate();
    return (
        <UserLayout>
            <ApplicationHeader title="Overview" />
            <div className="row">
                <div className="col-12">
                    <div className="titleBox">
                        <img src={Icons.yellowTi} className="titleImage" />
                        <div className="titleRightBox">
                            <p className="mb-0 font14 fw600">Welcome to Custoking!</p>
                            <p className="mb-0 font14 fw400">To get the most out of your experience, please complete the following onboarding steps:</p>
                            <div className="buttonSetUp">
                                <ApplicationButton btnText="Setup" className={"blueheight40"} onClick={()=>navigation("/SetUp")}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 paddingTop30">
                    <h4 className="font16 fw500 mb-0">Fees Collections</h4>
                    <div className="feesBox">
                        <p className="font18 fw500 mb-0 text-white">Today's Fees Collection : ₹ 14,00,00,000</p>
                        <div className="paddingTopFess">
                            <p className="mb-0 font14 fw500 text-white">Total Fees Collected : ₹ 7,50,00,000</p>
                        </div>
                        <div className="paddingTopFess">
                            <p className="mb-0 font14 fw500 text-white">Total Fees Outstanding : ₹ 7,50,00,000</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 paddingTop30">
                    <h4 className="font16 fw500 mb-0">Notice</h4>
                    <div className="noticeBox">
                        <div className="eventsTop">
                            <p className="mb-0">No event</p>
                        </div>
                        <ApplicationButton btnText={"View All"} className={"w-100"} />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 paddingTop30">
                    <h4 className="font16 fw500 mb-0">Teacher Attendance</h4>
                    <div className="attendanceCard">
                        <Donut data={teacherAttendanceData} innerSize="50%" />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 paddingTop30">
                    <h4 className="font16 fw500 mb-0">Student Attendance</h4>
                    <div className="attendanceCard">
                        <Donut data={studentAttendanceData} innerSize="50%" />
                    </div>
                </div>
                <div className="col-md-4 col-sm-6 paddingTop30">
                    <h4 className="font16 fw500 mb-0">Defaulters</h4>
                    <div className="defaulterBox">
                        <div className="eventsTop">
                            <p className="mb-0">No event</p>
                        </div>
                    </div>
                </div>
            </div>
        </UserLayout>
    )
}

export default Home;