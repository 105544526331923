import { createAsyncThunk } from "@reduxjs/toolkit";
import decodeJWT from "../../../utils/Decode";
import axiosInstance from "../../../services/api/Api";
import { getApiUrl } from "../../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../../services/endPoints/ApiUrls";

// Decode the token and get the workspaceId
const token = localStorage.getItem('token');
const encodedToken = decodeJWT(token);

export const saveFeesStructure = createAsyncThunk(
    'fees/saveFeesStructure',
    async (payload, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          getApiUrl() + apiUrls.fees.saveFeesStructure,
          payload,
        );
  
        if (response.status === 200) {
          return response.data;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );