import React from "react";
import { useNavigate } from "react-router-dom";
import "./Fees.css";

const classData = [
  { className: "Class - 1", totalStudents: "25" },
  { className: "Class - 2", totalStudents: "30" },
  { className: "Class - 3", totalStudents: "18" },
  { className: "Class - 4", totalStudents: "22" },
  { className: "Class - 5", totalStudents: "28" },
  { className: "Class - 6", totalStudents: "15" },
  { className: "Class - 7", totalStudents: "20" },
  { className: "Class - 8", totalStudents: "17" },
  { className: "Class - 9", totalStudents: "29" },
  { className: "Class - 10", totalStudents: "23" },
];

const ClassMap = () => {
  const navigate = useNavigate();

  const classCardHandle = (item) => {
    navigate("/fees/fee-details");
  };

  return (
    <div className="row">
      {classData?.map((item, index) => (
        <div className="col-md-3" key={index}>
          <div className="class-card" onClick={() => classCardHandle(item)}>
            <div className="class-title">{item.className}</div>
            <div className="student-count">
              Total No. of Students: {item.totalStudents}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ClassMap;
