import { createAsyncThunk } from "@reduxjs/toolkit";
import decodeJWT from "../../../utils/Decode";
import axiosInstance from "../../../services/api/Api";
import { getApiUrl } from "../../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../../services/endPoints/ApiUrls";

// Decode the token and get the workspaceId
const token = localStorage.getItem('token');
const encodedToken = decodeJWT(token);
const workspaceIdd = encodedToken?.payload?.workspaceId;


export const postCreateTeacher = createAsyncThunk(
    'teacher/postCreateTeacher',
    async (payload, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          getApiUrl() + apiUrls.teacher.createTeacher,
          payload,
        );
  
        if (response.status === 200) {
          return response.data;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );
export const postFinancialDetailsTeacher= createAsyncThunk(
    'teacher/postFinancialDetailsTeacher',
    async (payload, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          getApiUrl() + apiUrls.teacher.teacherFinacilaDetails,
          payload,
        );
  
        if (response.status === 200) {
          return response.data;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );
export const postTeacherAddress= createAsyncThunk(
    'teacher/postTeacherAddress',
    async (payload, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          getApiUrl() + apiUrls.teacher.techerAddress,
          payload,
        );
  
        if (response.status === 200) {
          return response.data;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );
export const postTeacherPersonalDetails= createAsyncThunk(
    'teacher/postTeacherPersonalDetails',
    async (payload, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          getApiUrl() + apiUrls.teacher.teacherPersonalDetails,
          payload,
        );
  
        if (response.status === 200) {
          return response.data;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );
export const postTeacherEmploymentDetails= createAsyncThunk(
    'teacher/postTeacherEmploymentDetails',
    async (payload, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          getApiUrl() + apiUrls.teacher.teacherEmploymentDetails,
          payload,
        );
  
        if (response.status === 200) {
          return response.data;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );
export const postTeacherAcademicDetails= createAsyncThunk(
    'teacher/postTeacherAcademicDetails',
    async (payload, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          getApiUrl() + apiUrls.teacher.teacherAcademicDetails,
          payload,
        );
  
        if (response.status === 200) {
          return response.data;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );

  export const fetchTeachersData = createAsyncThunk(
    'teacher/fetchTeachersData',
    async (value, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get(getApiUrl() + apiUrls.teacher.getTeachers(value));
        if (response.status === 200) {
          return response.data.response;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );

  export const fetchExportDataTeacher = createAsyncThunk(
    'teacher/fetchExportDataTeacher',
    async (data, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get(getApiUrl() + apiUrls.exportData.exportTeacher);
        if (response.status === 200) {
          return response.data.response;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );

  export const getTeacherDetails = createAsyncThunk(
    'teacher/getTeacherDetails',
    async (value, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get(getApiUrl() + apiUrls.teacher.getTeacherDetails(value));
        if (response.status === 200) {
          return response.data.response;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );

  // Thunk to fetch student count
  export const fetchTeacherCount = createAsyncThunk(
  'teacher/fetchTeacherCount',
  async (value, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(getApiUrl() + apiUrls.teacher.getTeacherCount(value));
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
// Thunk to fetch Matching Student Name
export const fetchMatchingTeacherName = createAsyncThunk(
  'teacher/fetchMatchingTeacherName',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(getApiUrl() + apiUrls.teacher.getMatchingTeacher(_));
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);
export const teacherUploadDocumentFile = createAsyncThunk(
  'teacher/teacherUploadDocumentFile',
  async ({ file, otherData }, { rejectWithValue }) => {
    try {
      if (!file) {
        return rejectWithValue('No file selected for upload.');
      }
      const formData = new FormData();
      const dataStringyFY = JSON.stringify(otherData);
      const blob = new Blob([dataStringyFY], {
        type: "application/json",
      });
      formData.append('file', file);
      formData.append("data", blob);
      const customHeaders = {
        'Content-Type': 'multipart/form-data',
      };
      const response = await axiosInstance.post(
        getApiUrl() + apiUrls.teacher.teacherUploadData,
        formData,
        {
          headers: customHeaders,
        }
      );
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || 'An unexpected error occurred. Please try again.'
      );
    }
  }
);
export const fetchTeacherDocuments = createAsyncThunk(
  'teacher/fetchTeacherDocuments',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get(getApiUrl() + apiUrls.teacher.fetchTeacherDocument(data));
      if (response.status === 200) {
        return response.data.response;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);

export const deleteTeacherDocuments= createAsyncThunk(
  'teacher/deleteTeacherDocuments',
  async (payload, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.delete(
        getApiUrl() + apiUrls.teacher.teacherDeleteDocument,
        {
          data: payload,
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        return rejectWithValue(response.data.message);
      }
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.message || "An unexpected error occurred. Please try again."
      );
    }
  }
);