import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const Donut = ({ title, data, innerSize = '0%' }) => {
  const options = {
    chart: {
      type: 'pie',
    },
    title: {
      text: title, // Dynamic title
    },
    credits: {
        enabled: false
      },
    plotOptions: {
      pie: {
        innerSize: innerSize, // Use '0%' for normal pie or a percentage for donut
        dataLabels: {
          enabled: true,
          format: '{point.name}: {point.percentage:.1f} %',
        },
      },
    },
    series: [
      {
        name: 'Share',
        data: data, // Dynamic data
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default Donut;
