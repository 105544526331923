import React, { useEffect, useState } from "react";
import "./WorkSpace.css";
import "../signUp/Signup.css";
import { Icons } from "../../constants/Icons";
import CardManager from "../userCard/UserCard";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getApiUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import { useLocation, useNavigate } from "react-router-dom";
import GetCookie from "../../persistance/cookies/GetCookies";
import ToastBootstrap from "../../components/toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchWorkspace } from "../../redux/reduxToolkit/actions/authAction";
const WorkSpace = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  // const [getAllWorkspace,setGetAllWorkspace]=useState([]);
  const getAuthToken=localStorage.getItem("token");
  const dispatch = useDispatch();
  const { allWorkspaceData, isLoadingWorkspaceData, workspaceError } = useSelector((state) => state?.auth);
  // useEffect(()=>{
  //   api.userGet(getApiUrl() + apiUrls.workspace.getAllUserWorkspace)
  //   .then(response => {
  //       if (response.data.httpStatusCode === 200) {
  //         setGetAllWorkspace(response.data.response)
  //       } else {
  //         setErrorMessage(response.data.message);
  //         setShowErrorMessage(true);
  //       }
  //   })
  //   .catch(error => {
  //     setErrorMessage(error.response?.data?.message || "An unexpected error occurred. Please try again.");
  //     setShowErrorMessage(true);
  //   });
  // },[])
  useEffect(() => {
    dispatch(fetchWorkspace());
}, [dispatch]);
console.log(allWorkspaceData,"allWorkspaceData")
const location = useLocation(); // Gets the current location object

useEffect(() => {
  // Ensure that the current path is not "/workspace" (case-insensitive)
  if (location.pathname.toLowerCase() !== '/workspace') {
    // Redirect to "/workspace" using navigate from React Router
    navigate('/workspace', { replace: true });
  }
}, [location, navigate]);

  return (
    <div className="signUpContainer">
      <div className="signUpTop">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-md-6">
              <div className="">
                <div className="custokingLogo">
                  <img src={Icons.custokingLogo} className="img-fluid" />
                </div>
                <p className="text-white font30 fw600 signUpTo">
                  Welcome User!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row d-flex justify-content-start">
          <div className="col-md-12">
            <p className="fw600 mt-3 fs-5">Workspace</p>
            <CardManager workspaceData={allWorkspaceData}/>
          </div>
        </div>
      </div>
      {errorMessage ?
          <ToastBootstrap showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage} className={"toastErrorMessage"} message={errorMessage} /> : null
      }
    </div>
  );
};

export default WorkSpace;
