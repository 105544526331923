
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "./Student.css"
import StudentOverView from './StudentOverview';
import "./StudentPerformance.css";
import SubjectRegister from './SubjectRegister';
import StudentDocuments from './studentsDetails/StudentDocuments';
import EmptyOverView from '../../../emptyPages/student/EmptyOverview';
import FeesTab from './Feestab';
import CommonTabs from '../../../components/CommonTab/CommonTabs';
const arrayTest=[]
const StudentPerformance = () => {
  const tabs = [
    {
      eventKey: 'OverView',
      title: 'Overview',
      content: arrayTest.length > 0 ? <StudentOverView /> : <EmptyOverView />,
    },
    {
      eventKey: 'Subject',
      title: 'Subject',
      content: <SubjectRegister />,
    },
    {
      eventKey: 'Grade',
      title: 'Grade',
      content: 'Grade', // You can replace this with a component if needed
    },
    {
      eventKey: 'Attendence',
      title: 'Attendence',
      content: 'Attendence', // You can replace this with a component if needed
    },
    {
      eventKey: 'Fees',
      title: 'Fees',
      content: <FeesTab />,
    },
    {
      eventKey: 'Document',
      title: 'Document',
      content: <StudentDocuments />,
    },
  ];
  return (
    <div className='studentPerformace'>
      <div className='row w-100'>
        <div className='col-12'>
        <CommonTabs tabs={tabs} defaultKey="OverView" />
        </div>
      </div>
    </div>
  )
}

export default StudentPerformance;



