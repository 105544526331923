import React from "react";
import "./EmptyState.css";
import { Images } from "../../constants/Images";

const TeacherEmptyState = () => {
    return(
        <>
         <div className="emptyContainer">
            <img src={Images.noteacherFound} />
            <p className="font28 fw600 studentEmptyText">No Teachers found</p>
         </div>
        </>
    )
}

export default TeacherEmptyState;