import React, { useEffect } from "react";
import UserLayout from "../../components/layout/UserLayout";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";
import FirstLastNameAvatar from "../../components/avatar/FirstLastNameAvatar";
import "./Teacher.css";
import { Icons } from "../../constants/Icons";
import CommonTabs from "../../components/CommonTab/CommonTabs";
import OverViewTeacher from "./tab/OverViewTeacher";
import ClassTeacher from "./tab/ClassTeacher";
import PaymentTeacher from "./tab/PaymentTeacher";
import AttandenceTeacher from "./tab/AttandenceTeacher";
import DocumentTeacher from "./tab/DocumentTeacher";
import { useDispatch, useSelector } from "react-redux";
import { fetchTeacherDocuments, getTeacherDetails } from "../../redux/reduxToolkit/actions/TeacherAction";
const TeacherComponent = () => {
  localStorage.removeItem("eventResponse");
  const tabsTeacher = [
    {
      eventKey: "OverView",
      title: "Overview",
      content: <OverViewTeacher />,
    },
    {
      eventKey: "Class",
      title: "Class",
      content: <ClassTeacher />,
    },
    {
      eventKey: "Payment",
      title: "Payment",
      content: <PaymentTeacher />, 
    },
    {
      eventKey: "Attendence",
      title: "Attendence",
      content: <AttandenceTeacher />, // You can replace this with a component if needed
    },
    {
      eventKey: "Document",
      title: "Document",
      content: <DocumentTeacher />,
    },
  ];
  const dispatch = useDispatch();
  const teacherId = localStorage.getItem("teacherDto");
  console.log(teacherId,"studentIdd")
  useEffect(() => {
    dispatch(fetchTeacherDocuments(teacherId));
      dispatch(getTeacherDetails(teacherId));
  }, [dispatch, teacherId])
  let { getTeacherDetailsData, isLoadingGetTeacherDetailsStatus, getTeacherDetailsError } =
  useSelector((state) => state?.teacher);
  console.log(getTeacherDetailsData);
  return (
    <>
      <UserLayout>
        <ApplicationHeader title="Teacher" />
        <div className="secondTeacherHeader">
          <div className="row">
            <div className="col-md-4 d-flex">
              <div className="d-flex align-items-center">
                <div className="teacherProfileDiv">
                  <div className="avatarCircle30 font26 fw600 avatarwidthSize">
                    <FirstLastNameAvatar name={`${getTeacherDetailsData?.teacherDTO?.firstName} ${getTeacherDetailsData?.teacherDTO?.lastName}`} />
                  </div>
                </div>
                <div className="detailsTeacher">
                  <p className="font22 fw600 name">{getTeacherDetailsData?.teacherDTO?.firstName} {getTeacherDetailsData?.teacherDTO?.lastName}</p>
                  <p className="font16 fw600 teacherId">Teacher Id: {getTeacherDetailsData?.teacherDTO?.teacherId}</p>
                  <p className="font16 fw600 gender">Gender:{getTeacherDetailsData?.teacherDTO?.gender}</p>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="teacherAttandence__div">
                <div className="attandence-text-div">
                  <p className="font24 fw600 mb-0">Attandence</p>
                </div>
                <div className="attandencePerDiv">
                  {/* <p className="font36 fw600 mb-0 pink-text-p">85%</p> */}
                  <p className="font36 fw600 mb-0">0</p>
                  {/* <p className="font24 fw600 mb-0">
                    <span className="green-text-span">85</span>/100
                  </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="btn-div-teacher">
                {/* <div className="share-div">
                  Share
                  <img
                    src={Icons.downArrow}
                    className="horizontalDot downarrowShareBtn"
                    alt="down"
                  />
                </div>
                <div className="export-btn">
                  <img
                    src={Icons.export}
                    className="exportTeacherImage"
                    alt="export"
                  />
                </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="studentPerformace">
          <div className="row w-100">
            <div className="col-12">
              <CommonTabs tabs={tabsTeacher} defaultKey="OverView" />
            </div>
          </div>
        </div>
      </UserLayout>
    </>
  );
};

export default TeacherComponent;
