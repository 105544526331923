import { createSlice } from '@reduxjs/toolkit';
import { fetchWorkspace, LogOutPost, SignIn, SignInDataPost, SignUpDataPost, WorkspaceSignInPost } from '../actions/authAction';

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        signInData: null,
        allWorkspaceData: null,
        workspaceSignIn: null,
        logOutData: null,
        signUpData:null,

        isLoadingSignInData: false,
        isLoadingWorkspaceData: false,
        isLoadingWorkspaceSignIn: false,
        isLoadingLogOut: false,
        isLoadingSignUp: false,

        signInError: null,
        workspaceError: null,
        workspaceSignInError: null,
        logOutError: null,
        signUpError: null,
      
    },
    reducers: {
        studentState: (state) => {
            state.signInData = null;
            state.allWorkspaceData = null;
            state.workspaceSignIn = null;
            state.logOutData = null;
            state.signUpData=null;

            state.signInError= null;
            state.workspaceError = null;
            state.workspaceSignInError = null;
            state.logOutError = null;
            state.signUpError=null;
        }
    },
    
    extraReducers: (builder) => {
        builder
            // Handle fetchStudentData
            .addCase(SignInDataPost.pending, (state) => {
                state.isLoadingSignInData = true;
                state.signInError = null;
            })
            .addCase(SignInDataPost.fulfilled, (state, action) => {
                state.isLoadingSignInData = false;
                state.signInData = action.payload;
            })
            .addCase(SignInDataPost.rejected, (state, action) => {
                state.isLoadingSignInData = false;
                state.signInError = action.payload;
            })

            .addCase(fetchWorkspace.pending, (state) => {
                state.isLoadingWorkspaceData = true;
                state.workspaceError = null;
            })
            .addCase(fetchWorkspace.fulfilled, (state, action) => {
                state.isLoadingWorkspaceData = false;
                state.allWorkspaceData = action.payload;
            })
            .addCase(fetchWorkspace.rejected, (state, action) => {
                state.isLoadingWorkspaceData = false;
                state.workspaceError = action.payload;
            })

            .addCase(WorkspaceSignInPost.pending, (state) => {
                state.isLoadingWorkspaceSignIn = true;
                state.workspaceSignInError = null;
            })
            .addCase(WorkspaceSignInPost.fulfilled, (state, action) => {
                state.isLoadingWorkspaceSignIn = false;
                state.workspaceSignIn = action.payload;
            })
            .addCase(WorkspaceSignInPost.rejected, (state, action) => {
                state.isLoadingWorkspaceSignIn = false;
                state.workspaceSignInError = action.payload;
            })
           
            .addCase(LogOutPost.pending, (state) => {
                state.isLoadingLogOut = true;
                state.logOutError = null;
            })
            .addCase(LogOutPost.fulfilled, (state, action) => {
                state.isLoadingLogOut = false;
                state.logOutData = action.payload;
            })
            .addCase(LogOutPost.rejected, (state, action) => {
                state.isLoadingLogOut = false;
                state.logOutError = action.payload;
            })



            .addCase(SignUpDataPost.pending, (state) => {
                state.isLoadingSignUp = true;
                state.signUpData = null;
            })
            .addCase(SignUpDataPost.fulfilled, (state, action) => {
                state.isLoadingSignUp = false;
                state.signUpData = action.payload;
            })
            .addCase(SignUpDataPost.rejected, (state, action) => {
                state.isLoadingSignUp = false;
                state.signUpError = action.payload;
            })
    },
});
// Export the reducer

export const { authState } = authSlice.actions;
export default authSlice.reducer;