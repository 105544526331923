import React from "react";
import { Icons } from "../../../constants/Icons";
import "./FeesHeader.css";
import TextInputWithLabel from "../../applicationInput/ApplicationInput";
const FeesHeader = () => {
  return (
    <>
      <div className="secondHeader">
        <div className="w-100 row serachStudentInFeesSection">
          <div className="col-md-4 fees">
            <div className="searchBoxFees">
              <TextInputWithLabel
                label="Student Name"
                id="studentName"
                name="studentName"
                placeholder="Enter Student Name"
                value=""
                customInputStyle={"greyInput"}
                rightIcon={Icons.searchIcon}
                customLabelStyle="greyLabel"
              />
            </div>
          </div>
          <div className="col-md-1">
            <div className="orBox">
              <p className="mb-0 font14 fw600"> Or</p>
            </div>
          </div>
          <div className="col-md-4 fees">
            <div className="searchBoxFees">
              <TextInputWithLabel
                label="Student Id"
                id="studentId"
                name="studentId"
                placeholder="Enter Student Id"
                value=""
                customLabelStyle="greyLabel"
                rightIcon={Icons.searchIcon}
                customInputStyle={"greyInput"}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default FeesHeader;
