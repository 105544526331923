import React, { useState } from "react";
import ApplicationButton from "../../../components/applicationButton/ApplicationButton";
import SubjectModal from "../../../components/modal/SubjectModal";
import SubjectTable from "../../../components/table/SubjectTable";


const SubjectRegister = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="studentOverviewContainer">
      <div className="studentSubjectHeader">
        <p className="font18 fw600 mb-0">Subject</p>
        <div className="addStudentButton">
          <ApplicationButton
            btnText="+ Register to Subject"
            className={"button40"}
            onClick={handleOpenModal}
          />
        </div>
      </div>
      <SubjectModal
        show={showModal}
        handleClose={handleCloseModal}
        modalTitle="Subject"
      />
      <SubjectTable />
    </div>
  );
};

export default SubjectRegister;
