
import './App.css';
import ApplicationNavigator from './components/navigator/ApplicationNavigator';
import { Provider as ReduxProvider } from "react-redux";
import store from './redux/reduxToolkit/Store';

function App() {

  return (
    <ReduxProvider store={store}>
      <ApplicationNavigator />
    </ReduxProvider>
  );
}

export default App;
