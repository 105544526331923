import React, { useEffect, useRef, useState } from "react";
import TextInputWithLabel from "../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import CommonDropdown from "../applicationDropdown/ApplicationDropdown";
import "./Modal.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Icons } from "../../constants/Icons";
import ToastBootstrap from "../toast/Toast";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getApiUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAdmissionDetailsStudent } from "../../redux/reduxToolkit/actions/StudentAction";

const rteStudentData = ["True", "False"];
const categoryData = ["General", "OBC", "EWS", "SC", "ST"];
const AdmissionDetailsModal = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const datePickerRef = useRef(null);
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const studentsDetails = useSelector((state) => state.student.getStudentDetails);
  const {studentAdmissionDetails, isLoadingstudentAdmissionDetailsStatus, studentAdmissionDetailsError} = useSelector((state) => state?.student);
  const formik = useFormik({
    initialValues: {
      admissionNumber:studentAdmissionDetails ? studentAdmissionDetails?.response?.admissionNo : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.admissionNo: "",
      dateofAdmission:studentAdmissionDetails ? studentAdmissionDetails?.response?.dateOfAdmission : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.dateOfAdmission: "",
      srNo: studentAdmissionDetails ? studentAdmissionDetails?.response?.srNo : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.srNo: "",
      uniqueId: studentAdmissionDetails ? studentAdmissionDetails?.response?.uniqueId : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.uniqueId: "",
      rteStudent:studentAdmissionDetails ? studentAdmissionDetails?.response?.rteStudent : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.rteStudent: "",
      rteCategory:studentAdmissionDetails ? studentAdmissionDetails?.response?.rteCategory : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.rteCategory: "",
      feeCategory:studentAdmissionDetails ? studentAdmissionDetails?.response?.feeCategory : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.feeCategory: "",
    },
    enableReinitialize: true, 
    validationSchema: yup.object({
      admissionNumber: yup.string().required("Admission Number  is required"),
      dateofAdmission: yup.string().required("Date of admission   is required"),
      srNo: yup.string().required("Sr No  is required"),
      uniqueId: yup.string().required("UniqueId  is required"),
      rteStudent: yup.string().required("RTE Student  is required"),
      rteCategory: yup.string().required("RTE Category name is required"),
      feeCategory: yup.string().required("Fee Category name is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const studentDto = localStorage.getItem("studentDto");
      const DateInMiliSec=new Date(values.dateofAdmission).getTime()
      const payload = {
        "studentDTO" :{
            "id" :studentDto
        },
        "id": studentAdmissionDetails ? studentAdmissionDetails?.response?.id : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.id: null ,
        "uuid": studentAdmissionDetails ? studentAdmissionDetails?.response?.uuid : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.uuid: null,
        "createdDate": studentAdmissionDetails ? studentAdmissionDetails?.response?.createdDate : studentsDetails?.studentAdmissionDTO? studentsDetails?.studentAdmissionDTO?.createdDate: null,
        "admissionNo" : values.admissionNumber,
        "dateOfAdmission" : DateInMiliSec,
        "feeCategory" : values.feeCategory,
        "srNo" : values.srNo,
        "uniqueId" : values.uniqueId,
        "rteStudent" : values.rteStudent,
        "rteCategory" : values.rteCategory
    }
    dispatch(fetchAdmissionDetailsStudent(payload));

    if (studentAdmissionDetails.httpStatusCode===200) {
      props.handleClose();
        resetForm();
        Swal.fire({
            icon: 'success',
            title: ' AdmissionDetails',
            text: 'Student Admission Details saved',
            confirmButtonText: 'OK'
        }).then((result) => {
            
        });
    } else {
        setErrorMessage(studentAdmissionDetails.message);
        setShowErrorMessage(true);
    }
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
      if (studentAdmissionDetails.httpStatusCode === 200) {
        props.handleClose();
        formik.resetForm();
        Swal.fire({
          icon: "success",
          title: " Admission Details",
          text: "Student Admission Details saved",
          confirmButtonText: "OK",
          timer: 5000,
          timerProgressBar: true,
        });
      } else if (studentAdmissionDetailsError) {
         setErrorMessage(studentAdmissionDetailsError);
         setShowErrorMessage(true);
      }
    }
  }, [hasSubmitted, studentAdmissionDetails, studentAdmissionDetailsError]);
  return (
    <div className="admissionDetailsContainer">
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Admission Number"}
              placeHolder={"Enter Admission Number"}
              name="admissionNumber"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.admissionNumber}
              error={
                formik.touched.admissionNumber && formik.errors.admissionNumber
                  ? formik.errors.admissionNumber
                  : null
              }
            />
          </div>
          <div className="col-md-6">
            <label for="dateofAdmission" className="label-text-style fw500 greyLabel">
              Date Of Admission
            </label>
            <div>
              <div className="position-relative">
                <DatePicker
                  ref={datePickerRef}
                  selected={formik.values.dateofAdmission}
                  onChange={(date) => formik.setFieldValue('dateofAdmission', date)}
                  onBlur={formik.handleBlur}
                  dateFormat="yyyy-MM-dd"
                  className="inline-style fw500 w-100 greyInput"
                  placeholderText="YYYY-MM-DD"
                />
                <div className="datePickerIcon" onClick={() => datePickerRef.current.setFocus()}>
                  <img src={Icons.greyCalenderIcon} alt="date" />
                </div>
              </div>
              {formik.touched.dateofAdmission && formik.errors.dateofAdmission ? (
                <div className="Inputerror">{formik.errors.dateofAdmission}</div>
              ) : null}
            </div>
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Sr No"}
              placeHolder={"Enter Sr No"}
              name="srNo"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.srNo}
              error={
                formik.touched.srNo && formik.errors.srNo
                  ? formik.errors.srNo
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Unique Id"}
              placeHolder={"Enter Unique Id"}
              name="uniqueId"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.uniqueId}
              error={
                formik.touched.uniqueId && formik.errors.uniqueId
                  ? formik.errors.uniqueId
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <CommonDropdown
              label={"RTE Student"}
              placeHolder={"Enter RTE Student"}
              name="rteStudent"
              customLabelStyle="greyLabel"
              title={formik.values.rteStudent}
              onSelect={(selectedValue) => formik.setFieldValue('rteStudent', selectedValue)}
              items={rteStudentData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.rteStudent}
              error={
                formik.touched.rteStudent && formik.errors.rteStudent
                  ? formik.errors.rteStudent
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <CommonDropdown
              label={"RTE Category"}
              placeHolder={"Enter RTE Category"}
              name="rteCategory"
              customLabelStyle="greyLabel"
              items={categoryData}
              title={formik.values.rteCategory}
              onSelect={(selectedValue) =>
                formik.setFieldValue("rteCategory", selectedValue)
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.rteCategory}
              error={
                formik.touched.rteCategory && formik.errors.rteCategory
                  ? formik.errors.rteCategory
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Fee Category"}
              placeHolder={"Enter Fee Category"}
              name="feeCategory"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.feeCategory}
              error={
                formik.touched.feeCategory && formik.errors.feeCategory
                  ? formik.errors.feeCategory
                  : null
              }
            />
          </div>
        </div>
      {errorMessage ? (
        <ToastBootstrap
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          className={"toastErrorMessage"}
          message={errorMessage}
        />
      ) : null}
        <div className="row pt30 justify-content-end">
          <div className="col-md-2">
            <ApplicationButton btnText={"Save"} type="submit" className="blueheight40" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AdmissionDetailsModal;
