import axios from 'axios';
import { baseURL } from '../baseUrl/BaseUrl';

// Helper function to get token from localStorage asynchronously
const getToken = async () => {
  return new Promise((resolve) => {
    const token = localStorage.getItem('token');
    resolve(token);
  });
};

// Configure Axios Instance
const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      // Get the token using async/await
      const token = await getToken();
      if (token) {
        // If the token exists, set it in the Authorization header
        config.headers['authToken'] = token;
      }
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => Promise.reject(error)
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Simply return the response if it's successful
    return response;
  },
  (error) => {
    // Check if the error response status is 401
    if (error.response && error.response.status === 401) {
      // Remove the token from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem("eventResponse");
      localStorage.removeItem("studentDto");
      window.location.href = '/sign-in';
      // Optionally, you can redirect the user to a login page or display a message
      // window.location.href = '/login'; // Uncomment this line to redirect to login

      // Optionally, show an alert or a notification
      // alert('Session expired. Please log in again.');

      // Return a rejected promise with the error
      return Promise.reject(error);
    }

    // If the error is not due to an expired token, simply reject it
    return Promise.reject(error);
  }
);

export default axiosInstance;