

import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import CommonDropdown from "../applicationDropdown/ApplicationDropdown";
import ToastBootstrap from "../toast/Toast";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getApiUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import IndianStatesAndUTs from "../../DTO/IndianStateAndUTs";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentAddressDetails } from "../../redux/reduxToolkit/actions/StudentAction";
const studentAddressType = ["CURRENT", "PERMANENT"];
const StudentAddressDetailsModal = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const studentsDetails = useSelector((state) => state.student.getStudentDetails);
  const {studentAddressDetails, isLoadingstudentAdressDetailsStatus, studentAddressDetailsError} = useSelector((state) => state?.student);

  const formik = useFormik({
    initialValues: {
      houseOrFlatNumber: studentAddressDetails ? studentAddressDetails?.response?.house : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.house: "",
      streetName: studentAddressDetails ? studentAddressDetails?.response?.street : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.street: "",
      addressType:studentAddressDetails ? studentAddressDetails?.response?.addressType : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.addressType: "",
      pinCode: studentAddressDetails ? studentAddressDetails?.response?.pinCode : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.pinCode: "",
      districtName: studentAddressDetails ? studentAddressDetails?.response?.district : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.district: "",
      city: studentAddressDetails ? studentAddressDetails?.response?.city : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.city: "",
      landmark: studentAddressDetails ? studentAddressDetails?.response?.landmark : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.landmark: "",
      stateName:  studentAddressDetails ? studentAddressDetails?.response?.state : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.state: "",
    },
    validationSchema: yup.object({
      houseOrFlatNumber: yup.string().required("House/Flat Number  is required"),
      streetName: yup.string().required("Street Name is required"),
      addressType: yup.string().required("Address Type required"),
      pinCode: yup
      .string()
      .matches(/^\d{6}$/, "Pincode must be exactly 6 digits")
      .required("Pincode is required"),    
      districtName: yup.string().required("District Name Type required"),
      city: yup.string().required("City Name Type required"),
      landmark: yup.string().required("Landmark is required"),
      stateName: yup.string().required("State Name is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const studentDto=localStorage.getItem("studentDto")
      const payload= {
        "studentDTO" :{
            "id" : studentDto
        },
        "id": studentAddressDetails ? studentAddressDetails?.response?.id : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.id: null ,
        "uuid": studentAddressDetails ? studentAddressDetails?.response?.uuid : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.uuid: null,
        "createdDate": studentAddressDetails ? studentAddressDetails?.response?.createdDate : studentsDetails?.studentAddressDTO? studentsDetails?.studentAddressDTO?.createdDate: null,
        "addressType" : values.addressType,
        "city" : values.city,
        "pinCode" : values.pinCode,
        "state" : values.stateName,
        "district" : values.districtName,
        "house" :values.house,
        "landmark" : values.landmark,
        "street" : values.streetName
    
    }
    dispatch(fetchStudentAddressDetails(payload));
    setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
    if (studentAddressDetails?.httpStatusCode === 200) {
      props.handleClose();
      formik.resetForm();
      Swal.fire({
        icon: 'success',
        title: 'Student Address  Details',
        text: 'Student  Address Details saved',
        confirmButtonText: 'OK',
        timer: 5000,
        timerProgressBar: true,
      });
    } else if (studentAddressDetailsError) {
       setErrorMessage(studentAddressDetailsError);
       setShowErrorMessage(true);
    }
  }
  }, [hasSubmitted, studentAddressDetails, studentAddressDetailsError]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextInputWithLabel
              label={"House/ Flat No"}
              placeHolder={"Enter House/ Flat No"}
              name="houseOrFlatNumber"
                customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.houseFlatNumber}
              error={
                formik.touched.houseFlatNumber && formik.errors.houseFlatNumber
                  ? formik.errors.houseFlatNumber
                  : null
              }
            />
          </div>
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Street"}
              placeHolder={"Enter Street name"}
              name="streetName"
                customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.streetName}
              error={
                formik.touched.streetName && formik.errors.streetName
                  ? formik.errors.streetName
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
          <CommonDropdown
              label={"Address Type"}
              placeHolder={"Address Type"}
              name="addressType"
              customLabelStyle="greyLabel"
              title={formik.values.addressType}
              onSelect={(selectedValue) => formik.setFieldValue('addressType', selectedValue)}
              items={studentAddressType} 
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.addressType}
              error={
                formik.touched.addressType && formik.errors.addressType
                  ? formik.errors.addressType
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Pin code"}
              placeHolder={"Enter Pin code"}
              name="pinCode"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.pinCode}
              error={
                formik.touched.pinCode && formik.errors.pinCode
                  ? formik.errors.pinCode
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"District"}
              placeHolder={"Enter District"}
                name="districtName"
                  customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.districtName}
              error={
                formik.touched.districtName && formik.errors.districtName
                  ? formik.errors.districtName
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"City"}
              placeHolder={"Enter City"}
                name="city"
                  customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.city}
              error={
                formik.touched.city && formik.errors.city
                  ? formik.errors.city
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Landmark"}
              placeHolder={"Enter Landmark"}
                name="landmark"
                  customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.landmark}
              error={
                formik.touched.landmark && formik.errors.landmark
                  ? formik.errors.city
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <CommonDropdown
              label={"State"}
              placeHolder={"Enter State Name"}
              name="stateName"
              customLabelStyle="greyLabel"
              items={IndianStatesAndUTs}
              title={formik.values.stateName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onSelect={(selectedValue) =>
                formik.setFieldValue("stateName", selectedValue)
              }
              value={formik.values.stateName}
              error={
                formik.touched.stateName && formik.errors.stateName
                  ? formik.errors.stateName
                  : null
              }
            />
          </div>
         
        </div>
      {errorMessage ?
            <ToastBootstrap showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage} className={"toastErrorMessage"} message={errorMessage} /> : null
     }
        <div className="row pt30 justify-content-end">
          <div className="col-md-2">
            <ApplicationButton btnText={"Save"} type="submit" className="blueheight40"/>
          </div>
        </div>
      </form>
    </>
  );
};

export default StudentAddressDetailsModal;
