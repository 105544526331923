import React, { useState } from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import "./ApplicationDropdown.css";

function CommonDropdown({
  title,
  items,
  onSelect,
  variant = 'secondary',
  size,
  error,
  customLabelStyle = '',
  isRequired = false,
  label = '',
  value,
  placeHolder,
  optional = false,
  ...props
}) {
  const [selectedItem, setSelectedItem] = useState(value || ''); // State to track the selected item

  const handleSelect = (item) => {
    setSelectedItem(item); // Update selected item
    onSelect(item); // Trigger the onSelect callback with the selected item
  };

  return (
    <div className="input-container">
      {label && (
        <label className={`label-text-style fw500 ${customLabelStyle}`}>
          {label} 
          {optional && (
            <span className="font16 fw500 greyColor ms-1">(Optional)</span>
          )}
          {isRequired && <span className="asterics">*</span>}
        </label>
      )}
      <div>
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedItem || title || placeHolder}
          variant={variant}
          {...props}
          className={`applicationDropdownStyle ${selectedItem ? "dropdownTextColor" : "placeholdDropdownTextColor"}`}
        >
          {items?.map((item, index) => (
            <Dropdown.Item
              key={index}
              onClick={() => handleSelect(item)}
              className={item === selectedItem ? 'active' : ''}
            >
              {item}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      </div>
      {error && <div className="Inputerror">{error}</div>}
    </div>
  );
}

export default CommonDropdown;
