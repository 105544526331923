import { createAsyncThunk } from '@reduxjs/toolkit';
import { getApiUrl, getLoginUrl, getSignUpUrl } from '../../../services/urlConfig/UrlConfig';
import { apiUrls } from '../../../services/endPoints/ApiUrls';
import axiosInstance from '../../../services/api/Api';
import Swal from 'sweetalert2';

export const SignInDataPost = createAsyncThunk(
    'auth/SignInDataPost',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(
                getLoginUrl() + apiUrls.login.userLogin,
                payload,
            );
            if (response.data.httpStatusCode === 200) {
                return response.data;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || "An unexpected error occurred. Please try again."
            );
        }
    }
);
export const fetchWorkspace = createAsyncThunk(
    'student/fetchWorkspace',
    async (_, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get(getApiUrl() + apiUrls.workspace.getAllUserWorkspace);
        if (response.status === 200) {
          return response.data.response;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );
  export const WorkspaceSignInPost = createAsyncThunk(
    'auth/WorkspaceSignInPost',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(
                getLoginUrl() + apiUrls.workspace.workspaceLogin,
                payload,
            );
            if (response.data.httpStatusCode === 200) {
                return response.data;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || "An unexpected error occurred. Please try again."
            );
        }
    }
);
export const LogOutPost = createAsyncThunk(
    'auth/LogOutPost',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(
                getApiUrl() + apiUrls.logout.logoutUser,
                payload,
            );
            if (response.data.httpStatusCode === 200) {
                localStorage.removeItem("token");
                window.location.href=("/sign-in");
                return response.data;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || "An unexpected error occurred. Please try again."
            );
        }
    }
);
  export const SignUpDataPost = createAsyncThunk(
    'auth/SignUpDataPost',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(
                getSignUpUrl() + apiUrls.signUp.verificationEmail,
                payload,
            );
            if (response.data.httpStatusCode === 200) {
                return response.data;
            } else {
                return rejectWithValue(response.data.message);
            }
        } catch (error) {
            return rejectWithValue(
                error.response?.data?.message || "An unexpected error occurred. Please try again."
            );
        }
    }
);