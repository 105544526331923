import React, { useEffect, useRef, useState } from "react";
import TextInputWithLabel from "../../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../../applicationButton/ApplicationButton";
import CommonDropdown from "../../applicationDropdown/ApplicationDropdown";
import "./../Modal.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Icons } from "../../../constants/Icons";
import ToastBootstrap from "../../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { postTeacherEmploymentDetails } from "../../../redux/reduxToolkit/actions/TeacherAction";
import Swal from "sweetalert2";
import { getCurrentTimestamp } from "../../../DTO/TodayDate";
import { postAddClass } from "../../../redux/reduxToolkit/actions/ClassesAction";
import decodeJWT from "../../../utils/Decode";

const AddClassModal = ({ show, handleClose, title }) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const datePickerRef = useRef(null);
    const dispatch = useDispatch();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const createdDate = getCurrentTimestamp();
    const token = localStorage.getItem("token");
    const decodedtoken = decodeJWT(token);
    const { addClassDetails, isLoadingAddClassDetails, addClassDetailsError } = useSelector((state) => state.classes);

    const formik = useFormik({
        initialValues: {
            className: "",
            durationFrom: "",
            durationTo: "",
        },
        validationSchema: yup.object({
            className: yup.string().required("Class Name is required"),
            durationFrom: yup.string().required("Duration is required"),
            durationTo: yup.string().required("Duration is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            console.log(values, "valuesss")
            setHasSubmitted(true);
            const payload = {
                "name": values.className,
                "organizationDTO": {
                    "id": decodedtoken.payload.organizationId
                },
                "createdDate": createdDate,
                "sections": []
            }
            dispatch(postAddClass(payload))
        }
    });
    useEffect(() => {
        if (hasSubmitted) {
            if (addClassDetails?.httpStatusCode === 200) {
                handleClose();
                formik.resetForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Class Added',
                    text: 'Class added successfully',
                    confirmButtonText: 'OK',
                    timer: 5000,
                    timerProgressBar: true,
                });
            } else if (addClassDetailsError) {
                setErrorMessage(addClassDetailsError);
                setShowErrorMessage(true);
            }
        }
    }, [hasSubmitted, addClassDetails, addClassDetailsError])
    return (
        <div className="admissionDetailsContainer">
            <Modal
                show={show}
                onHide={handleClose}
                className="customModal"
                backdrop="static"
                centered
                size="lg"
            >
                <Modal.Header>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <p className="mb-0 font16 fw500">{title}</p>
                        <div>
                            <img
                                src={Icons.crossSky}
                                alt="cross"
                                className="crossModal"
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <TextInputWithLabel
                                    label={"Class Name"}
                                    placeHolder={"Enter Class Name"}
                                    name="className"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.className}
                                    error={
                                        formik.touched.className && formik.errors.className
                                            ? formik.errors.className
                                            : null
                                    }
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mt-4">
                                <label for="dateofJoining" className="label-text-style fw500 greyLabel">
                                Duration From
                                </label>
                                <div>
                                    <div className="position-relative">
                                        <DatePicker
                                            ref={datePickerRef}
                                            selected={formik.values.durationFrom}
                                            onChange={(date) => formik.setFieldValue('durationFrom', date)}
                                            onBlur={formik.handleBlur}
                                            dateFormat="yyyy-MM-dd"
                                            className="inline-style fw500 w-100 greyInput"
                                            placeholderText="YYYY-MM-DD"
                                        />
                                        <div className="datePickerIcon" onClick={() => datePickerRef.current.setFocus()}>
                                            <img src={Icons.greyCalenderIcon} alt="date" />
                                        </div>
                                    </div>
                                    {formik.touched.durationFrom && formik.errors.durationFrom ? (
                                        <div className="Inputerror">{formik.errors.durationFrom}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className="col-md-6 mt-4">
                                <label for="durationTo" className="label-text-style fw500 greyLabel">
                                Duration To
                                </label>
                                <div>
                                    <div className="position-relative">
                                        <DatePicker
                                            ref={datePickerRef}
                                            selected={formik.values.durationTo}
                                            onChange={(date) => formik.setFieldValue('durationTo', date)}
                                            onBlur={formik.handleBlur}
                                            dateFormat="yyyy-MM-dd"
                                            className="inline-style fw500 w-100 greyInput"
                                            placeholderText="YYYY-MM-DD"
                                        />
                                        <div className="datePickerIcon" onClick={() => datePickerRef.current.setFocus()}>
                                            <img src={Icons.greyCalenderIcon} alt="date" />
                                        </div>
                                    </div>
                                    {formik.touched.durationTo && formik.errors.durationTo ? (
                                        <div className="Inputerror">{formik.errors.durationTo}</div>
                                    ) : null}
                                </div>
                            </div>


                        </div>
                        {errorMessage ? (
                            <ToastBootstrap
                                showErrorMessage={showErrorMessage}
                                setShowErrorMessage={setShowErrorMessage}
                                className={"toastErrorMessage"}
                                message={errorMessage}
                            />
                        ) : null}
                        <div className="row pt30 justify-content-end">
                            <div className="col-md-2">
                                <ApplicationButton btnText={"Save"} type="submit" className="blueheight40" />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddClassModal;

