

import React from "react";



const PaymentTeacher=()=>{
    return(
        <>
        payment</>
    )
  
    
}

export default PaymentTeacher;