import React from "react";
import "./Fees.css";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";
import UserLayout from "../../components/layout/UserLayout";
import FeesHeader from "../../components/applicationHeader/feesHeader/FeesHeader";
import FeesTable from "./FeesTable";
import ClassMap from "./ClassMap";
import AddFeesDetails from "./AddFeeDetails";
const FeesModule = () => {
  return (
    <>
      <UserLayout>
        <ApplicationHeader title="Fees" />
        <AddFeesDetails />
        <div className="">
          <ClassMap />
        </div>
      </UserLayout>
    </>
  );
};

export default FeesModule;
