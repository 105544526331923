import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../../applicationButton/ApplicationButton";
import CommonDropdown from "../../applicationDropdown/ApplicationDropdown";
import "./../Modal.css";
import { Icons } from "../../../constants/Icons";
import ToastBootstrap from "../../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { getCurrentTimestamp } from "../../../DTO/TodayDate";
import decodeJWT from "../../../utils/Decode";
import { getAllClasses, postAddSection } from "../../../redux/reduxToolkit/actions/ClassesAction";
import Swal from "sweetalert2";

const customSectionData = ["Section A", "Section B", "Section C", "Section D", "Section E", "Section F"];

const AddSectionModal = ({ show, handleClose, title, item }) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [addSectionData, setAddSectionData] = useState([]);
    const dispatch = useDispatch();
    const createdDate = getCurrentTimestamp();
    const token = localStorage.getItem("token");
    const decodedtoken = decodeJWT(token);
    const [hasSubmitted, setHasSubmitted] = useState(false);
console.log(addSectionData, "addSectionDataa");
const { addSectionDetails, isLoadingAddSectionDetails, addSectionDetailsError } = useSelector((state) => state.classes);
console.log(addSectionDetails, "addSectionDetailss");
    const encodedToken = decodeJWT(token);
    const workspaceIdd = encodedToken?.payload?.workspaceId;
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            Section: "",
        },
        validationSchema: yup.object({
            // Section: yup.string().required("Section is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            try {
                console.log(values, "submitted values");
                setAddSectionData((prevData) => [...prevData, values.Section]);
                resetForm();
                handleClose();
                setHasSubmitted(true);
                const payload = addSectionData?.map((section) => ({
                    name: section,
                    studentClassDTO: {
                        id: item.id,
                    },
                }));
                dispatch(postAddSection(payload))
            } catch (error) {
                setErrorMessage("An error occurred while submitting.");
                setShowErrorMessage(true);
            }
        },
    });

    useEffect(() => {
        if (hasSubmitted) {
            if (addSectionDetails?.httpStatusCode === 200) {
                dispatch(getAllClasses(workspaceIdd));
                handleClose();
                formik.resetForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Section Added',
                    text: 'Section added successfully',
                    confirmButtonText: 'OK',
                    timer: 5000,
                    timerProgressBar: true,
                });
            } else if (addSectionDetailsError) {
                setErrorMessage(addSectionDetailsError);
                setShowErrorMessage(true);
            }
        }
    }, [hasSubmitted, addSectionDetails, addSectionDetailsError])

    const handleAddSection = () => {
        if (formik.values.Section) {
            setAddSectionData((prevData) => [...prevData, formik.values.Section]);
            formik.setFieldValue("Section", "");
        } else {
            setErrorMessage("Please select a section before adding.");
            setShowErrorMessage(true);
        }
    };

    const handleDeleteSection = (section) => {
        setAddSectionData((prevData) => prevData.filter((item) => item !== section));
    };

    const availableSections = customSectionData.filter(
        (section) => !addSectionData.includes(section)
    );

    return (
        <div className="admissionDetailsContainer">
            <Modal
                show={show}
                onHide={handleClose}
                className="customModal"
                backdrop="static"
                centered
                size="lg"
            >
                <Modal.Header>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <p className="mb-0 font16 fw500">{title}</p>
                        <div>
                            <img
                                src={Icons.crossSky}
                                alt="cross"
                                className="crossModal"
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-md-6">
                                <CommonDropdown
                                    placeHolder="Section"
                                    name="Section"
                                    customLabelStyle="greyLabel"
                                    items={availableSections} // Use filtered options
                                    onSelect={(selectedValue) =>
                                        formik.setFieldValue("Section", selectedValue)
                                    }
                                    title={formik.values.Section || "Select a Section"}
                                />
                                {formik.errors.Section && formik.touched.Section && (
                                    <div className="Inputerror">{formik.errors.Section}</div>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            {addSectionData?.map((item, index) => (
                                <div className="col-md-3 col-sm-6" key={index}>
                                    <div className="sectionAddContainer">
                                        <p className="mb-0 font14 fw600">{item}</p>
                                        <img
                                            src={Icons.crossSky}
                                            alt="delete"
                                            onClick={() => handleDeleteSection(item)}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                        {errorMessage && (
                            <ToastBootstrap
                                showErrorMessage={showErrorMessage}
                                setShowErrorMessage={setShowErrorMessage}
                                className={"toastErrorMessage"}
                                message={errorMessage}
                            />
                        )}
                        <div className="modalFooter modalDocumentFooter">
                            <div className="leftButtonFooterBox">
                                <ApplicationButton
                                    type="button"
                                    btnText="+ Add"
                                    onClick={handleAddSection}
                                    className="whiteBtn40"
                                />
                            </div>
                            <div className="rightButtonFooterBox">
                                <ApplicationButton
                                    type="submit"
                                    btnText="Done"
                                    className="blueheight40"
                                />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default AddSectionModal;
