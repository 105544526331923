import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import CommonDropdown from "../../components/applicationDropdown/ApplicationDropdown";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
import { Icons } from "../../constants/Icons";
import { postCreateTeacher } from "../../redux/reduxToolkit/actions/TeacherAction";
import decodeJWT from "../../utils/Decode";
const gendderData = ["MALE", "FEMALE","OTHERS"];
   const subjects = ["Yes", "No"];
const TeacherAddModalBody = ({ handleClose, onSuccess }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [teachers, setTeachers] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [generatedPassword, setGeneratedPassword] = useState("");
  const token = localStorage.getItem("token");
  const decodedtoken = decodeJWT(token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { addTeacher, isLoadingdataAddTeacherStatus, addTeacherError } =
    useSelector((state) => state?.teacher);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const [loginCredSelected, setLoginCredSelected] = useState("No");
  const addTeacherCall = () => {
    const payload = teachers.map((item) => {
      return {
        firstName: item.firstName,
        lastName: item.lastName,
        gender: item.gender,
        email: item.email,
        photoUri: "dummy",
        phone: item.mobile1,
        teacherId: item.teacherId,
        organizationDTO: {
          id: decodedtoken.payload.organizationId,
        },
      };
    });
    dispatch(postCreateTeacher(payload));
    setHasSubmitted(true);
  };
  const formik = useFormik({
    initialValues: {
      teacherId: "",
      gender: "",
      firstName: "",
      lastName: "",
      email: "",
      mobile1: "",
      loginCred: "",
      image: null,
      password: '',
    },
    validationSchema: Yup.object({
      teacherId: Yup.string()
  .matches(/^[a-zA-Z0-9]+$/, "Teacher ID must contain only letters and numbers")
  .required("Teacher ID is required"),
      gender: Yup.string().required("gender Id is required"),
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Please give valid email address"
        )
        .required("Email is required"),
      mobile1: Yup.string()
        .matches(/^\d{10}$/, "Please give valid Mobile number")
        .required("Mobile number is required"),
      subject: Yup.string().optional("subject is required"),
      image: Yup.mixed().required("Image is required"),
      password: Yup.string().optional("password is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      setTeachers((prevData) => [...prevData, values]);
      resetForm();
    },
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formik.setFieldValue("image", reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDeleteStudent = (index) => {
    setTeachers((prevData) => prevData.filter((_, i) => i !== index));
  };
  useEffect(() => {
    if (hasSubmitted) {
      if (addTeacher?.httpStatusCode === 200) {
        handleClose();
        window.location.reload();
        formik.resetForm();
        Swal.fire({
          icon: "success",
          title: "Teacher Added SuccessFully",
          text: "Teacher Added SuccessFully",
          confirmButtonText: "OK",
          timer: 5000,
          timerProgressBar: true,
        });
      } else if (addTeacherError) {
        setErrorMessage(addTeacherError);
        setShowErrorMessage(true);
      }
    }
  }, [hasSubmitted, addTeacher, addTeacherError]);

  const generatePassword = () => {
    const password = Math.random().toString(36).slice(-8); 
    setGeneratedPassword(password);
    formik.setFieldValue("password", password); 
  };
  return (
    <div>
      <div className="d-flex borderBottomDashed">
        <div className="text-center">
          <p className="mb-0 font10">Add Image</p>
          <div className="imageContainer mt-2">
            <input
              type="file"
              accept="image/*"
              id="fileInput"
              className="file-input"
              onChange={handleImageChange}
            />
            <label htmlFor="fileInput" className="file-label">
              <img
                src={
                  formik.values.image ? formik.values.image : Icons.imageCamera
                }
                alt="Select file"
                className="img-fluid"
              />
            </label>
            {formik.touched.image && formik.errors.image && (
              <div className="Inputerror">{formik.errors.image}</div>
            )}
          </div>
        </div>
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="row w-100 paddingLeft20">
              <div className="col-md-6 paddingBottom6">
                <TextInputWithLabel
                  label="Enter Teacher ID"
                  name="teacherId"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.teacherId}
                  error={
                    formik.touched.teacherId && formik.errors.teacherId
                      ? formik.errors.teacherId
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2 paddingBottom6">
                <CommonDropdown
                  label={"Gender"}
                  placeHolder={"Enter Gender "}
                  name="gender"
                  customLabelStyle="greyLabel"
                  title={formik.values.rteStudent}
                  onSelect={(selectedValue) =>
                    formik.setFieldValue("gender", selectedValue)
                  }
                  items={gendderData}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.gender}
                  error={
                    formik.touched.gender && formik.errors.gender
                      ? formik.errors.gender
                      : null
                  }
                />
              </div>
              <div className="col-md-6 paddingBottom6">
                <TextInputWithLabel
                  label="First Name"
                  name="firstName"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  error={
                    formik.touched.lastName && formik.errors.firstName
                      ? formik.errors.firstName
                      : null
                  }
                />
              </div>
              <div className="col-md-6 paddingBottom6">
                <TextInputWithLabel
                  label="Last Name"
                  name="lastName"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  error={
                    formik.touched.lastName && formik.errors.lastName
                      ? formik.errors.lastName
                      : null
                  }
                />
              </div>
              <div className="col-md-6 paddingBottom6">
                <TextInputWithLabel
                  label="Mobile 1"
                  name="mobile1"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobile1}
                  error={
                    formik.touched.mobile1 && formik.errors.mobile1
                      ? formik.errors.mobile1
                      : null
                  }
                />
              </div>
              <div className="col-md-6 paddingBottom6">
                <TextInputWithLabel
                  label="Email"
                  name="email"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  error={
                    formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null
                  }
                />
              </div>
              <div className="col-md-6 paddingBottom6">
                <CommonDropdown
                  label={"Create Login Credentials"}
                  placeHolder={"Login Credentials"}
                  name="loginCred"
                  customLabelStyle="greyLabel"
                  title={formik.values.documentTag}
                  onSelect={(selectedValue) => {
                    formik.setFieldValue("loginCred", selectedValue);
                    setLoginCredSelected(selectedValue); // Update the state on dropdown change
                  }}
                  items={subjects}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.loginCred}
                  error={
                    formik.touched.loginCred && formik.errors.loginCred
                      ? formik.errors.loginCred
                      : null
                  }
                />
              </div>
              {loginCredSelected === "Yes" && (
              <div className="col-md-6 paddingBottom6">
                <TextInputWithLabel
                  label={"Create Password"}
                  placeHolder={"Create Password"}
                  name="password"
                  type={showPassword ? "text" : "password"}
                  rightIcon={showPassword ? Icons.eyeClose : Icons.eyeOpen}
                  onPressRight={togglePasswordVisibility}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  error={
                    formik.touched.password && formik.errors.password
                      ? formik.errors.password
                      : null
                  }
                />
              </div>
              )}
            </div>
            {loginCredSelected  === "Yes" && (
            <div className="d-flex justify-content-center align-items-center paddingTop30">
              <div>
                <ApplicationButton
                  btnText={"Auto Generate Password"}
                  className={"button40 d-flex align-items-center"}
                  type="button"
                  onClick={generatePassword}
                />
              </div>
            </div>
            )}
            <div className="d-flex justify-content-end align-items-center paddingTop30">
              <div>
                <ApplicationButton
                  btnText={"Add Teacher"}
                  className={"button40 d-flex align-items-center"}
                  type="submit"
                  icon={Icons.addStudent}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {teachers?.length > 0 ? (
        <div className="studentTableContainer">
          <table className="studentTable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Image</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Mobile Number</th>
                <th>Address</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {teachers?.map((teacher, index) => (
                <tr key={index}>
                  <td>{teacher.teacherId}</td>
                  <td>
                    {teacher.image && (
                      <img
                        src={teacher.image}
                        alt="student"
                        className="studentImage"
                      />
                    )}
                  </td>
                  <td>{teacher.firstName}</td>
                  <td>{teacher.lastName}</td>
                  <td>{teacher.email}</td>
                  <td>{teacher.mobile1}</td>
                  <td>
                    <div
                      onClick={() => handleDeleteStudent(index)}
                      className="text-center"
                    >
                      <img
                        src={Icons.delete}
                        alt="delete"
                        className="deleteButton"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
      <div className="btnGroup">
        <div>
          <ApplicationButton
            btnText="Close"
            onClick={handleClose}
            className={"whiteBtn40"}
          />
        </div>
        <div className="paddingLeft20">
          <ApplicationButton
            btnText="Save"
            disabled={teachers.length > 0 ? false : true}
            onClick={addTeacherCall}
            className={"blueheight40"}
          />
        </div>
      </div>
    </div>
  );
};

export default TeacherAddModalBody;
