import React from "react";
import AttendanceChart from "./AttendenceChart";
import { Icons } from "../../../constants/Icons";
import "./Student.css";
import SubjectProgress from "./SubjectProgressbar";
const subjects = [
  { name: "English", progress: 80 },
  { name: "Math", progress: 70 },
  { name: "Science", progress: 60 },
];
const StudentOverView = () => {
  const presentDays = 20;
  const absentDays = 30;
  return (
    <div className="studentOverviewContainer">
      <div className="row w-100">
        <div className="col-md-7">
          <div className="CustomCard">
            <p className="fw600 font18 mb-0">Attandence</p>
            <div className="row w-100">
              <div className="col-md-6">
                <AttendanceChart
                  presentPercentage={presentDays}
                  absentPercentage={absentDays}
                />
              </div>
              <div className="col-md-6">
                <div className="attendenceBox">
                  <p className="font18 fw600 persentattendence">
                    {presentDays}% Attendance
                    <img
                      alt="link"
                      src={Icons.externalLink}
                      className="ms-2 externalLinkImg"
                    />
                  </p>
                  <div>
                    <p className="font16 fw500 days-text presentDays">
                      <span className="present">
                        ●
                      </span>{" "}
                      Present Day
                    </p>
                    <p className="mb-0 font16 fw500  days-text">
                      <span className="absent">
                        ●
                      </span>{" "}
                      Absent Days
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="overView-custom-card">
            <div className="overView-icon-wrapper subject-card">
              <img src={Icons.feePaidIcon} alt="icon" className="overView-icon" />
            </div>
            <div className="overView-text-wrapper">
              <h4 className="overView-card-number">6</h4>
              <p className="overView-card-description">Subjects</p>
            </div>
          </div>

          <div className="mt-3 overView-custom-card">
            <div className="overView-icon-wrapper paid-card">
              <img src={Icons.feePaidIcon} alt="icon" className="overView-icon" />
            </div>
            <div className="overView-text-wrapper">
              <h4 className="overView-card-number">Paid</h4>
              <p className="overView-card-description">Fees (Current Month)</p>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="gradeCardContainer">
            <p className="fw600 font18 gradeCardHeader">Grade Card</p>
            <div className="row">
              {subjects.map((subject, index) => (
                <>
                  <div className="col-md-2">
                  <div className="subjectProgressSection">
                    <p className="mb-0 font14 fw600 subjectName">{subject.name}</p>
                    </div>
                  </div>
                  <div className="col-md-10">
                    <div className="subjectProgressSection">
                      <SubjectProgress
                        key={index}
                        initialProgress={subject.progress}
                      />
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentOverView;
