import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import UserLayout from "../../components/layout/UserLayout";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";
import "./Classes.css";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import AddSectionModal from "../../components/modal/addClass/AddSectionModal";
import { useDispatch, useSelector } from "react-redux";
import { getAllClasses } from "../../redux/reduxToolkit/actions/ClassesAction";
import decodeJWT from "../../utils/Decode";

const ClassesDetails = () => {
    const location = useLocation();
    const item = location.state?.item;
    console.log(item, "itemDetails");
    const token = localStorage.getItem("token");
    const encodedToken = decodeJWT(token);
    const workspaceIdd = encodedToken?.payload?.workspaceId;
    const dispatch = useDispatch();
    const [showAddSectionModal, setShowAddSectionModal] = useState(false);
    const {
        getAllClassesData,
        isLoadingGetAllClassesData,
        getAllClassesDataError,
        addSectionDetails, isLoadingAddSectionDetails, addSectionDetailsError
    } = useSelector((state) => state?.classes);
    useEffect(()=>{
        dispatch(getAllClasses(workspaceIdd));
    },[])
    const matchedClass = getAllClassesData?.find(
        (classItem) => classItem.name === item?.name
    );
    console.log(getAllClassesData, "getAllClassesDataaaaa");
    console.log(matchedClass, "matchedClassss")

    return (
        <UserLayout>
            <ApplicationHeader
                title="Classes"
            />
            <div className="classesDetailsContainer">
                <div className="classesBox">
                    <p className="mb-0 font18 fw600 classNameColor">Class {item.name}</p>
                    <ApplicationButton
                        btnText={"Add Section"}
                        className={"button40"}
                        onClick={() => setShowAddSectionModal(true)}
                    />
                </div>
                <p className="mb-0 font14 fw600 classNameColor mt-2">Subject: Hindi ,English, Maths</p>
                <p className="mb-0 font14 fw600 classNameColor mt-2">Session-12-04-24 To 30-03-25</p>
            </div>
            <div className="sectionDisplay">
                <div className="row">
                    {matchedClass?.sections?.map((section, sectionIndex) => (
                        <div className="col-md-3" key={`${sectionIndex}`}>
                            <div className="class-card">
                                <div className="class-title">{section.name}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <AddSectionModal
                show={showAddSectionModal}
                handleClose={() => setShowAddSectionModal(false)}
                title="Add Section"
                item={item}
            />
        </UserLayout>
    )
}

export default ClassesDetails;