import React from "react";
import UserLayout from "../../components/layout/UserLayout";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";

const Reports = () =>{
    localStorage.removeItem("eventResponse");
    return(
        <UserLayout>
            <ApplicationHeader title="Reports"/>
          <p>Comming Soon</p>
        </UserLayout>
    )
}

export default Reports;