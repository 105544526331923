





import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import "./Modal.css"
import ToastBootstrap from "../toast/Toast";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getApiUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchPreviousEducationDetails } from "../../redux/reduxToolkit/actions/StudentAction";
const PreviousEducationModal = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const studentsDetails = useSelector((state) => state.student.getStudentDetails);
  const {studentPrevEducationDetails, isLoadingstudentPrevDetailsStatus, studentPrevEducationDetailsError} = useSelector((state) => state?.student);

  const formik = useFormik({
    initialValues: {
      tcId: studentPrevEducationDetails ? studentPrevEducationDetails?.response?.tcId : studentsDetails?.studentPrevEduDTO? studentsDetails?.studentPrevEduDTO?.tcId: "",
      lastSchoolName: studentPrevEducationDetails ? studentPrevEducationDetails?.response?.lastSchoolName : studentsDetails?.studentPrevEduDTO? studentsDetails?.studentPrevEduDTO?.lastSchoolName: "",
      lastSchoolBoard:studentPrevEducationDetails ? studentPrevEducationDetails?.response?.lastSchoolBoard : studentsDetails?.studentPrevEduDTO? studentsDetails?.studentPrevEduDTO?.lastSchoolBoard: "",
      lastSchoolAddress: studentPrevEducationDetails ? studentPrevEducationDetails?.response?.lastSchoolAddress : studentsDetails?.studentPrevEduDTO? studentsDetails?.studentPrevEduDTO?.lastSchoolAddress: "",
    },
    validationSchema: yup.object({
      tcId: yup.string().required("TC Id is required"),
      lastSchoolName: yup.string().required("Last School Name is required"),
      lastSchoolBoard: yup.string().required("Last School Board is required"),
      lastSchoolAddress: yup.string().required("Last School Address is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const studentDto=localStorage.getItem("studentDto")
      const payload= {
        "studentDTO" : {
            "id" :studentDto
        },
        "id": studentPrevEducationDetails ? studentPrevEducationDetails?.response?.id : studentsDetails?.studentPrevEduDTO? studentsDetails?.studentPrevEduDTO?.id: null ,
        "uuid": studentPrevEducationDetails ? studentPrevEducationDetails?.response?.uuid : studentsDetails?.studentPrevEduDTO? studentsDetails?.studentPrevEduDTO?.uuid: null,
        "createdDate": studentPrevEducationDetails ? studentPrevEducationDetails?.response?.createdDate : studentsDetails?.studentPrevEduDTO? studentsDetails?.studentPrevEduDTO?.createdDate: null,
        "lastSchoolName" :values.lastSchoolName,
        "lastSchoolAddress" : values.lastSchoolAddress,
        "lastSchoolBoard" : values.lastSchoolBoard,
        "tcId" : values.tcId 
    }
    
    dispatch(fetchPreviousEducationDetails(payload));
    setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
    if (studentPrevEducationDetails?.httpStatusCode === 200) {
      props.handleClose();
      formik.resetForm();
      Swal.fire({
        icon: 'success',
        title: ' Previous Education Details',
        text: 'Student  Previous Education Details saved',
        confirmButtonText: 'OK',
        timer: 5000,
        timerProgressBar: true,
      });
    } else if (studentPrevEducationDetailsError) {
       setErrorMessage(studentPrevEducationDetailsError);
       setShowErrorMessage(true);
    }
  }
  }, [hasSubmitted, studentPrevEducationDetails, studentPrevEducationDetailsError]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextInputWithLabel
              label={"TC Id"}
              placeHolder={"Enter TC Id"}
              name="tcId"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.tcId}
              error={
                formik.touched.tcId && formik.errors.tcId
                  ? formik.errors.tcId
                  : null
              }
            />
          </div>
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Last School Name"}
              placeHolder={"Last School Name"}
              name="lastSchoolName"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastSchoolName}
              error={
                formik.touched.lastSchoolName && formik.errors.lastSchoolName
                  ? formik.errors.lastSchoolName
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Last School Board"}
              placeHolder={"Enter Last School Board"}
              name="lastSchoolBoard"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastSchoolBoard}
              error={
                formik.touched.lastSchoolBoard && formik.errors.lastSchoolBoard
                  ? formik.errors.lastSchoolBoard
                  : null
              }
            />
          </div>
          <div className="col-md-12 mt-2">
            <lable for="textareaId" className="label-text-style fw500 greyLabel">Last School Address</lable>
            <textarea
              rows="4"
              className="applicationTextArea"
              id="textareaId"
              name="lastSchoolAddress"
              placeholder="Last School Address"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastSchoolAddress}
              error={
                formik.touched.lastSchoolAddress && formik.errors.lastSchoolAddress
                  ? formik.errors.lastSchoolAddress
                  : null
              }></textarea>
          </div>
        </div>
        {errorMessage ?
            <ToastBootstrap showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage} className={"toastErrorMessage"} message={errorMessage} /> : null
     }
        <div className="row pt30 justify-content-end">
          <div className="col-md-2">
            <ApplicationButton btnText={"Save"} type="submit" className="blueheight40" />
          </div>
        </div>
      </form>
    </>
  );
};

export default PreviousEducationModal;
