import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GetCookie from "../../persistance/cookies/GetCookies";

function ProtectedRoute(props) {
  let Cmp = props.Cmp;
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/sign-in");
     }
  }, [token]);
  return (
    <>
      <Cmp />
    </>
  );
}

export default ProtectedRoute;
