import React from "react";
import "./ProfileProgress.css";
import FirstLastNameAvatar from "../avatar/FirstLastNameAvatar";

const ProfileProgress = (props) => {
  return (
    <div className="profileProgressContainer">
      <div className="avatarContainer">
        <div className="progressCircle">
          <div className="profileImage" >
            <FirstLastNameAvatar name={props.name!=='undefined undefined'?props.name:'....'} />
          </div>
          <svg className="progressSvg" width="100%" height="100%">
            <circle
              className="progressBackground"
              cx="50%"
              cy="50%"
              r="45"
              strokeWidth="10"
              fill="none"
            />
            <circle
              className="progressBar"
              cx="50%"
              cy="50%"
              r="45"
              strokeWidth="10"
              fill="none"
              strokeDasharray="282"
              strokeDashoffset={282 - (282 * (props.progress!==undefined?props.progress:0)) / 100}
            />
          </svg>
          <div className="progressLabel">
            {props.progress!==undefined?props.progress:0}%
          </div>
        </div>
      </div>
      <p className="studentId font20 fw600">Student ID: {props.id}</p>
      <h4 className="studentName font20 fw600">{props.name!=='undefined undefined'?props.name:'....'}</h4>
      <p className="studentClass font16 fw500">Class-{props.class!==undefined?<span className="text-uppercase">{`${props.class}${props.section}`}</span>: "..."}</p>
    </div>
  );
};

export default ProfileProgress;
