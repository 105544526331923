import React, { useEffect, useState } from "react";
import "./CreatePassword.css";
import '../signUp/Signup.css';
import { Icons } from "../../constants/Icons";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getSignUpUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import enCodeJwt from "../../utils/Encode";
import SetCookie from "../../persistance/cookies/SetCookies";
import GetCookie from "../../persistance/cookies/GetCookies";
import ToastBootstrap from "../../components/toast/Toast";
const CreatePassword = () => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues: {
            password: "",
            confirmPassword: "",
        },
        validationSchema: yup.object({
            password: yup
                .string()
                .min(7, "Password must be at least 7 characters")
                .required("Please enter your password"),
            confirmPassword: yup
                .string()
                .oneOf([yup.ref('password'), null], "Passwords must match") // Ensure passwords match
                .required("Please confirm your password"),
        }),
        onSubmit: async (values, { resetForm }) => {
            const searchParams = new URLSearchParams(window.location.search);
            const headerToken = searchParams.get("token");
            const verifyEmailToken = {
                password: values.password,
            };
            const passwordToken = enCodeJwt(verifyEmailToken);
            const payloadVerificationApi = {
                "passwordToken": passwordToken,
                "headerToken": headerToken
            }



            api.publicPost(getSignUpUrl() + apiUrls.signUp.verfiyToken, payloadVerificationApi)
                .then(response => {
                    if (response.data.httpStatusCode == 200) {
                        localStorage.setItem("token", response.data.response.authToken);
                        navigate("/workspace");
                    } else {
                        console.error("API error response:", response.data);
                        setErrorMessage(response.data.message);
                        setShowErrorMessage(true);
                    }
                })
                .catch(error => {
                    setErrorMessage(error.response?.data?.message || "An unexpected error occurred. Please try again.");
                    setShowErrorMessage(true);
                });
        },
    });
    useEffect(() => {
        const token = localStorage.getItem('token');
       if (token) {
          navigate("/");
          }
      }, []);
    return (
        <div className="signUpContainer">
            <div className="signUpTop forgotPasswordTop">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-md-6">
                            <div className="rightSideSignUp rightSideForgetPassword">
                                <div className="custokingLogo">
                                    <img src={Icons.custokingLogo} className="img-fluid" />
                                </div>
                                <p className="text-white font30 fw600 signUpTo">Create Password</p>
                            </div>
                        </div>
                        <div className="col-xl-5 col-md-6 position-relative">
                            <div className="loginCard forgotPasswordCard">
                                <div className="row">
                                    <form onSubmit={formik.handleSubmit}>
                                        <div className="col-md-12 pt15">
                                            <TextInputWithLabel label={"Password"} placeHolder={"Password"}
                                                type="password"
                                                name="password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.password}
                                                error={formik.touched.password && formik.errors.password ? formik.errors.password : null} />
                                        </div>
                                        <div className="col-md-12 pt15">
                                            <TextInputWithLabel label={"Confirm Password"} placeHolder={"Confirm Password"}
                                                name="confirmPassword"
                                                type="password"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.confirmPassword}
                                                error={formik.touched.confirmPassword && formik.errors.confirmPassword ? formik.errors.confirmPassword : null}
                                            />
                                        </div>
                                        {errorMessage ?
                                                <ToastBootstrap showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage} className={"toastErrorMessage"} message={errorMessage} /> : null
                                            }
                                        <div className="col-md-12 pt30">
                                            <ApplicationButton btnText={"Submit"} type="submit" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreatePassword;