import { createSlice, createAction } from '@reduxjs/toolkit';
import { saveFeesStructure } from '../actions/FeesAction';

const feesSlice = createSlice({
    name: 'fees',
    initialState: {
        saveFeesStructureData: null,

        isLoadingSaveFeesStructure: false,

        saveFeesStructureError: null,
    },
    reducers: {
        feesState: (state) => {
            state.saveFeesStructureData = null;

            state.saveFeesStructureError = null;

        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(saveFeesStructure.pending, (state) => {
                state.isLoadingSaveFeesStructure = true;
                state.saveFeesStructureError = null;
            })
            .addCase(saveFeesStructure.fulfilled, (state, action) => {
                state.isLoadingSaveFeesStructure = false;
                state.saveFeesStructureData = action.payload;
            })
            .addCase(saveFeesStructure.rejected, (state, action) => {
                state.isLoadingSaveFeesStructure = false;
                state.saveFeesStructureError = action.payload;
            })


    },
});
// Export the reducer

export const { feesState } = feesSlice.actions;
export default feesSlice.reducer;