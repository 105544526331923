
import React, { useEffect, useState } from 'react';
import StudentSideNavbar from '../../../components/SideNavbar/StudentSideNavbar';
import UserLayout from '../../../components/layout/UserLayout';
import ApplicationHeader from '../../../components/applicationHeader/ApplicationHeader';
import SecondHeaderStudent from '../../../components/applicationHeader/secondHeaderStudent/SecondHeaderStudent';
import StudentPerformance from './StudentPerformance';
import "./StudentDetails.css";
import { useDispatch, useSelector } from 'react-redux';
import { fetchDocuments, getStudentDetails } from '../../../redux/reduxToolkit/actions/StudentAction';
const StudentDetails = () => {
    localStorage.removeItem("eventResponse");
    // const [studentsDetails, setStudentsDetails] = useState();
    const studentsDetails = useSelector((state) => state.student.getStudentDetails);
    // console.log(getStudentDetails,"studentsDetails")
    //     const token = localStorage.getItem("token");
    //   const encodedToken = decodeJWT(token);getStudentDetails
    //   const workspaceIdd = encodedToken?.payload?.workspaceId
    // useEffect(()=>{
    //   setStudentsDetails();
    // },[getStudentDetails])
    const dispatch = useDispatch();
    const studentId = localStorage.getItem("studentDto");
    console.log(studentId,"studentIdd")
    useEffect(() => {
        dispatch(fetchDocuments(studentId))
        dispatch(getStudentDetails(studentId))
    }, [dispatch, studentId])
    
    const studentsDocuments = useSelector((state) => state.student.fetchDocumentData);
    console.log(studentsDocuments, "studentsDocumentss");
    return (
        <UserLayout>
            <ApplicationHeader title="Student" />
            <SecondHeaderStudent />
            <div className='row'>
                <div className='col-md-3'>
                    <StudentSideNavbar studentsDetails={studentsDetails} studentsDocuments={studentsDocuments}/>
                </div>
                <div className='col-md-9'>
                    <StudentPerformance />
                </div>
            </div>
        </UserLayout>
    )
}

export default StudentDetails;