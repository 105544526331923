import React, { useEffect, useState } from "react";
import UserLayout from "../../../components/layout/UserLayout";
import ApplicationHeader from "../../../components/applicationHeader/ApplicationHeader";
import "./Student.css";
import StudentTable from "./StudentTable";
import SecondHeaderStudent from "../../../components/applicationHeader/secondHeaderStudent/SecondHeaderStudent";
import { useSelector } from "react-redux";
import StudentEmptyState from "../../../components/emptyState/StudentEmptyState";
import CommonSpinner from "../../../components/spinner/Spinner";

const Student = () => {
  const [shouldFetchData, setShouldFetchData] = useState(false);

  const handlePostSuccess = () => {
    setShouldFetchData(true);
  };
  useEffect(() => {
    localStorage.removeItem("eventResponse")
  }, [])
  const { countStudent, isLoadingdataStudentStatus, isLoadingmatchStudentStatus } = useSelector((state) => state?.student);
  return (
    <UserLayout>
      <ApplicationHeader title="Student" />
      <SecondHeaderStudent onSuccess={handlePostSuccess} />
      <div className="studentTableContainer">
        {isLoadingdataStudentStatus && isLoadingmatchStudentStatus ?
          <CommonSpinner /> :
          countStudent > 0 ?
            <StudentTable shouldFetchData={shouldFetchData} setShouldFetchData={setShouldFetchData} />
            :
            <StudentEmptyState />
        }
      </div>
    </UserLayout>
  )
}

export default Student;