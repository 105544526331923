import { createAsyncThunk } from "@reduxjs/toolkit";
import decodeJWT from "../../../utils/Decode";
import axiosInstance from "../../../services/api/Api";
import { getApiUrl } from "../../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../../services/endPoints/ApiUrls";


// Decode the token and get the workspaceId
const token = localStorage.getItem('token');
const encodedToken = decodeJWT(token);
const workspaceIdd = encodedToken?.payload?.workspaceId;


export const postAddClass = createAsyncThunk(
    'classes/postAddClass',
    async (payload, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          getApiUrl() + apiUrls.class.addClass,
          payload,
        );
  
        if (response.status === 200) {
          return response.data;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );
export const postAddSection= createAsyncThunk(
    'classes/postAddSection',
    async (payload, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.post(
          getApiUrl() + apiUrls.class.addSection,
          payload,
        );
  
        if (response.status === 200) {
          return response.data;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );

  export const getAllClasses = createAsyncThunk(
    'classes/getAllClasses',
    async (value, { rejectWithValue }) => {
      try {
        const response = await axiosInstance.get(getApiUrl() + apiUrls.class.getAllClasses(value));
        if (response.status === 200) {
          return response.data.response;
        } else {
          return rejectWithValue(response.data.message);
        }
      } catch (error) {
        return rejectWithValue(
          error.response?.data?.message || "An unexpected error occurred. Please try again."
        );
      }
    }
  );

