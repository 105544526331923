

/**
 * Formats a given date to 'YYYY-MM-DD' format.
 * @param {Date} date - The date to format.
 * @returns {string} - The formatted date string in 'YYYY-MM-DD'.
 */
export     const cFormatDate = (date) => {
  const year = date.getFullYear().toString(); 
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};