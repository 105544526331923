
import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import Swal from "sweetalert2";
import ToastBootstrap from "../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { fetchAcademicDetailsStudent } from "../../redux/reduxToolkit/actions/StudentAction";
const AcademicDetailsModal = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const studentsDetails = useSelector((state) => state.student.getStudentDetails);
  const {studentAcademicDetails, isLoadingstudentAcademicDetailsStatus, studentAcademicDetailsError} = useSelector((state) => state?.student);
  const formik = useFormik({
    initialValues: {
      class: studentAcademicDetails ? studentAcademicDetails?.response?.studentClass : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.studentClass: "",
      secationName:studentAcademicDetails ? studentAcademicDetails?.response?.section : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.section: "",
      rollNumber:studentAcademicDetails ? studentAcademicDetails?.response?.rollNo : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.rollNo: "",
      firstLanguage: studentAcademicDetails ? studentAcademicDetails?.response?.firstLanguage : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.firstLanguage: "",
      secondLanguage: studentAcademicDetails ? studentAcademicDetails?.response?.secondLanguage : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.secondLanguage: "",
      languagePreferred:studentAcademicDetails ? studentAcademicDetails?.response?.languagesPreferred : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.languagesPreferred: "",
      stream: studentAcademicDetails ? studentAcademicDetails?.response?.stream : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.stream: "",
    },
    validationSchema: yup.object({
      class: yup.string().required("Class is required"),
      secationName: yup.string().required("Section Name is required"),
      rollNumber: yup.string().required("Roll Number is required"),
      firstLanguage: yup.string().required("First Language is required"),
      secondLanguage: yup.string().required("Second Language is required"),
      languagePreferred: yup.string().required("Language Preferred is required"),
      stream: yup.string().required("Stream is required")
    }),
    onSubmit: async (values, { resetForm }) => {
    const studentDto=localStorage.getItem("studentDto")
     const payload= {
        "studentDTO": {
            "id": studentDto
        },
        "id": studentAcademicDetails ? studentAcademicDetails?.response?.id : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.id: null ,
        "uuid": studentAcademicDetails ? studentAcademicDetails?.response?.uuid : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.uuid: null,
        "createdDate": studentAcademicDetails ? studentAcademicDetails?.response?.createdDate : studentsDetails?.studentAcademicDTO? studentsDetails?.studentAcademicDTO?.createdDate: null,
        "studentClass": values.class,
        "section": values.secationName,
        "rollNo": values.rollNumber,
        "firstLanguage": values.firstLanguage,
        "secondLanguage": values.secondLanguage,
        "stream": values.stream,
        "languagesPreferred": values.languagePreferred
    }
    dispatch(fetchAcademicDetailsStudent(payload));
    setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
      if (studentAcademicDetails.httpStatusCode === 200) {
        props.handleClose();
        formik.resetForm();
        Swal.fire({
          icon: "success",
          title: " Academic Details",
          text: "Student Academic Details saved",
          confirmButtonText: "OK",
          timer: 5000,
          timerProgressBar: true,
        });
      } else if (studentAcademicDetailsError) {
        setErrorMessage(studentAcademicDetailsError);
         setShowErrorMessage(true);
      }
    }
  }, [hasSubmitted, studentAcademicDetails, studentAcademicDetailsError]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Class"}
              placeHolder={"Enter Class"}
              name="class"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.class}
              error={
                formik.touched.class && formik.errors.class
                  ? formik.errors.class
                  : null
              }
            />
          </div>
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Sec"}
              placeHolder={"Enter Sec"}
              name="secationName"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.secationName}
              error={
                formik.touched.secationName && formik.errors.secationName
                  ? formik.errors.secationName
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Roll No"}
              placeHolder={"Enter Roll No"}
              name="rollNumber"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.rollNumber}
              error={
                formik.touched.rollNumber && formik.errors.rollNumber
                  ? formik.errors.rollNumber
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"First Language"}
              placeHolder={"First Language"}
              name="firstLanguage"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstLanguage}
              error={
                formik.touched.firstLanguage && formik.errors.firstLanguage
                  ? formik.errors.firstLanguage
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Second Language"}
              placeHolder={"Enter Second Language"}
              name="secondLanguage"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.secondLanguage}
              error={
                formik.touched.secondLanguage && formik.errors.secondLanguage
                  ? formik.errors.secondLanguage
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Language Preferred"}
              placeHolder={"Enter Language Preferred"}
              name="languagePreferred"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.languagePreferred}
              error={
                formik.touched.languagePreferred && formik.errors.languagePreferred
                  ? formik.errors.languagePreferred
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Stream"}
              placeHolder={"Enter Stream"}
              name="stream"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stream}
              error={
                formik.touched.stream && formik.errors.stream
                  ? formik.errors.stream
                  : null
              }
            />
          </div>
        </div>
      {errorMessage ?
            <ToastBootstrap showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage} className={"toastErrorMessage"} message={errorMessage} /> : null
     }
        <div className="row pt30 justify-content-end">
          <div className="col-md-2">
            <ApplicationButton btnText={"Save"} type="submit" className="blueheight40" />
          </div>
        </div>
      </form>
    </>
  );
};

export default AcademicDetailsModal;
