import React, { useEffect, useRef, useState } from "react";
import "./Signup.css";
import { Icons } from "../../constants/Icons";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import enCodeJwt from "../../utils/Encode";
import Swal from "sweetalert2";
import ToastBootstrap from "../../components/toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { SignUpDataPost } from "../../redux/reduxToolkit/actions/authAction";
import DatePicker from "react-datepicker";
import { formatDate } from "../../utils/DateFormate";


const Signup = () => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const datePickerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signUpData, isLoadingSignUpData, signUpError } = useSelector(
    (state) => state?.auth
  );
  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      instituationName: "",
      academicYear:"",
      privacyPolicy: false,
    },
    validationSchema: yup.object({
      firstName: yup.string().required("First name is required"),
      lastName: yup.string().required("Last name is required"),
      email: yup
        .string()
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
          "Invalid email address"
        )
        .required("Please enter your email address"),
      phoneNumber: yup
        .string()
        .matches(/^[0-9]{10}$/, "Enter a valid phone number")
        .required("Phone number is required"),
      instituationName: yup.string().required("Institution name is required"),
      academicYear: yup.string()
        .required("Academic Year is required"),
      privacyPolicy: yup
        .boolean()
        .oneOf([true], "You must accept the privacy policy"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const verifyEmailToken = {
        firstName: values.firstName,
        lastName: values.lastName,
        emailId: values.email?.toLowerCase(),
        userRoleInCompany: "ADMIN",
        phoneNumber: values.phoneNumber,
        organizationName: values.instituationName,
        academicYearStartDate:new Date(values.academicYear).getTime()
      };

      const token = enCodeJwt(verifyEmailToken);
      const ecodeToken = {
        token: token,
      };
      dispatch(SignUpDataPost(ecodeToken));
      setHasSubmitted(true);
      // api.publicPost(getSignUpUrl() + apiUrls.signUp.verificationEmail, ecodeToken)
      //     .then(response => {
      //         // Success scenario - API returned 2xx status code
      //         if (response.success) {
      //             resetForm();
      //             Swal.fire({
      //                 icon: 'success',
      //                 title: 'Email sent successfully',
      //                 text: 'Please check your email for further instructions.',
      //                 confirmButtonText: 'OK'
      //             }).then((result) => {
      //                 if (result.isConfirmed) {
      //                     navigate('/sign-in'); // Navigate to /sign-in
      //                 }
      //             });
      //         } else {
      //             setErrorMessage(response.data.message);
      //             setShowErrorMessage(true);
      //         }
      //     })
      //     .catch(error => {
      //         setErrorMessage(error.response?.data?.message || "An unexpected error occurred. Please try again.");
      //         setShowErrorMessage(true);
      //     });
    },
  });

  useEffect(() => {
    if (hasSubmitted) {
      if (!isLoadingSignUpData && signUpData) {
        Swal.fire({
          icon: "success",
          title: "Email sent successfully",
          text: "Please check your email for further instructions.",
          confirmButtonText: "OK",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/sign-in");
          }
        });
      } else if (signUpError) {
        setErrorMessage(signUpError);
        setShowErrorMessage(true);
      }
    }
  }, [isLoadingSignUpData, signUpData, signUpError]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/");
    }
  }, []);
  return (
    <div className="signUpContainer">
      <div className="signUpTop">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-md-6">
              <div className="rightSideSignUp">
                <div className="custokingLogo">
                  <img
                    src={Icons.custokingLogo}
                    className="img-fluid"
                    alt="Custoking Logo"
                  />
                </div>
                <p className="text-white font30 fw600 signUpTo">Sign Up to</p>
                <p className="text-white font20 fw400">Custoking School ERP</p>
                <p className="text-white font16 fw400">
                  Custoking has developed one of the most advanced and
                  user-friendly School ERP Software for managing various school
                  activities.
                </p>
              </div>
            </div>
            <div className="col-xl-5 col-md-6 position-relative">
              <div className="loginCard">
                <div className="welcomeText">
                  <div>
                    <p className="font20 fw500 welcomeCustoking">
                      Welcome to <span className="fw600">CustoKing</span>
                    </p>
                  </div>
                  <div>
                    <p className="haveAccount fz12 fw400">Have an Account?</p>
                    <Link to="/" className="link-decoration">
                      {" "}
                      <p className="signInText ">Sign In</p>
                    </Link>
                  </div>
                </div>
                <div className="signUpText">
                  <p className="font36 fw600">Sign up</p>
                </div>
                <form onSubmit={formik.handleSubmit}>
                  <div className="row">
                    <div className="col-md-6">
                      <TextInputWithLabel
                        label={"First Name"}
                        placeHolder={"First Name"}
                        name="firstName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.firstName}
                        error={
                          formik.touched.firstName && formik.errors.firstName
                            ? formik.errors.firstName
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-6">
                      <TextInputWithLabel
                        label={"Last Name"}
                        placeHolder={"Last Name"}
                        name="lastName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.lastName}
                        error={
                          formik.touched.lastName && formik.errors.lastName
                            ? formik.errors.lastName
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-12 pt15">
                      <TextInputWithLabel
                        label={"Email Address"}
                        placeHolder={"Email Address"}
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        error={
                          formik.touched.email && formik.errors.email
                            ? formik.errors.email
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-12 pt15">
                      <TextInputWithLabel
                        label={"Phone"}
                        placeHolder={"Phone"}
                        name="phoneNumber"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.phoneNumber}
                        error={
                          formik.touched.phoneNumber &&
                          formik.errors.phoneNumber
                            ? formik.errors.phoneNumber
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-12 pt15">
                      <TextInputWithLabel
                        label={"Institution Name"}
                        placeHolder={"Institution Name"}
                        name="instituationName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.instituationName}
                        error={
                          formik.touched.instituationName &&
                          formik.errors.instituationName
                            ? formik.errors.instituationName
                            : null
                        }
                      />
                    </div>
                    <div className="col-md-12 pt15">
                      <label
                        for="dateofAdmission"
                        className="label-text-style fw600"
                      >
                        Academic Year
                      </label>
                      <div>
                        <div className="position-relative">
                          <DatePicker
                          name="dateofAdmission"
                            ref={datePickerRef}
                            selected={formik.values.academicYear}
                            onChange={(date) =>
                              formik.setFieldValue("academicYear", date)
                            }
                            onBlur={formik.handleBlur}
                            dateFormat="yyyy-MM-dd"
                            className="inline-style fw500 w-100 academicYear"
                            placeholderText="Academic year"
                            id="dateofAdmission"
                          />
                          <div
                            className="datePickerIcon"
                            onClick={() => datePickerRef.current.setFocus()}
                          >
                            <img src={Icons.calendarAcademic} alt="date" />
                          </div>
                        </div>
                        {formik.touched.academicYear &&
                        formik.errors.academicYear ? (
                          <div className="Inputerror">
                            {formik.errors.academicYear}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12 pt15">
                      <label className=" labelCheckbox  checkbox-container">
                        <input
                          id="custom-checkbox"
                          className="ms-2"
                          type="checkbox"
                          name="privacyPolicy"
                          checked={formik.values.privacyPolicy}
                          onChange={formik.handleChange}
                        />
                        <span className="ms-2 labelCheckbox font12 fw500">
                          <span className="black">
                            By continuing, you agree to accept our{" "}
                            <span className="blue">Privacy Policy</span> &{" "}
                            <span className="blue">Terms of Services</span>.
                          </span>
                        </span>
                      </label>
                      {formik.touched.privacyPolicy &&
                      formik.errors.privacyPolicy ? (
                        <div className="Inputerror">
                          {formik.errors.privacyPolicy}
                        </div>
                      ) : null}
                    </div>
                    {errorMessage ? (
                      <ToastBootstrap
                        showErrorMessage={showErrorMessage}
                        setShowErrorMessage={setShowErrorMessage}
                        className={"toastErrorMessage"}
                        message={errorMessage}
                      />
                    ) : null}
                    <div className="col-md-12 pt30">
                      <ApplicationButton btnText={"Sign Up"} type="submit" className={"w-100"}/>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
