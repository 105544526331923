import React from "react";



const ClassTeacher=()=>{
    return(
        <>
        class
        </>
    )
  
    
}

export default ClassTeacher;