import { createSlice } from '@reduxjs/toolkit';

// Create a slice for managing personal details ID
const personalDetailsIdSlice = createSlice({
  name: 'personalDetailsId',
  initialState: {
    id: null,  // Initial state for storing ID
  },
  reducers: {
    setPersonalDetailsId(state, action) {
      state.id = action.payload;  // Update the ID in the state
    },
  },
});

// Create a slice for managing academic details ID
const academicDetailsIdSlice = createSlice({
  name: 'academicDetailsId',
  initialState: {
    id: null,  // Initial state for storing ID
  },
  reducers: {
    setAcademicDetailsId(state, action) {
      state.id = action.payload;  // Update the ID in the state
    },
  },
});

// Export actions
export const { setPersonalDetailsId } = personalDetailsIdSlice.actions;
export const { setAcademicDetailsId } = academicDetailsIdSlice.actions;

// Export reducers
export const personalDetailsIdReducer = personalDetailsIdSlice.reducer;
export const academicDetailsIdReducer = academicDetailsIdSlice.reducer;
