import React, { useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import { Modal } from "react-bootstrap";
import { Icons } from "../../constants/Icons";
import CommonDropdown from "../applicationDropdown/ApplicationDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Modal.css";
import TextInputWithLabel from "../applicationInput/ApplicationInput";

const subjects = [
    "English",
    "Hindi",
    "Science",
    "Social Science",
    "Mathematics",
];

const SubjectDetailsDocumentsModal = ({ show, handleClose, modalTitle }) => {
    const fileInputRefs = useRef({});
    const formik = useFormik({
        initialValues: {
            fileName: "",
            description: "",
            fileInput: null,
            documentTag: null,
        },
        validationSchema: yup.object({
            fileName: yup.string().required("Subject is required"),
            description: yup.string().required("Description is required"),
            fileInput: yup
                .mixed()
                .test("fileSize", "Please upload a PDF less than 5MB.", (value) => {
                    return value ? value.size <= 5 * 1024 * 1024 : true;
                }),
            documentTag: yup.string().required("Document Tag is required")
        }),
        onSubmit: async (values, { resetForm }) => {
            resetForm();
        },
    });
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const fieldName = event.target.name;

        if (file) {
            // Validate file type
            if (!file.type.includes("pdf")) {
                formik.setFieldError(fieldName, "Please upload a valid PDF file.");
                return;
            }

            // Validate file size
            if (file.size > 5 * 1024 * 1024) {
                formik.setFieldError(fieldName, "File size should be less than 5MB.");
                return;
            }

            // Set field value and clear errors
            formik.setFieldValue(fieldName, file);
            formik.setFieldError(fieldName, "");
        }
    };
    const handleClick = (fieldName) => {
        fileInputRefs.current[fieldName].click();
    };
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="customModal"
                backdrop="static"
                centered
                size="lg"
            >
                <Modal.Header>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <p className="mb-0 font16 fw500">{modalTitle}</p>
                        <div>
                            <img
                                src={Icons.crossSky}
                                alt="cross"
                                className="crossModal"
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">
                            <div className="col-12">
                                <TextInputWithLabel
                                    label={"File Name"}
                                    placeHolder={"Enter File Name"}
                                    name="fileName"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.fileName}
                                    error={
                                        formik.touched.fileName && formik.errors.fileName
                                            ? formik.errors.fileName
                                            : null
                                    }
                                />
                            </div>
                            <div className="col-12 mt-2">
                                <TextInputWithLabel
                                    label={"Description"}
                                    placeHolder={"Enter Description"}
                                    name="description"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.description}
                                    error={
                                        formik.touched.description && formik.errors.description
                                            ? formik.errors.description
                                            : null
                                    }
                                />
                            </div>
                            <div className="col-md-12 mt-2">
                                <label className="label-text-style fw500 greyLabel">File Input</label>
                                <div className="file-input-container input-date-picker">
                                    <button
                                        type="button"
                                        className="upload-button"
                                        onClick={() => handleClick("fileInput")}
                                    >
                                        Upload
                                    </button>
                                    <span>
                                        {formik.values.fileInput ? formik.values.fileInput.name : "No file chosen"}
                                    </span>
                                    <input
                                        type="file"
                                        ref={(el) => (fileInputRefs.current.fileInput = el)}
                                        className="file-input"
                                        accept=".pdf"
                                        name="fileInput"
                                        onChange={handleFileChange}
                                        onBlur={formik.handleBlur}
                                        hidden
                                    />
                                </div>
                                {formik.errors.fileInput && (
                                    <div className="Inputerror">
                                        {formik.errors.fileInput}
                                    </div>
                                )}
                            </div>
                            <div className="col-md-12 mt-2">
                                <CommonDropdown
                                    label={"Document Tag"}
                                    placeHolder={"Select Document Tag"}
                                    name="documentTag"
                                    customLabelStyle="greyLabel"
                                    title={formik.values.documentTag}
                                    onSelect={(selectedValue) => formik.setFieldValue('documentTag', selectedValue)}
                                    items={subjects}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.documentTag}
                                    error={
                                        formik.touched.documentTag && formik.errors.documentTag
                                            ? formik.errors.documentTag
                                            : null
                                    }
                                />
                            </div>

                        </div>
                        <div className="row pt30 justify-content-end">
                            <div className="col-md-2">
                                <ApplicationButton btnText={"Save"} type="submit" className="blueheight40" />
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SubjectDetailsDocumentsModal;
