import React from "react";
import { Icons } from "../../constants/Icons";

const paymentData = [
  { date: "3 Mar 2023", amount: "INR 52,526" },
  { date: "3 Mar 2023", amount: "INR 52,526" },
  { date: "3 Mar 2023", amount: "INR 52,526" },
  { date: "3 Mar 2023", amount: "INR 52,526" },
  { date: "3 Mar 2023", amount: "INR 52,526" },
];

const FeesBreakUp = () => {
  return (
    <>
      <div className="feesDetailsConatiner">
        <div class="row w-100">
          <div className="col-md-4">
            <div className="amountRemainingClass">
              <div className="font22">Amount Remaining</div>
              <div className="font22 fw600">₹ 1,59,698</div>
            </div>
          </div>
          <div className="col-md-4">
            <div className="totalAmountClass">
              <div className="font22">Total Amount</div>
              <div className="font22 fw600">₹ 1,59,698</div>
            </div>
          </div>
        </div>
        <div class="row  w-100 payment-section-row">
          <div className="col-md-6">
          <div className="payment-history-container">
            <div className="header-payment">
                <div  className="font22 fw600">Payment Expected</div>
                
            </div>
            <table className="payment-history-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                    </tr>
                </thead>
                <tbody>
                    {paymentData.map((payment, index) => (
                        <tr key={index} >
                            <td>{payment.date}</td>
                            <td>{payment.amount}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
          </div>
          <div className="col-md-6">
          <div className="payment-history-container">
            <div className="header-payment">
                <div  className="font22 fw600">Payment History</div>
                <button className="download-button">
                    <img src={Icons.downloadIcon} className="download-icon" alt="d-icon" />
                </button>
            </div>
            <table className="payment-history-table">
                <thead>
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {paymentData.map((payment, index) => (
                        <tr key={index} >
                            <td>{payment.date}</td>
                            <td>{payment.amount}</td>
                            <td>
                            <img src={Icons.downloadIcon} alt="d-icon" className="download-icon"  />
                            
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeesBreakUp;
