import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../../applicationButton/ApplicationButton";
import CommonDropdown from "../../applicationDropdown/ApplicationDropdown";
import IndianStatesAndUTs from "../../../DTO/IndianStateAndUTs";
import ToastBootstrap from "../../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Icons } from "../../../constants/Icons";
import { postTeacherAcademicDetails } from "../../../redux/reduxToolkit/actions/TeacherAction";
import Swal from "sweetalert2";

const categoryData = ["General", "OBC", "EWS", "SC", "ST"];
const bloodGroups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];
const AcademicDetailsTeacherModal = ({ show, handleClose, title }) => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const dispatch = useDispatch();
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const getTeacherDetailsData = useSelector((state) => state.teacher.getTeacherDetailsData);
    const { teacherAcademicDetails, isLoadingTeacherAcademicDetailsStatus, teacherAcademicDetailsError } = useSelector((state) => state?.teacher);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            highestQualification: teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.highestQualification : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.highestQualification : "",
            specialization: teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.specialization : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.specialization : "",
            teachingSubjects: teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.teachingSubjects : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.teachingSubjects : "",
            yearsOfExperience: teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.yearsOfExperience : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.yearsOfExperience : "",
        },
        validationSchema: yup.object({
            highestQualification: yup.string().required("Highest Qualification is required"),
            specialization: yup.string().required("Specialization is required"),
            teachingSubjects: yup.string().required("Teaching Subjects is required"),
            yearsOfExperience: yup.string().required("Years Of Experience is required"),
        }),
        onSubmit: async (values, { resetForm }) => {
            const teacherDto = localStorage.getItem("teacherDto")
            const payload = {
                "teacherDTO": {
                    "id": teacherDto
                },
                "id": teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.id : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.id : null,
                "uuid": teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.uuid : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.uuid : null,
                "createdDate": teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.createdDate : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.createdDate : null,
                "highestQualification": values.highestQualification,
                "specialization": values.specialization,
                "teachingSubjects": values.teachingSubjects,
                "yearsOfExperience": values.yearsOfExperience,
            }
            dispatch(postTeacherAcademicDetails(payload));
            setHasSubmitted(true);
        },
    });
    useEffect(() => {
        if (hasSubmitted) {
            if (teacherAcademicDetails?.httpStatusCode === 200) {
                handleClose();
                formik.resetForm();
                Swal.fire({
                    icon: 'success',
                    title: 'Teacher Academic    Details',
                    text: 'Teacher  Academic Details saved',
                    confirmButtonText: 'OK',
                    timer: 5000,
                    timerProgressBar: true,
                });
            } else if (teacherAcademicDetailsError) {
                setErrorMessage(teacherAcademicDetailsError);
                setShowErrorMessage(true);
            }
        }
    }, [hasSubmitted, teacherAcademicDetails, teacherAcademicDetailsError])
    return (
        <>
            <Modal
                show={show}
                onHide={handleClose}
                className="customModal"
                backdrop="static"
                centered
                size="lg"
            >
                <Modal.Header>
                    <div className="d-flex align-items-center justify-content-between w-100">
                        <p className="mb-0 font16 fw500">{title}</p>
                        <div>
                            <img
                                src={Icons.crossSky}
                                alt="cross"
                                className="crossModal"
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="row">

                            <div className="col-md-6 mt-2">
                                <TextInputWithLabel
                                    label={"Highest Qualification"}
                                    placeHolder={"Enter Your Highest Qualification"}
                                    name="highestQualification"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.highestQualification}
                                    error={
                                        formik.touched.highestQualification && formik.errors.highestQualification
                                            ? formik.errors.highestQualification
                                            : null
                                    }
                                />
                            </div>
                            <div className="col-md-6 mt-2">
                                <TextInputWithLabel
                                    label={"Specialization"}
                                    placeHolder={"Enter Specialization"}
                                    name="specialization"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.specialization}
                                    error={
                                        formik.touched.specialization &&
                                            formik.errors.specialization
                                            ? formik.errors.specialization
                                            : null
                                    }
                                />
                            </div>
                            <div className="col-md-6 mt-2">
                                <TextInputWithLabel
                                    label={"Teaching Subjects"}
                                    placeHolder={"Enter Teaching Subjects"}
                                    name="teachingSubjects"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.teachingSubjects}
                                    error={
                                        formik.touched.teachingSubjects &&
                                            formik.errors.teachingSubjects
                                            ? formik.errors.teachingSubjects
                                            : null
                                    }
                                />
                            </div>
                            <div className="col-md-6 mt-2">
                                <TextInputWithLabel
                                    label={"Years Of Experience"}
                                    placeHolder={"Enter Years Of Experience"}
                                    name="yearsOfExperience"
                                    customInputStyle="greyInput"
                                    customLabelStyle="greyLabel"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.yearsOfExperience}
                                    error={
                                        formik.touched.yearsOfExperience &&
                                            formik.errors.yearsOfExperience
                                            ? formik.errors.yearsOfExperience
                                            : null
                                    }
                                />
                            </div>
                        </div>
                        <div className="row pt30 justify-content-end">
                            <div className="col-md-2">
                                <ApplicationButton
                                    btnText={"Save"}
                                    type="submit"
                                    className="blueheight40"
                                />
                            </div>
                        </div>
                    </form>
                    {errorMessage ? (
                        <ToastBootstrap
                            showErrorMessage={showErrorMessage}
                            setShowErrorMessage={setShowErrorMessage}
                            className={"toastErrorMessage"}
                            message={errorMessage}
                        />
                    ) : null}
                </Modal.Body>
            </Modal>
        </>
    );
};

export default AcademicDetailsTeacherModal;

