import React from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import "../../pages/user/student/Student.css";

const CommonTabs = ({ tabs, defaultKey }) => {
  return (
    <Tabs defaultActiveKey={defaultKey} className="Performace" fill>
      {tabs.map((tab, index) => (
        <Tab eventKey={tab.eventKey} title={tab.title} key={index}>
          {tab.content}
        </Tab>
      ))}
    </Tabs>
  );
};

export default CommonTabs;
