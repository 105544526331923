import { baseURL } from "../baseUrl/BaseUrl";

let apiUrl = `${baseURL}/kustoking`;
let loginUrl = `${baseURL}/kustoking`;
let signUpUrl = `${baseURL}/kustoking/signup`;

// Functions to get URLs
export function getApiUrl() {
  return apiUrl;
}

export function getLoginUrl() {
  return loginUrl;
}

export function getSignUpUrl() {
  return signUpUrl;
}
