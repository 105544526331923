import React, { useEffect, useState } from 'react';
import { Icons } from '../../../constants/Icons';
import Dropdown from 'react-bootstrap/Dropdown';
import FirstLastNameAvatar from '../../../components/avatar/FirstLastNameAvatar';
import ReactPaginate from "react-paginate";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStudentData, getStudentDetails } from '../../../redux/reduxToolkit/actions/StudentAction';
import decodeJWT from '../../../utils/Decode';
import CommonSpinner from '../../../components/spinner/Spinner';

const StudentTable = ({ shouldFetchData, setShouldFetchData }) => {
    const [checked, setChecked] = useState([]);
    const [sortConfig, setSortConfig] = useState({ key: 'firstName', direction: 'ascending' });
    const [dropdownOpen, setDropdownOpen] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const studentsPerPage = 10;
    const [studentsData, setStudentData] = useState([]); // Correct initialization
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');
    const encodedToken = decodeJWT(token);
    const workspaceIdd = encodedToken?.payload?.workspaceId;

    useEffect(() => {
        dispatch(fetchStudentData(workspaceIdd));
    }, [dispatch, workspaceIdd]);

    const { dataStudent, isLoadingdataStudentStatus, dataStudentError, matchStudent, isLoadingmatchStudentStatus, matchStudentError } = useSelector((state) => state?.student);

    const inputSearchStudent = localStorage.getItem("eventResponse");

    useEffect(() => {
        if (inputSearchStudent?.length > 0) {
            setStudentData(Array.isArray(matchStudent) ? matchStudent : []);
        } else {
            setStudentData(Array.isArray(dataStudent) ? dataStudent : []);
        }
    }, [dataStudent, matchStudent, inputSearchStudent]);
    const handleCheckboxChange = (id) => {
        setChecked(prev =>
            prev.includes(id) ? prev.filter(item => item !== id) : [...prev, id]
        );
    };

    const handleDelete = (id) => {
        console.log(`Delete student with id: ${id}`);
    };

    const handleEdit = (id) => {
        console.log(`Edit student with id: ${id}`);
    };


    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const sortedStudents = [...studentsData]?.sort((a, b) => {
        if (sortConfig.key) {
            const valueA = a[sortConfig.key]?.toLowerCase();
            const valueB = b[sortConfig.key]?.toLowerCase();

            if (valueA < valueB) return sortConfig.direction === 'ascending' ? -1 : 1;
            if (valueA > valueB) return sortConfig.direction === 'ascending' ? 1 : -1;
            return 0;
        }
        return 0;
    });

    const toggleDropdown = (id) => {
        setDropdownOpen(dropdownOpen === id ? null : id);
    };

    const handlePageChange = (selectedPage) => {
        setCurrentPage(selectedPage.selected);
    };

    const offset = currentPage * studentsPerPage;
    const currentStudents = sortedStudents?.slice(offset, offset + studentsPerPage);
    const totalPages = Math.ceil(sortedStudents?.length / studentsPerPage);
    
    const getStudentDto = (id) => {
        if (id) {
            localStorage.setItem("studentDto", id);
            navigate("student-details");
        }
    };

    return (
        <>
            {!isLoadingdataStudentStatus && !isLoadingmatchStudentStatus ? (
                <>
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th><input type="checkbox" /></th> */}
                                <th>Student ID</th>
                                <th onClick={() => handleSort('firstName')} style={{ cursor: 'pointer' }}>
                                    Name  {sortConfig.key === 'firstName' && (
                                        sortConfig.direction === 'ascending'
                                            ? <img src={Icons.sortAscending} alt='sorting' className='sortAscending' />
                                            : <img src={Icons.sortDescending} alt='sorting' className='sortAscending' />
                                    )}
                                </th>
                                <th>Email</th>
                                <th>Emergency Contact No</th>
                                <th className='deleteSection'><img src={Icons.delete} alt="delete" className='deleteButton' /></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Array.isArray(studentsData) && studentsData.length > 0 ? (
                                currentStudents.map(student => (
                                    <tr key={student.id}>
                                        {/* <td>
                                            <input
                                                type="checkbox"
                                                checked={checked.includes(student.id)}
                                                onChange={() => handleCheckboxChange(student.id)}
                                            />
                                        </td> */}
                                        <td>{student.id}</td>
                                        <td>
                                            <div className='d-flex align-items-center cursor-pointer' onClick={() => getStudentDto(student.id)}>
                                                <div className='avatarCircle30 font10 fw600'>
                                                    <FirstLastNameAvatar name={`${student.firstName} ${student.lastName}`} />
                                                </div>
                                                <span className='ms-2 text-capitalize'>{student.firstName} {student.lastName}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <a className="decoration-none" href={`mailto:${student.email}`}>
                                                <div className='d-flex align-items-center'>
                                                    <img src={Icons.greenMessage} alt="message" className='messageIcon' />
                                                    <span className='ms-2 text-lowercase'>{student.email}</span>
                                                </div>
                                            </a>
                                        </td>
                                        <td>{student.phone}</td>
                                        <td className='deleteSection cursor-pointer' onClick={() => toggleDropdown(student.id)}>
                                            <Dropdown>
                                                <Dropdown.Toggle variant="undefined" id="dropdown-basic">
                                                    <img
                                                        src={Icons.horizontalDot}
                                                        className='horizontalDot'
                                                        alt="options"
                                                    />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='customDropdownMenu'>
                                                    <Dropdown.Item className='dropdownEdit'>
                                                        <div className="handleEdit" onClick={() => handleEdit(student.id)}>
                                                            <img src={Icons.blueEdit} className='editIcon' alt='edit' />
                                                            <p className='mb-0 font12 fw400 editText'>
                                                                Edit
                                                            </p>
                                                        </div>
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className='dropdownDelete'>
                                                        <div className="handleEdit" onClick={() => handleDelete(student.id)}>
                                                            <img src={Icons.redDelete} className='deleteIcon' alt='delete' />
                                                            <p className='mb-0 font12 fw400 deleteText'>
                                                                Delete
                                                            </p>
                                                        </div>
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr><td colSpan="6">No students found</td></tr>
                            )}
                        </tbody>
                    </table>
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        pageCount={totalPages}
                        onPageChange={handlePageChange}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                    />
                </>
            ) : (
                <CommonSpinner/>
            )}
           
        </>
    );
};

export default StudentTable;
