import React from "react";
import "./EmptyOverview.css";
import { Icons } from "../../constants/Icons";
import AttendanceChart from "../../pages/user/student/AttendenceChart";
import NoAttendanceChart from "./NoAttandenceChart";
const subjects = [
  { name: "English", progress: 80 },
  { name: "Math", progress: 70 },
  { name: "Science", progress: 60 },
];
const EmptyOverView = () => {
  const presentDays = 20;
  const absentDays = 30;
  return (
    <div className="studentOverviewContainer">
      <div className="row w-100">
        <div className="col-md-7">
          <div className="CustomCard">
            <p className="fw600 font18 mb-0">Attandence</p>
            <div className="row w-100">
              <div className="col-md-6">
                <NoAttendanceChart
                  presentPercentage={presentDays}
                  absentPercentage={absentDays}
                />
              </div>
              <div className="col-md-6">
                <div className="attendenceBox">
                  <p className="font18 fw600 persentattendenceNo">
                    0% No Attendance Found
                  </p>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="overView-custom-card">
            <div className="overView-icon-wrapper subject-section-bg">
              <img
                src={Icons.feePaidIcon}
                alt="icon"
                className="overView-icon img-no-data"
              />
            </div>
            <div className="overView-text-wrapper">
              <h4 className="overView-card-number">0</h4>
              <p className="overView-card-description">Subjects</p>
            </div>
          </div>

          <div className="mt-3 overView-custom-card">
            <div className="overView-icon-wrapper fees-section-bg">
              <img
                src={Icons.feePaidIcon}
                alt="icon"
                className="overView-icon img-no-data"
              />
            </div>
            <div className="overView-text-wrapper">
              <h4 className="overView-card-number">No Fees</h4>
              <p className="overView-card-description">No Data Found</p>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="gradeCardContainer">
            <p className="fw600 font18 gradeCardHeader">Grade Card</p>
            <div className="row  justify-content-center">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <img alt="data not found" src={Icons.alertOtagon} className="alrtOtagon-img"/>
                  <p className="mb-0 ms-2">No Data found </p>
                  </div>
                  
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyOverView;
