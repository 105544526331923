import React, { useEffect, useState } from "react";
import decodeJWT from "../../utils/Decode";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { postAddStudent } from "../../redux/reduxToolkit/actions/StudentAction";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import TextInputWithLabel from "../../components/applicationInput/ApplicationInput";
import { Icons } from "../../constants/Icons";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import CommonDropdown from "../../components/applicationDropdown/ApplicationDropdown";
import { saveFeesStructure } from "../../redux/reduxToolkit/actions/FeesAction";
let custokingClassName = [
  "ONE",
  "TWO",
  "THREE",
  "FOUR",
  "FIVE",
  "SIX",
  "SEVEN",
  "EIGHT",
];
let feesCycle = ["MONTHLY", "QUARTERLY", "YEARLY"];
const FessAddModalBody = ({ handleClose, onSuccess }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [feesDetails, setFeesDetails] = useState([]); // Stores the array of students
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const token = localStorage.getItem("token");
  const decodedtoken = decodeJWT(token);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { saveFeesStructureData, isLoadingSaveFeesStructure, saveFeesStructureError } = useSelector(
    (state) => state?.fees
  );

  const addFeesDetailsCall = () => {
    const payload = feesDetails?.map((item) => {
      return {
        studentClass: item.class,
        feeType: item.fees,
        feeAmount: item.feesAmount,
        cycle: item.feesCycle,
        organizationDTO: {
          id: decodedtoken.payload.organizationId,
        },
      };
    });
    dispatch(saveFeesStructure(payload)); // Dispatch the entire array of students
    setHasSubmitted(true);
  };

  const formik = useFormik({
    initialValues: {
      class: "",
      fees: "",
      feesAmount: "",
      feesCycle: "",
    },
    validationSchema: Yup.object({
      class: Yup.string().required("Please Enter Class"),
      fees: Yup.string().required("Please Enter  Fees"),
      feesAmount: Yup.string().required("Please Enter Fees Amount"),
      feesCycle: Yup.string().required("Please Enter Fees Cycle"),
    }),
    onSubmit: (values, { resetForm }) => {
      // Adds the form values to the students array on "Add"
      setFeesDetails((prevData) => [...prevData, values]);
      console.log(values, "values")
      console.log(feesDetails, "feesDetailsss")
      resetForm(); // Resets the form after adding
    },
  });

  const handleDeleteStudent = (index) => {
    setFeesDetails((prevData) => prevData.filter((_, i) => i !== index)); // Deletes a student
  };

  useEffect(() => {
    if (hasSubmitted) {
      if (saveFeesStructureData?.httpStatusCode === 200) {
        handleClose();
        formik.resetForm();
        Swal.fire({
          icon: "success",
          title: "Fess Structure Submitted Successfully",
          text: "Fess Structure Submitted successfully",
          confirmButtonText: "OK",
          timer: 5000,
          timerProgressBar: true,
        });
      } else if (saveFeesStructureError) {
        setErrorMessage(saveFeesStructureError);
        setShowErrorMessage(true);
      }
    }
  }, [hasSubmitted, saveFeesStructureData, saveFeesStructureError]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="borderBottomDashed">
        <div className="row">
          {/* Class Dropdown */}
          <div className="col-md-6 paddingBottom6">
            <CommonDropdown
              label={"Class"}
              placeHolder={"Please enter Class"}
              name="class"
              customLabelStyle="greyLabel"
              title={formik.values.class}
              onSelect={(selectedValue) => {
                formik.setFieldValue("class", selectedValue);
              }}
              items={custokingClassName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.class}
              error={
                formik.touched.class && formik.errors.class
                  ? formik.errors.class
                  : null
              }
            />
          </div>

          {/* Fees Input */}
          <div className="col-md-6 paddingBottom6">
            <TextInputWithLabel
              label="Fees Type"
              placeHolder={"Please enter Fees Type"}
              name="fees"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fees}
              error={
                formik.touched.fees && formik.errors.fees
                  ? formik.errors.fees
                  : null
              }
            />
          </div>

          {/* Fees Amount Input */}
          <div className="col-md-6 paddingBottom6">
            <TextInputWithLabel
              label="Fees Amount"
              name="feesAmount"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.feesAmount}
              error={
                formik.touched.feesAmount && formik.errors.feesAmount
                  ? formik.errors.feesAmount
                  : null
              }
            />
          </div>

          {/* Fees Cycle Dropdown */}
          <div className="col-md-6 paddingBottom6">
            <CommonDropdown
              label={"Fee Cycle"}
              placeHolder={"Please enter Fee Cycle"}
              name="feesCycle"
              customLabelStyle="greyLabel"
              title={formik.values.feesCycle}
              onSelect={(selectedValue) => {
                formik.setFieldValue("feesCycle", selectedValue);
              }}
              items={feesCycle}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.feesCycle}
              error={
                formik.touched.feesCycle && formik.errors.feesCycle
                  ? formik.errors.feesCycle
                  : null
              }
            />
          </div>
        </div>

        {/* <div className="paddingTop20">
          <h5>Added feesDetails:</h5>
          <ul>
            {feesDetails?.map((student, index) => (
              <li key={index}>
                {student.class}, {student.fees}, {student.feesAmount}, {student.feesCycle}
                <button onClick={() => handleDeleteStudent(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div> */}
        {feesDetails?.length > 0 ?
                <div className="studentTableContainer">
                    <table className="studentTable">
                        <thead>
                            <tr>
                                <th>SL. NO</th>
                                <th>Class</th>
                                <th>Fee Type</th>
                                <th>Fees Amount</th>
                                <th>Fees Cycle</th>
                            </tr>
                        </thead>
                        <tbody>
                            {feesDetails?.map((student, index) => (
                                <tr key={index}>
                                    <td>{index+1}</td>
                                    <td>{student.class}</td>
                                    <td>{student.fees}</td>
                                    <td>{student.feesAmount}</td>
                                    <td>{student.feesCycle}</td>
                                    <td>
                                        <div onClick={() => handleDeleteStudent(index)} className='text-center'><img src={Icons.delete} alt='delete' className='deleteButton' /></div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                : null}
      </div>
      <div className="btnGroup">
        {/* Add Button */}
        <div>
          <ApplicationButton
            btnText={"+ Add"}
            className={"blueheight50 whiteBtn40"}
            type="button" // Prevent form submission on Add button click
            onClick={() => formik.handleSubmit()} // Handle the form submission manually
          />
        </div>

        {/* Done Button */}
        <div className="paddingLeft20">
          <ApplicationButton
            btnText={"Done"}
            className={"blueheight50 blueheight40"}
            type="button"
            disabled={feesDetails?.length>0 ? false : true}
            onClick={() => addFeesDetailsCall()}
          />
        </div>
      </div>
    </form>
  );
};

export default FessAddModalBody;
