import React from "react";
import "./Fees.css"
import UserLayout from "../../components/layout/UserLayout";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";
import FeesHeader from "../../components/applicationHeader/feesHeader/FeesHeader";
import FeesBreakUp from "./FeesBreackup";

const FeesDetails = () => {
    return (
        <UserLayout>
            <ApplicationHeader title="Fees" />
            <FeesHeader />
            <FeesBreakUp />
        </UserLayout>
    )
}

export default FeesDetails;