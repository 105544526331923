import React, { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const AttendanceChart = ({ presentPercentage, absentPercentage }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext("2d");

    const attendanceChart = new Chart(ctx, {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [presentPercentage, absentPercentage],
            backgroundColor: ["#FFD66B", "#D11A2A"], // Colors for Present and Absent days
            hoverBackgroundColor: ["#FFD66B", "#D11A2A"],
            borderWidth: 1,
          },
        ],
      },
      options: {
        responsive: true,
        cutout: "75%", // To create the hollow center
        plugins: {
          tooltip: {
            enabled: false, // Disable default tooltip
          },
          legend: {
            display: false, // Hide legend
          },
          title: {
            display: false, // No title required
          },
        },
      },
    });

    return () => {
      attendanceChart.destroy();
    };
  }, [presentPercentage, absentPercentage]);

  return (
    <div className="chartContainer">
      <canvas ref={chartRef} />
      <div className="profitPercentage">
        {presentPercentage}%
      </div>
    </div>
  );
};

export default AttendanceChart;
