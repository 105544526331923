import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const SubjectProgress = ({  initialProgress }) => {
  const [progress, setProgress] = useState(initialProgress);

  const handleProgressChange = (event, newValue) => {
    setProgress(newValue);
  };

  return (
    <Box sx={{ }}>
      <Slider
        value={progress}
        onChange={handleProgressChange}
        aria-labelledby="continuous-slider"
        min={0}
        max={100}
        sx={{
          color: progress > 80 ? 'green' : progress > 50 ? 'orange' : 'red',
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Typography variant="body2">0</Typography>
        <Typography variant="body2">20</Typography>
        <Typography variant="body2">40</Typography>
        <Typography variant="body2">60</Typography>
        <Typography variant="body2">80</Typography>
        <Typography variant="body2">100</Typography>
      </Box>
     
    </Box>
  );
};

export default SubjectProgress;
