import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../constants/Icons";
import { useDispatch, useSelector } from "react-redux";
import decodeJWT from "../../utils/Decode";
import { fetchStudentData } from "../../redux/reduxToolkit/actions/StudentAction";
import FirstLastNameAvatar from "../../components/avatar/FirstLastNameAvatar";
import { Dropdown } from "react-bootstrap";
import { fetchTeachersData } from "../../redux/reduxToolkit/actions/TeacherAction";
import CommonSpinner from "../../components/spinner/Spinner";
import ReactPaginate from "react-paginate";
import TeacherEmptyState from "../../components/emptyState/NoTeacherFound";

const feesData = [
  {
    teacherId: "#876123",
    name: "James Mullican",
    email: "j@gmail.com",
    contactNumber: "72727802316",
  },
  {
    teacherId: "#876123",
    name: "James Mullican",
    email: "j@gmail.com",
    contactNumber: "72727802316",
  },
  {
    teacherId: "#876123",
    name: "James Mullican",
    email: "j@gmail.com",
    contactNumber: "72727802316",
  },
  {
    teacherId: "#876123",
    name: "James Mullican",
    email: "j@gmail.com",
    contactNumber: "72727802316",
  },
  {
    teacherId: "#876123",
    name: "James Mullican",
    email: "j@gmail.com",
    contactNumber: "72727802316",
  },
  {
    teacherId: "#876123",
    name: "James Mullican",
    email: "j@gmail.com",
    contactNumber: "72727802316",
  },
  {
    teacherId: "#876123",
    name: "James Mullican",
    email: "j@gmail.com",
    contactNumber: "72727802316",
  },
  {
    teacherId: "#876123",
    name: "James Mullican",
    email: "j@gmail.com",
    contactNumber: "72727802316",
  },
  {
    teacherId: "#876123",
    name: "James Mullican",
    email: "j@gmail.com",
    contactNumber: "72727802316",
  },
];

const TeacherTable = () => {
  const [checked, setChecked] = useState([]);
  const [sortConfig, setSortConfig] = useState({
    key: "firstName",
    direction: "ascending",
  });
  const [dropdownOpen, setDropdownOpen] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const studentsPerPage = 10;
  const [teacherData, setTeacherData] = useState([]); // Correct initialization
  console.log(teacherData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
  const encodedToken = decodeJWT(token);
  const workspaceIdd = encodedToken?.payload?.workspaceId;

  useEffect(() => {
    dispatch(fetchTeachersData(workspaceIdd));
  }, [dispatch, workspaceIdd]);

  let { teachersData, isLoadingTeachersDataStatus, teachersDataError,matchtTeacherName,isLoadingmatchStudentStatus,getMatchtTeacherNameError } =
    useSelector((state) => state?.teacher);
    const inputSearchStudent = localStorage.getItem("eventResponse");
    useEffect(() => {
        if (inputSearchStudent?.length > 0) {
          setTeacherData(Array.isArray(matchtTeacherName) ? matchtTeacherName : []);
        } else {
          setTeacherData(Array.isArray(teachersData) ? teachersData : []);
        }
    }, [teachersData, matchtTeacherName, inputSearchStudent]);
 
  const handleCheckboxChange = (id) => {
    setChecked((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleDelete = (id) => {
    console.log(`Delete student with id: ${id}`);
  };

  const handleEdit = (id) => {
    console.log(`Edit student with id: ${id}`);
  };

  const handleSort = (key) => {
    let direction = "ascending";
    if (sortConfig.key === key && sortConfig.direction === "ascending") {
      direction = "descending";
    }
    setSortConfig({ key, direction });
  };

  const sortedStudents = [...teacherData]?.sort((a, b) => {
    if (sortConfig.key) {
      const valueA = a[sortConfig.key]?.toLowerCase();
      const valueB = b[sortConfig.key]?.toLowerCase();

      if (valueA < valueB) return sortConfig.direction === "ascending" ? -1 : 1;
      if (valueA > valueB) return sortConfig.direction === "ascending" ? 1 : -1;
      return 0;
    }
    return 0;
  });

  const toggleDropdown = (id) => {
    setDropdownOpen(dropdownOpen === id ? null : id);
  };

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };

  const offset = currentPage * studentsPerPage;
  const currentTeacher = sortedStudents?.slice(
    offset,
    offset + studentsPerPage
  );
  const totalPages = Math.ceil(sortedStudents?.length / studentsPerPage);

  const getTeacherDto = (id) => {
    if (id) {
      localStorage.setItem("teacherDto", id);
      navigate("teacher-details");
    }
  };
  return (
    <>
    {!isLoadingTeachersDataStatus ? (
      <>
        {Array.isArray(teacherData) && teacherData.length > 0 ? (
          <>
            <table className="table">
              <thead>
                <tr>
                  {/* <th>
                    <input type="checkbox" />
                  </th> */}
                  <th>Teacher ID</th>
                  <th
                    onClick={() => handleSort("firstName")}
                    style={{ cursor: "pointer" }}
                  >
                    Name{" "}
                    {sortConfig.key === "firstName" &&
                      (sortConfig.direction === "ascending" ? (
                        <img
                          src={Icons.sortAscending}
                          alt="sorting"
                          className="sortAscending"
                        />
                      ) : (
                        <img
                          src={Icons.sortDescending}
                          alt="sorting"
                          className="sortAscending"
                        />
                      ))}
                  </th>
                  <th>Email</th>
                  <th>Emergency Contact No</th>
                  <th className="deleteSection">
                    <img
                      src={Icons.delete}
                      alt="delete"
                      className="deleteButton"
                    />
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTeacher.map((teacher) => (
                  <tr key={teacher.id}>
                    {/* <td>
                      <input
                        type="checkbox"
                        checked={checked.includes(teacher.teacherId)}
                        onChange={() => handleCheckboxChange(teacher.teacherId)}
                      />
                    </td> */}
                    <td>{teacher.teacherId}</td>
                    <td>
                      <div
                        className="d-flex align-items-center cursor-pointer"
                        onClick={() => getTeacherDto(teacher.id)}
                      >
                        <div className="avatarCircle30 font10 fw600">
                          <FirstLastNameAvatar
                            name={`${teacher.firstName} ${teacher.lastName}`}
                          />
                        </div>
                        <span className="ms-2 text-capitalize">
                          {teacher.firstName} {teacher.lastName}
                        </span>
                      </div>
                    </td>
                    <td>
                      <a
                        className="decoration-none"
                        href={`mailto:${teacher.email}`}
                      >
                        <div className="d-flex align-items-center">
                          <img
                            src={Icons.greenMessage}
                            alt="message"
                            className="messageIcon"
                          />
                          <span className="ms-2 text-lowercase">
                            {teacher.email}
                          </span>
                        </div>
                      </a>
                    </td>
                    <td>{teacher.phone}</td>
                    <td
                      className="deleteSection cursor-pointer"
                      onClick={() => toggleDropdown(teacher.teacherId)}
                    >
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="undefined"
                          id="dropdown-basic"
                        >
                          <img
                            src={Icons.horizontalDot}
                            className="horizontalDot"
                            alt="options"
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="customDropdownMenu">
                          <Dropdown.Item className="dropdownEdit">
                            <div
                              className="handleEdit"
                              onClick={() => handleEdit(teacher.teacherId)}
                            >
                              <img
                                src={Icons.blueEdit}
                                className="editIcon"
                                alt="edit"
                              />
                              <p className="mb-0 font12 fw400 editText">Edit</p>
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdownDelete">
                            <div
                              className="handleEdit"
                              onClick={() => handleDelete(teacher.teacherId)}
                            >
                              <img
                                src={Icons.redDelete}
                                className="deleteIcon"
                                alt="delete"
                              />
                              <p className="mb-0 font12 fw400 deleteText">
                                Delete
                              </p>
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
  
            <ReactPaginate
              previousLabel={"<"}
              nextLabel={">"}
              pageCount={totalPages}
              onPageChange={handlePageChange}
              containerClassName={"pagination"}
              activeClassName={"active"}
            />
          </>
        ) : (
          <TeacherEmptyState />
        )}
      </>
    ) : (
      <CommonSpinner />
    )}
  </>
  
  );
};

export default TeacherTable;
