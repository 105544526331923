import { useMemo, useState } from "react";
import "./AttandenceTable.css";
import FirstLastNameAvatar from "../../components/avatar/FirstLastNameAvatar";
import { useSelector } from "react-redux";
import ReactPaginate from "react-paginate";

const CustomAttendanceTable = ({ dateFromDatePicker }) => {
  const selectedStartDate = new Date(dateFromDatePicker.startDate);
  const selectedEndDate = new Date(dateFromDatePicker.endDate);
  const { attandenceRecords } = useSelector((state) => state?.attandence);
  const [currentPage, setCurrentPage] = useState(0);
  const studentsPerPage = 7;
  const formatDate = (date) => {
    const year = date.getFullYear().toString(); 
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  function getSevenDates(start, end) {
    const dates = [];
    const startDate = new Date(start);
    const endDate = new Date(end);
    const daysBetween = Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24));
    const interval = Math.floor(daysBetween / 6); 
    for (let i = 0; i <= 6; i++) {
      const date = new Date(startDate);
      date.setDate(date.getDate() + i * interval);
      dates.push(date.toISOString().slice(0, 10)); 
    }
    return dates;
  }
  
  const sevenDates = getSevenDates(formatDate(selectedStartDate), formatDate(selectedEndDate));
  console.log(sevenDates)
  // Format date to "YYYY-MM-DD"


  // Generate a list of dates in the range, formatted as "YYYY-MM-DD"
  const dateRange = useMemo(() => {
    const dates = [];
    let currentDate = new Date(selectedStartDate);
    while (currentDate <= selectedEndDate) {
      dates.push(formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return dates;
  }, [selectedStartDate, selectedEndDate]);

  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };


  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Student</th>
            {dateRange.map((date, index) => (
              <th key={index} className="text-center">{date.slice(8, 10)}</th>
            ))}
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {attandenceRecords?.map((student) => (
            <tr key={student.studentDTO.id}>
              <td>
                <div className="d-flex align-items-center attadenceTable">
                  <div className="avatarCircle30 font10 fw600">
                    <FirstLastNameAvatar name={`${student.studentDTO.firstName} ${student.studentDTO.lastName}`} />
                  </div>
                  <span className="ms-2 text-wrap">{student.studentDTO.firstName} {student.studentDTO.lastName}</span>
                </div>
              </td>

              {dateRange.map((date, index) => (
                <td key={index} className="text-center">
                  <div className={`labelAttendence ${
                    student.attendanceStatusMap[date] === "PRESENT" 
                      ? "present" 
                      : student.attendanceStatusMap[date] === "ABSENT" 
                      ? "absent"  
                      : student.attendanceStatusMap[date] === "LATE" 
                      ? "late"
                      : "unmarked"
                  }`}>
                    <span className="checkmark">{student.attendanceStatusMap[date] || "UNMARKED"}</span>
                  </div>
                </td>
              ))}

              <td className="text-center">
                {Object.values(student.attendanceStatusMap).filter(status => status === "PRESENT").length}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

export default CustomAttendanceTable;
