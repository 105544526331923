import React, { Children } from "react";
import SideNavbar from "../SideNavbar/SideNavbar";
import './UserLayout.css';

const UserLayout = ({ children }) => {
    return (
        <div className="userLayoutContainer">
            <SideNavbar />
            <div className="userInsideLayout">
                <div className="container">
                    {children}
                </div>
            </div>
        </div>
    )
}

export default UserLayout;