import { createSlice, createAction } from '@reduxjs/toolkit';
import { fetchGetAttandence, markAttandence } from '../actions/AttandenceAction';

const attandenceSlice = createSlice({
    name: 'attandence',
    initialState: {
        attandenceRecords: null,
        markAttandenceRecords:null,
        isLoadingdataStudentAttandenceStatus: false,
        isLoadingMarkAttandenceRecords:null,
        dataStudentAttandenceError: null,
        markAttandenceRecordsError: null,
    },
    reducers: {
        attandenceState: (state) => {
            state.attandenceRecords = null;
            state.markAttandenceRecords=null;
            state.dataStudentAttandenceError= null;
            state.markAttandenceRecordsError=null
        }
    },
    
    extraReducers: (builder) => {
        builder
            // Handle fetchStudentData
            .addCase(fetchGetAttandence.pending, (state) => {
                state.isLoadingdataStudentAttandenceStatus = true;
                state.dataStudentError = null;
            })
            .addCase(fetchGetAttandence.fulfilled, (state, action) => {
                state.isLoadingdataStudentAttandenceStatus = false;
                state.attandenceRecords = action.payload;
            })
            .addCase(fetchGetAttandence.rejected, (state, action) => {
                state.isLoadingdataStudentAttandenceStatus = false;
                state.dataStudentAttandenceError = action.payload;
            })

            // mark atta
            .addCase(markAttandence.pending, (state) => {
                state.isLoadingMarkAttandenceRecords = true;
                state.markAttandenceRecordsError = null;
            })
            .addCase(markAttandence.fulfilled, (state, action) => {
                state.isLoadingMarkAttandenceRecords = false;
                state.markAttandenceRecords = action.payload;
            })
            .addCase(markAttandence.rejected, (state, action) => {
                state.isLoadingMarkAttandenceRecords = false;
                state.markAttandenceRecordsError = action.payload;
            })

           
        
    },
});
// Export the reducer

export const { attandenceState } = attandenceSlice.actions;
export default attandenceSlice.reducer;
