import React, { useState } from "react";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import { Modal } from "react-bootstrap";
import { Icons } from "../../constants/Icons";
import FessAddModalBody from "./FeesBody";

const AddFeesDetails = ({ onSuccess }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <div className="secondHeader">
        <div className="leftSecondHeader"></div>
        <div className="rightSecondHeader">
          <div className="addStudentButton">
            <ApplicationButton
              btnText="+ Add Fees Details"
              className={"button40"}
              onClick={handleShow}
            />
          </div>
          <div className="exportBtnStudent"></div>
        </div>
        <Modal
          show={show}
          onHide={handleClose}
          className="customModal"
          backdrop="static"
          centered
          size="lg"
        >
          <Modal.Header>
            <div className="d-flex align-items-center justify-content-end w-100">
              <img
                  src={Icons.crossSky}
                  alt="cross"
                  className="crossModal"
                  onClick={handleClose}
                />
              </div>
          </Modal.Header>
          <Modal.Body>
            <FessAddModalBody handleClose={handleClose} onSuccess={onSuccess} />
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default AddFeesDetails;
