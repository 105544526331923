import CryptoJS from "crypto-js";

const generateJWT = (payload, secret) => {
  const header = {
    alg: "HS256",
    typ: "JWT"
  };

  function base64url(source) {
    var encodedSource = CryptoJS.enc.Base64.stringify(source);
    encodedSource = encodedSource.replace(/=+$/, "");
    encodedSource = encodedSource.replace(/\+/g, "-");
    encodedSource = encodedSource.replace(/\//g, "_");
    return encodedSource;
  }

  var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  var encodedHeader = base64url(stringifiedHeader);

  var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload));
  var encodedData = base64url(stringifiedData);

  var signature = encodedHeader + "." + encodedData;
  signature = CryptoJS.HmacSHA256(signature, secret);
  signature = base64url(signature);

  return encodedHeader + "." + encodedData + "." + signature;
};

const enCodeJwt = (payload) => {
  const secret = "My very confidential secret!!!";
  return generateJWT(payload, secret);
};

export default enCodeJwt;
