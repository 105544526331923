import React from "react";



const AttandenceTeacher=()=>{
    return(
        <>
    attandence
        </>
    )
  
    
}

export default AttandenceTeacher;