import { useEffect, useMemo, useState } from "react";
import FirstLastNameAvatar from "../../components/avatar/FirstLastNameAvatar";
import "./AttandenceTable.css";
import decodeJWT from "../../utils/Decode";
import { getApiUrl, getLoginUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import { api } from "../../services/commonApiService/CommonApiServices";
import { useFormik } from "formik";
import ToastBootstrap from "../../components/toast/Toast";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudentData } from "../../redux/reduxToolkit/actions/StudentAction";
import CommonSpinner from "../../components/spinner/Spinner";
import { fetchGetAttandence, markAttandence } from "../../redux/reduxToolkit/actions/AttandenceAction";
import { dataRangeData } from "./CustomAttandence";
import ReactPaginate from "react-paginate";
import Swal from "sweetalert2";
const AttendanceTable = () => {

  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [attendanceData, setAttendanceData] = useState({});
  const [studentsData, setStudentData] = useState([]);
  const dispatch = useDispatch();
  const token = localStorage.getItem('token');
  const encodedToken = decodeJWT(token);
  const workspaceIdd = encodedToken?.payload?.workspaceId;
  const [currentPage, setCurrentPage] = useState(0);
  const studentsPerPage = 7;
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const formik = useFormik({
    initialValues: {
      attendance: {},
    },
    onSubmit: async (values, { resetForm }) => {
      const unmarkedStudents = studentsData.filter(
        (student) => !attendanceData[student.studentDTO.id]
      );
      if (unmarkedStudents.length > 0) {
        setErrorMessage(
          `The following students have not been marked for attendance:\n\n` +
          unmarkedStudents
            .map((student) => `ID: ${student.studentDTO.id}, Name: ${student.studentDTO.firstName} ${student.studentDTO.lastName}`)
            .join("\n") +
          `\n\nPlease mark their attendance before saving.`
        );
        setShowErrorMessage(true);
        return;
      }

      const payloadAttandenceStudent = studentsData.map((student) => ({
        studentDTO: {
          id: student.studentDTO.id,
        },
        attendanceStatus: attendanceData[student.studentDTO.id] || "ABSENT" ||"PRESENT" || "LATE",
      }));
      dispatch(markAttandence(payloadAttandenceStudent));
      setHasSubmitted(true);
    },
  });

  const handleAttendanceChange = (studentId, status) => {
    setAttendanceData({
      ...attendanceData,
      [studentId]: status,
    });
  };
  const todayDate = new Date();
  const formatDate = (date) => {
    const year = date.getFullYear().toString(); 
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  let todayStartDateAndEndDateObject = {
    "startDate": formatDate(todayDate),
    "endDate": formatDate(todayDate)
  }
  useEffect(() => {
    dispatch(fetchStudentData(workspaceIdd));
    dispatch(fetchGetAttandence(todayStartDateAndEndDateObject)) 
  }, [dispatch, workspaceIdd]);

  const { dataStudent, isLoadingdataStudentStatus, dataStudentError, matchStudent, isLoadingmatchStudentStatus, matchStudentError } = useSelector((state) => state?.student);
  const { attandenceRecords, isLoadingdataStudentAttandenceStatus, dataStudentAttandenceError,markAttandenceRecords,isLoadingMarkAttandenceRecords,markAttandenceRecordsError } = useSelector((state) => state?.attandence);
  const inputSearchStudent = localStorage.getItem("eventResponse");

  useEffect(() => {
    if (inputSearchStudent?.length > 0) {
      setStudentData(Array.isArray(matchStudent) ? matchStudent : []);
    } else {
      setStudentData(Array.isArray(attandenceRecords) ? attandenceRecords : []);
    }
  }, [attandenceRecords, matchStudent, inputSearchStudent]);
  useEffect(() => {
    if (hasSubmitted) {
    if (markAttandenceRecords?.httpStatusCode === 200) {
      formik.resetForm();
      Swal.fire({
        icon: 'success',
        title: 'Attandence Marks',
        text: 'Attandence Marks Successfullly',
        confirmButtonText: 'OK',
        timer: 5000,
        timerProgressBar: true,
      });
    } else if (markAttandenceRecordsError) {
       setErrorMessage(markAttandenceRecordsError);
       setShowErrorMessage(true);
    }
  }
  }, [hasSubmitted, markAttandenceRecords, markAttandenceRecordsError]);
  return (
    <>
      {!isLoadingdataStudentAttandenceStatus && !isLoadingdataStudentAttandenceStatus ? (
        <form onSubmit={formik.handleSubmit}>
          {Array.isArray(attandenceRecords) && attandenceRecords.length > 0 ? (
            <>
            <table>
            
              <tbody>
                {attandenceRecords.map((student) => (
                  <tr key={student.studentDTO.id}>
                    <td>
                      <div className="d-flex align-items-center paddingtdLeft10">
                        <div className="avatarCircle30 font10 fw600">
                          <FirstLastNameAvatar
                            name={`${student.studentDTO.firstName} ${student.studentDTO.lastName}`}
                          />
                        </div>
                        <span className="ms-2 text-capitalize">
                          {student.studentDTO.firstName} {student.studentDTO.lastName}
                        </span>
                      </div>
                    </td>
                    <td>
                      <div className="d-flex align-items-center justify-content-center">
                        <span className="ms-2 text-lowercase">{student.studentDTO.email}</span>
                      </div>
                    </td>
                    <td>
                      <div className="radio-group-attandence">
                        <input
                          type="radio"
                          name={`attendance-${student.studentDTO.id}`}
                          onChange={() =>
                            handleAttendanceChange(student.studentDTO.id, "PRESENT")
                          }
                          id={`presentAttendence-${student.studentDTO.id}`}
                          defaultChecked={student.attendanceStatusMap[formatDate(todayDate)] === "PRESENT"}
                        />
                        <label
                          className="radio-container-attandence present"
                          htmlFor={`presentAttendence-${student.studentDTO.id}`}
                        >
                          <span className="checkmark">Present</span>
                        </label>

                        <input
                          type="radio"
                          name={`attendance-${student.studentDTO.id}`}
                          onChange={() =>
                            handleAttendanceChange(student.studentDTO.id, "ABSENT")
                          }
                          id={`absentAttendence-${student.studentDTO.id}`}
                          defaultChecked={student.attendanceStatusMap[formatDate(todayDate)] === "ABSENT"}
                        />
                        <label
                          className="radio-container-attandence absent"
                          htmlFor={`absentAttendence-${student.studentDTO.id}`}
                        >
                          <span className="checkmark">Absent</span>
                        </label>

                        <input
                          type="radio"
                          name={`attendance-${student.studentDTO.id}`}
                          onChange={() =>
                            handleAttendanceChange(student.studentDTO.id, "LATE")
                          }
                          id={`lateAttendence-${student.studentDTO.id}`}
                          defaultChecked={student.attendanceStatusMap[formatDate(todayDate)] === "LATE"}
                        />
                        <label
                          className="radio-container-attandence late"
                          htmlFor={`lateAttendence-${student.studentDTO.id}`}
                        >
                          <span className="checkmark">Late</span>
                        </label>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
        </>
          ) : (
            <p className="text-center mt-2">Data not found</p>
          )}
          {attandenceRecords && attandenceRecords.length > 0 && (
            <div className="col-md-2 ml-auto">
              <ApplicationButton btnText={"Save"} type="submit" />
            </div>
          )}
        </form>
      ) : (
        <CommonSpinner/>
      )}
      {errorMessage && (
        <ToastBootstrap
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          className={"toastErrorMessage"}
          message={errorMessage}
        />
      )}
    </>
  );
};

export default AttendanceTable;
