import React, { useState } from "react";
import ApplicationButton from "../../../../components/applicationButton/ApplicationButton";
import StudentsDocumentsTable from "../../../../components/table/StudentDocumnetTable";
import SubjectDetailsDocumentsModal from "../../../../components/modal/StudentsDetailsDocumentsModal";


const StudentDocuments = () => {
  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  return (
    <div className="studentOverviewContainer">
      <div className="studentSubjectHeader">
        <p className="font18 fw600 mb-0">Document</p>
        <div className="addStudentButton">
          <ApplicationButton
            btnText="+ Upload"
            className={"button40"}
            onClick={handleOpenModal}
          />
        </div>
      </div>
      <SubjectDetailsDocumentsModal
        show={showModal}
        handleClose={handleCloseModal}
        modalTitle="Upload File"
      />
      <StudentsDocumentsTable />
    </div>
  );
};

export default StudentDocuments;
