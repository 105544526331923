// import React, { useEffect, useState } from "react";
// import { ErrorMessage, Field, useFormik } from "formik";
// import * as Yup from "yup";
// import { Modal } from "react-bootstrap";
// import { Icons } from "../../../constants/Icons";
// import TextInputWithLabel from "../../applicationInput/ApplicationInput";
// import ApplicationButton from "../../applicationButton/ApplicationButton";


// const TeacherEmployInfoModal= ({ show, handleClose, title }) => {
//   const formik = useFormik({
//     initialValues: {
//       email: "",
//       subject: "",
//       message: "",
//     },
//     validationSchema: Yup.object({
//       email: Yup.string().required("Email is required"),
//       subject: Yup.string().required("Subject is required"),
//       message: Yup.string().required("Message  is required"),
//     }),
//     onSubmit: (values, { resetForm }) => {
//       // setStudents(prevData => [...prevData, values]);
//       resetForm();
//     },
//   });

//   return (
//     <>
//       <Modal
//         show={show}
//         onHide={handleClose}
//         className="customModal"
//         backdrop="static"
//         centered
//         size="lg"
//       >
//         <Modal.Header>
//           <div className="d-flex align-items-center justify-content-between w-100">
//             <p className="mb-0 font16 fw500">{title}</p>
//             <div>
//               <img
//                 src={Icons.crossSky}
//                 alt="cross"
//                 className="crossModal"
//                 onClick={handleClose}
//               />
//             </div>
//           </div>
//         </Modal.Header>
//         <Modal.Body>
//           <form onSubmit={formik.handleSubmit}>
//             <div className="row w-100 paddingLeft20">
//               <div className="col-md-12 paddingBottom6">
//                 <TextInputWithLabel
//                   label="Email"
//                   name="email"
//                   placeHolder="Notification@custoking.com"
//                   customInputStyle=""
//                   customLabelStyle="fw600"
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.email}
//                   error={
//                     formik.touched.email && formik.errors.email
//                       ? formik.errors.email
//                       : null
//                   }
//                 />
//               </div>
//               <div className="col-md-12 paddingBottom6">
//                 <TextInputWithLabel
//                 type="text"
//                   label="Subject"
//                   name="subject"
//                   customInputStyle=""
//                   customLabelStyle="fw600"
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.subject}
//                   error={
//                     formik.touched.subject && formik.errors.subject
//                       ? formik.errors.subject
//                       : null
//                   }
//                 />
//               </div>
//               <div className="col-md-12 paddingBottom6">
//                 <label
//                   htmlFor="message"
//                   className="label-text-style fw600 "
//                 >
//                   Message
//                 </label>
//                 <textarea
//                   className="form-control"
//                   id="message"
//                   rows="6"
//                   onChange={formik.handleChange}
//                   onBlur={formik.handleBlur}
//                   value={formik.values.message}
//                   placeholder="0/50"
//                 ></textarea>
//                 {formik.touched.message && formik.errors.message ? (
//                   <div className="Inputerror">{formik.errors.message}</div>
//                 ) : null}
//               </div>
//             </div>
//             <div className=" w-100 row paddingTop30 paddingLeft20">
//               <div className="col-md-6">
//                 <ApplicationButton
//                   btnText={"Cancel"}
//                   className={"whiteBtn40"}
//                   onClick={handleClose}
//                 />
//               </div>
//               <div className="col-md-6">
//                 <ApplicationButton
//                   btnText={"Email"}
//                   className={"button40"}
//                   type="submit"
//                 />
//               </div>
//             </div>
//           </form>
//         </Modal.Body>
//       </Modal>
//     </>
//   );
// };

// export default TeacherEmployInfoModal;
import React, { useEffect, useRef, useState } from "react";
import TextInputWithLabel from "../../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../../applicationButton/ApplicationButton";
import CommonDropdown from "../../applicationDropdown/ApplicationDropdown";
import "./../Modal.css";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Icons } from "../../../constants/Icons";
import ToastBootstrap from "../../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { postTeacherEmploymentDetails } from "../../../redux/reduxToolkit/actions/TeacherAction";
import Swal from "sweetalert2";

const employmentTypeData = ["FULL_TIME", "CONTRACTUAL","OTHERS","VISITING","GUEST","PART_TIME"];
const categoryData = ["General", "OBC", "EWS", "SC", "ST"];
const TeacherEmployInfoModal = ({ show, handleClose, title }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const datePickerRef = useRef(null);
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const getTeacherDetailsData = useSelector((state) => state.teacher.getTeacherDetailsData);
  const {teacherEmploymentDetail, teacherEmploymentDetailError} = useSelector((state) => state?.teacher);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      dateofJoining:teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.dateOfJoining : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.dateOfJoining: "",
      employmentType: teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.employmentType : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.employmentType: "",
      department: teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.department : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.department: "",
      designation:teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.designation : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.designation: "",
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      dateofJoining: yup.string().required("Date of joining is required"),
      employmentType: yup.string().required("RTE Student  is required"),
      department: yup.string().required("Department is required"),
      designation: yup.string().required("Sr No  is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const DateInMiliSec=new Date(values.dateofJoining).getTime()
      const teacherDto=localStorage.getItem("teacherDto")
      const payload= {
        "teacherDTO" :{
            "id" : teacherDto
        },
        "id": teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.id : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.id: null ,
        "uuid": teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.uuid : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.uuid: null,
        "createdDate": teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.createdDate : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.createdDate: null,
        "dateOfJoining" : DateInMiliSec,
        "employmentType" : values.employmentType,
        "department" : values.department,
        "designation" : values.designation,
    
    }
    dispatch(postTeacherEmploymentDetails(payload));
    setHasSubmitted(true);
    }
  });
  useEffect(() => {
    if (hasSubmitted) {
    if (teacherEmploymentDetail?.httpStatusCode === 200) {
      handleClose();
      formik.resetForm();
      Swal.fire({
        icon: 'success',
        title: 'Teacher Eployement    Details',
        text: 'Teacher  Eployement Details saved',
        confirmButtonText: 'OK',
        timer: 5000,
        timerProgressBar: true,
      });
    } else if (teacherEmploymentDetailError) {
       setErrorMessage(teacherEmploymentDetailError);
       setShowErrorMessage(true);
    }
  }
  }, [hasSubmitted, teacherEmploymentDetail, teacherEmploymentDetailError]);
  return (
    <div className="admissionDetailsContainer">
      <Modal
        show={show}
        onHide={handleClose}
        className="customModal"
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="mb-0 font16 fw500">{title}</p>
            <div>
              <img
                src={Icons.crossSky}
                alt="cross"
                className="crossModal"
                onClick={handleClose}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <label for="dateofJoining" className="label-text-style fw500 greyLabel">
                  Date Of Joining
                </label>
                <div>
                  <div className="position-relative">
                    <DatePicker
                      ref={datePickerRef}
                      selected={formik.values.dateofJoining}
                      onChange={(date) => formik.setFieldValue('dateofJoining', date)}
                      onBlur={formik.handleBlur}
                      dateFormat="yyyy-MM-dd"
                      className="inline-style fw500 w-100 greyInput"
                      placeholderText="YYYY-MM-DD"
                    />
                    <div className="datePickerIcon" onClick={() => datePickerRef.current.setFocus()}>
                      <img src={Icons.greyCalenderIcon} alt="date" />
                    </div>
                  </div>
                  {formik.touched.dateofJoining && formik.errors.dateofJoining ? (
                    <div className="Inputerror">{formik.errors.dateofJoining}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
            <CommonDropdown
              label={"Employment Type"}
              placeHolder={"Enter Employment Type"}
              name="employmentType"
              customLabelStyle="greyLabel"
              title={formik.values.employmentType}
              onSelect={(selectedValue) => formik.setFieldValue('employmentType', selectedValue)}
              items={employmentTypeData}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.employmentType}
              error={
                formik.touched.employmentType && formik.errors.employmentType
                  ? formik.errors.employmentType
                  : null
              }
            />
          </div>
              <div className="col-md-6">
                <TextInputWithLabel
                  label={"Department"}
                  placeHolder={"Enter Department"}
                  name="department"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.department}
                  error={
                    formik.touched.department && formik.errors.department
                      ? formik.errors.department
                      : null
                  }
                />
              </div>

              <div className="col-md-6 mt-2">
                <TextInputWithLabel
                  label={"Designation"}
                  placeHolder={"Enter Designation"}
                  name="designation"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.designation}
                  error={
                    formik.touched.designation && formik.errors.designation
                      ? formik.errors.designation
                      : null
                  }
                />
              </div>
            </div>
            {errorMessage ? (
              <ToastBootstrap
                showErrorMessage={showErrorMessage}
                setShowErrorMessage={setShowErrorMessage}
                className={"toastErrorMessage"}
                message={errorMessage}
              />
            ) : null}
            <div className="row pt30 justify-content-end">
              <div className="col-md-2">
                <ApplicationButton btnText={"Save"} type="submit" className="blueheight40" />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TeacherEmployInfoModal;

