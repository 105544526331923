import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import ToastBootstrap from "../toast/Toast";
import Swal from "sweetalert2";
import CommonDropdown from "../applicationDropdown/ApplicationDropdown";
import { useDispatch, useSelector } from "react-redux";
import { fetchParentDetailsDetails } from "../../redux/reduxToolkit/actions/StudentAction";
const relationDto = [
  "FATHER",
  "MOTHER",
  "SISTER",
  "BROTHER",
  "UNCLE",
  "AUNT",
  "GRANDFATHER",
  "GRANDMOTHER",
  "LOCAL_GUARDIAN",
];

const ParentsDetailsModal = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const studentsDetails = useSelector((state) => state.student.getStudentDetails);
  const {studentParentDetails, isLoadingstudentParentDetailStatus, studentParentDetailsError} = useSelector((state) => state?.student);
  const formik = useFormik({
    initialValues: {
      motherName: studentParentDetails ? studentParentDetails?.response[1].name : studentsDetails?.parentDTOList ? studentsDetails?.parentDTOList[1].name: "",
      motherPhoneNumber: studentParentDetails ? studentParentDetails?.response[1].mobileNumber : studentsDetails?.parentDTOList ? studentsDetails?.parentDTOList[1].mobileNumber: "",
      fatherName: studentParentDetails ? studentParentDetails?.response[0].name : studentsDetails?.parentDTOList ? studentsDetails?.parentDTOList[0].name: "",
      fatherMobNumber:studentParentDetails ? studentParentDetails?.response[0].mobileNumber : studentsDetails?.parentDTOList ? studentsDetails?.parentDTOList[0].mobileNumber: "",
      motherOccupation: studentParentDetails ? studentParentDetails?.response[1].occupation : studentsDetails?.parentDTOList ? studentsDetails?.parentDTOList[1].occupation: "",
      fatherOccupation:studentParentDetails ? studentParentDetails?.response[0].occupation : studentsDetails?.parentDTOList ? studentsDetails?.parentDTOList[0].occupation: "",
      gauardianName: studentParentDetails ? studentParentDetails?.response[2].name : studentsDetails?.parentDTOList ? studentsDetails?.parentDTOList[2].name: "",
      gauardianMobileNo: studentParentDetails ? studentParentDetails?.response[2].mobileNumber : studentsDetails?.parentDTOList ? studentsDetails?.parentDTOList[2].mobileNumber: "",
      realtionWithGuardian:studentParentDetails ? studentParentDetails?.response[2].relation : studentsDetails?.parentDTOList ? studentsDetails?.parentDTOList[2].relation: ""
    },
    validationSchema: yup.object({
      motherName: yup.string().required("Mother name is required"),
      motherPhoneNumber: yup
        .string()
        .matches(/^[0-9]{10}$/, "Enter a valid phone number")
        .required("Mother Phone Number is required"),
      fatherName: yup.string().required("Father name is required"),
      fatherMobNumber: yup
        .string()
        .matches(/^[0-9]{10}$/, "Enter a valid phone number")
        .required("Father Phone Number is required"),
      motherOccupation: yup
        .string()
        .required("Mother Occupation name is required"),
      fatherOccupation: yup
        .string()
        .required("Father Occupation name is required"),
      gauardianName: yup
        .string()
        .required("Father Occupation name is required"),
      gauardianMobileNo: yup
        .string()
        .matches(/^[0-9]{10}$/, "Enter a valid phone number")
        .required("Guardian Mobile No is required"),
        realtionWithGuardian:yup
        .string("Please enter relation with guardian"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const studentDto = localStorage.getItem("studentDto");
      const payload = [
        {
            "studentDTO": {
                "id":studentDto
            },
            "id": studentParentDetails ? studentParentDetails?.response?.id : studentsDetails?.parentDTOList? studentsDetails?.parentDTOList?.id: null ,
            "uuid": studentParentDetails ? studentParentDetails?.response?.uuid : studentsDetails?.parentDTOList? studentsDetails?.parentDTOList?.uuid: null,
            "createdDate": studentParentDetails ? studentParentDetails?.response?.createdDate : studentsDetails?.parentDTOList? studentsDetails?.parentDTOList?.createdDate: null,
            "name": values.fatherName,
            "mobileNumber": values.fatherMobNumber,
            "whatsappNumber": values.fatherMobNumber,
            "relation": "FATHER",
            "occupation": values.fatherOccupation,
        },
        {
            "studentDTO": {
                "id": studentDto
            },
            "name": values.motherName,
            "mobileNumber": values.motherPhoneNumber,
            "whatsappNumber": values.motherPhoneNumber,
            "relation": "MOTHER",
            "occupation":values.motherOccupation
        },
        {
            "studentDTO": {
                "id": studentDto
            },
            "name":values.gauardianName,
            "mobileNumber": values.gauardianMobileNo,
            "whatsappNumber":  values.gauardianMobileNo,
            "relation": values.realtionWithGuardian,
            "occupation":null
        }
    ]
    dispatch(fetchParentDetailsDetails(payload));
    setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
      if (studentParentDetails.httpStatusCode === 200) {
        props.handleClose();
        formik.resetForm();
        Swal.fire({
          icon: "success",
          title: " Student Parent  Details",
          text: "Student Parent Details saved",
          confirmButtonText: "OK",
          timer: 5000,
          timerProgressBar: true,
        });
      } else if (studentParentDetailsError) {
         setErrorMessage(studentParentDetailsError);
         setShowErrorMessage(true);
      }
    }
  }, [hasSubmitted, studentParentDetails, studentParentDetailsError]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Mother Name"}
              placeHolder={"Enter Mother Name"}
              name="motherName"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.motherName}
              error={
                formik.touched.motherName && formik.errors.motherName
                  ? formik.errors.motherName
                  : null
              }
            />
          </div>
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Mother Mob no"}
              placeHolder={"Enter Mother Mob no"}
              name="motherPhoneNumber"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.motherPhoneNumber}
              error={
                formik.touched.motherPhoneNumber &&
                formik.errors.motherPhoneNumber
                  ? formik.errors.motherPhoneNumber
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Father Name"}
              placeHolder={"Enter Father Name"}
              name="fatherName"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fatherName}
              error={
                formik.touched.fatherName && formik.errors.fatherName
                  ? formik.errors.fatherName
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Father Mob no"}
              placeHolder={"Enter Father Mob no"}
              name="fatherMobNumber"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fatherMobNumber}
              error={
                formik.touched.fatherMobNumber && formik.errors.fatherMobNumber
                  ? formik.errors.fatherMobNumber
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Mother Occupation"}
              placeHolder={"Enter Mother Occupation"}
              name="motherOccupation"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.motherOccupation}
              error={
                formik.touched.motherOccupation &&
                formik.errors.motherOccupation
                  ? formik.errors.motherOccupation
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Father Occupation"}
              placeHolder={"Enter Father Occupation"}
              name="fatherOccupation"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.fatherOccupation}
              error={
                formik.touched.fatherOccupation &&
                formik.errors.fatherOccupation
                  ? formik.errors.fatherOccupation
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Gauardian Name"}
              placeHolder={"Enter Gauardian Name"}
              name="gauardianName"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gauardianName}
              error={
                formik.touched.gauardianName && formik.errors.gauardianName
                  ? formik.errors.gauardianName
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Gauardian Mobile No"}
              placeHolder={"Enter Gauardian Mobile No"}
              name="gauardianMobileNo"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.gauardianMobileNo}
              error={
                formik.touched.gauardianMobileNo &&
                formik.errors.gauardianMobileNo
                  ? formik.errors.gauardianMobileNo
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <CommonDropdown
              label={"Guardian Name"}
              placeHolder={"Enter relation With Student"}
              name="rteStudent"
              customLabelStyle="greyLabel"
              title={formik.values.realtionWithGuardian}
              onSelect={(selectedValue) =>
                formik.setFieldValue("realtionWithGuardian", selectedValue)
              }
              items={relationDto}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.realtionWithGuardian}
              error={
                formik.touched.realtionWithGuardian &&
                formik.errors.realtionWithGuardian
                  ? formik.errors.realtionWithGuardian
                  : null
              }
            />
          </div>
        </div>
        {errorMessage ? (
          <ToastBootstrap
            showErrorMessage={showErrorMessage}
            setShowErrorMessage={setShowErrorMessage}
            className={"toastErrorMessage"}
            message={errorMessage}
          />
        ) : null}
        <div className="row pt30 justify-content-end">
          <div className="col-md-2">
            <ApplicationButton
              btnText={"Save"}
              type="submit"
              className="blueheight40"
            />
          </div>
        </div>
      </form>
    </>
  );
};

export default ParentsDetailsModal;
