import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../../applicationButton/ApplicationButton";
import ToastBootstrap from "../../toast/Toast";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { Icons } from "../../../constants/Icons";
import Swal from "sweetalert2";
import { postFinancialDetailsTeacher } from "../../../redux/reduxToolkit/actions/TeacherAction";

const TeacherFinancialModal = ({ show, handleClose, title }) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const getTeacherDetailsData = useSelector((state) => state.teacher.getTeacherDetailsData);
  const {financialdeatilsDataTeacher, isLoadingFinancialdeatilsDataTeacherStatus, financialdeatilsDataTeacherError} = useSelector((state) => state?.teacher);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bankName: financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.bankName : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.bankName: "",
      bankAccountNo:financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.bankAccountNo : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.bankAccountNo: "",
      ifscCode: financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.ifscCode : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.ifscCode: "",
      panNumber: financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.panNumber : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.panNumber: "",
    },
    validationSchema: yup.object({
      bankName: yup.string().required("Bank Name is required"),
      bankAccountNo: yup.string().required("Account Number is required"),
      ifscCode: yup.string().required("IFSC is required"),
      panNumber: yup.string().required("PAN Number is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      const teacherDto=localStorage.getItem("teacherDto")
      const payload= {
        "teacherDTO" :{
            "id" : teacherDto
        },
        "id": financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.id : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.id: null ,
        "uuid": financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.uuid : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.uuid: null,
        "createdDate": financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.createdDate : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.createdDate: null,
        "bankName" : values.bankName,
        "bankAccountNo" : values.bankAccountNo,
        "ifscCode" : values.ifscCode,
        "panNumber" : values.panNumber,
    
    }
    dispatch(postFinancialDetailsTeacher(payload));
    setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
    if (financialdeatilsDataTeacher?.httpStatusCode === 200) {
      handleClose();
      formik.resetForm();
      Swal.fire({
        icon: 'success',
        title: 'Teacher Financial     Details',
        text: 'Teacher  Financial Details saved',
        confirmButtonText: 'OK',
        timer: 5000,
        timerProgressBar: true,
      });
    } else if (financialdeatilsDataTeacherError) {
       setErrorMessage(financialdeatilsDataTeacherError);
       setShowErrorMessage(true);
    }
  }
  }, [hasSubmitted, financialdeatilsDataTeacher, financialdeatilsDataTeacherError]);
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="customModal"
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="mb-0 font16 fw500">{title}</p>
            <div>
              <img
                src={Icons.crossSky}
                alt="cross"
                className="crossModal"
                onClick={handleClose}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6 mt-2">
                <TextInputWithLabel
                  label={"Bank Name"}
                  placeHolder={"Enter Bank Name"}
                  name="bankName"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bankName}
                  error={
                    formik.touched.bankName && formik.errors.bankName
                      ? formik.errors.bankName
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <TextInputWithLabel
                  label={"Account Number"}
                  placeHolder={"Enter Account Number"}
                  name="bankAccountNo"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bankAccountNo}
                  error={
                    formik.touched.bankAccountNo &&
                      formik.errors.bankAccountNo
                      ? formik.errors.bankAccountNo
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <TextInputWithLabel
                  label={"IFSC"}
                  placeHolder={"Enter IFSC"}
                  name="ifscCode"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.ifscCode}
                  error={
                    formik.touched.ifscCode &&
                      formik.errors.ifscCode
                      ? formik.errors.ifscCode
                      : null
                  }
                />
              </div>
              <div className="col-md-6 mt-2">
                <TextInputWithLabel
                  label={"PAN Number"}
                  placeHolder={"Enter PAN Number"}
                  name="panNumber"
                  customInputStyle="greyInput"
                  customLabelStyle="greyLabel"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.panNumber}
                  error={
                    formik.touched.panNumber &&
                      formik.errors.panNumber
                      ? formik.errors.panNumber
                      : null
                  }
                />
              </div>
            </div>
            <div className="row pt30 justify-content-end">
              <div className="col-md-2">
                <ApplicationButton
                  btnText={"Save"}
                  type="submit"
                  className="blueheight40"
                />
              </div>
            </div>
          </form>
          {errorMessage ? (
            <ToastBootstrap
              showErrorMessage={showErrorMessage}
              setShowErrorMessage={setShowErrorMessage}
              className={"toastErrorMessage"}
              message={errorMessage}
            />
          ) : null}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default TeacherFinancialModal;

