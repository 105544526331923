import React, { useState } from "react";
import ApplicationButton from "../../../components/applicationButton/ApplicationButton";
import "../Teacher.css";
import PersonalDetailsTeacher from "../../../components/modal/teacher/PersonalDetailsTeacherModal";
import TeacherDetailPer from "../../../components/modal/teacher/TeacherPerDetailModal";
import TeacherAddressModal from "../../../components/modal/teacher/TeacherAddressModal";
import TeacherEmployInfoModal from "../../../components/modal/teacher/TeacherEmployInfoModal";
import TeacherDocumentsModal from "../../../components/modal/teacher/TeacherDocumentModal";
import TeacherFinancialModal from "../../../components/modal/teacher/TeacherFinancialModal";
import AcademicDetailsTeacherModal from "../../../components/modal/teacher/AcademicDetailsModal";
import { useDispatch, useSelector } from "react-redux";
import { Icons } from "../../../constants/Icons";
import { deleteTeacherDocuments, fetchTeacherDocuments } from "../../../redux/reduxToolkit/actions/TeacherAction";
import Swal from "sweetalert2";

const OverViewTeacher = () => {
  const teacherUploadDocuments = useSelector((state) => state.teacher.teacherFetchUploadDocumentFileData);
  console.log(teacherUploadDocuments, "OverviewteacherUploadDocuments")

  let { getTeacherDetailsData, teacherPersonalDetailsData, teacherAcademicDetails, techerAddressData, teacherEmploymentDetail, financialdeatilsDataTeacher } = useSelector((state) => state?.teacher);
  console.log(getTeacherDetailsData, "getTeacherDetailsDataOverview")
  console.log(teacherPersonalDetailsData, "postTeacherPersonalDetailsOverview")
  const personalDetails = [
    `Domicile: ${teacherPersonalDetailsData?.response ? teacherPersonalDetailsData?.response?.domicile : getTeacherDetailsData?.teacherPersonalDTO ? getTeacherDetailsData?.teacherPersonalDTO?.domicile : "..."}`,
    `Blood Group: ${teacherPersonalDetailsData?.response ? teacherPersonalDetailsData?.response?.bloodGroup : getTeacherDetailsData?.teacherPersonalDTO ? getTeacherDetailsData?.teacherPersonalDTO?.bloodGroup : "..."}`,
    `Category: ${teacherPersonalDetailsData?.response ? teacherPersonalDetailsData?.response?.category : getTeacherDetailsData?.teacherPersonalDTO ? getTeacherDetailsData?.teacherPersonalDTO?.category : "..."}`,
    `Allergies: ${teacherPersonalDetailsData?.response ? teacherPersonalDetailsData?.response?.allergies : getTeacherDetailsData?.teacherPersonalDTO ? getTeacherDetailsData?.teacherPersonalDTO?.allergies : "..."}`,
  ];
  const academicDetails = [
    `Highest Qualification: ${teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.highestQualification : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.highestQualification : "..."}`,
    `Specialization: ${teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.specialization : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.specialization : "..."}`,
    `Teaching Subjects: ${teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.teachingSubjects : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.teachingSubjects : "..."}`,
    `Years Of Experience: ${teacherAcademicDetails?.response ? teacherAcademicDetails?.response?.yearsOfExperience : getTeacherDetailsData?.teacherAcademicDTO ? getTeacherDetailsData?.teacherAcademicDTO?.yearsOfExperience : "..."}`
  ];

  const addressDetails = [
    `Address Type: ${techerAddressData?.response ? techerAddressData?.response?.addressType : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.addressType : "..."}`,
    `City: ${techerAddressData?.response ? techerAddressData?.response?.city : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.city : "..."}`,
    `State: ${techerAddressData?.response ? techerAddressData?.response?.state : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.state : "..."}`,
    `District: ${techerAddressData?.response ? techerAddressData?.response?.district : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.district : "..."}`,
    `Street: ${techerAddressData?.response ? techerAddressData?.response?.street : getTeacherDetailsData?.teacherAddressDTO ? getTeacherDetailsData?.teacherAddressDTO?.street : "..."}`,
  ];

  const employmentInfo = [
    `Employment Type: ${teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.employmentType : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.employmentType : "..."}`,
    `Date Of Joining: ${teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.dateOfJoining : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.dateOfJoining : "..."}`,
    `Designation: ${teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.designation : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.designation : "..."}`,
    `Department: ${teacherEmploymentDetail?.response ? teacherEmploymentDetail?.response?.department : getTeacherDetailsData?.teacherEmploymentDTO ? getTeacherDetailsData?.teacherEmploymentDTO?.department : "..."}`,
  ];

  const teacherDocuments = teacherUploadDocuments?.map((item) => ({
    id: item?.id,
    text: item?.documentType,
    infoValue: item?.documentUri,
    documentLogicalName: item?.documentLogicalName,
    documentPhysicalName: item?.documentPhysicalName
  }));
  console.log(teacherDocuments, "teacherDocumentsss")
  const teacherFinancial = [
    `Bank Name: ${financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.bankName : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.bankName : "..."}`,
    `Bank Account No: ${financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.bankAccountNo : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.bankAccountNo : "..."}`,
    `IFSC Code: ${financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.ifscCode : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.ifscCode : "..."}`,
    `Pan Number: ${financialdeatilsDataTeacher?.response ? financialdeatilsDataTeacher?.response?.panNumber : getTeacherDetailsData?.teacherFinancialDTO ? getTeacherDetailsData?.teacherFinancialDTO?.panNumber : "..."}`,
  ]
  const [showPersonalDetailsModal, setShowPersonalDetailsModal] = useState(false);
  const [showTeacherDetailsModal, setShowTeacherDetailsModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [showEmploymentInfoModal, setShowEmploymentInfoModal] = useState(false);
  const [showTeacherDocumentsModal, setShowTeacherDocumentsModal] = useState(false);
  const [showFinancialInfoModal, setShowFinancialInfoModal] = useState(false);
  const dispatch = useDispatch();
  const teacherId = localStorage.getItem("teacherDto");
  const handleRemoveRow = async (selectedData, index) => {
    console.log(selectedData, "selectedData")
    const deletePayload = {
      id: selectedData.id,
      documentPhysicalName: selectedData.documentPhysicalName,
      documentLogicalName: selectedData.documentPhysicalName
    };
    
    // Dispatch the delete action and wait for completion
    const response = await dispatch(deleteTeacherDocuments(deletePayload));
    
    // Assuming your deleteTeacherDocuments action returns a payload with an HTTP status code
    if (response?.payload?.httpStatusCode === 200) {
      Swal.fire({
        icon: 'success',
        title: 'Deleted',
        text: `${selectedData.text} deleted successfully`,
        confirmButtonText: 'OK',
        timer: 3000,
        timerProgressBar: true,
      });
      
      // Fetch updated documents
      dispatch(fetchTeacherDocuments(teacherId));
    }
    // Uncomment this to handle potential errors:
    // else if (teacherPersonalDetailsDataError) {
    //    setErrorMessage(teacherPersonalDetailsDataError);
    //    setShowErrorMessage(true);
    // }
};
  return (
    <>
      <div className="row overviewTeacherRow">
        <div className="col-md-4 mb-3">
          <div className="card overview-card">
            <div className="card-body card-body-overview">
              <p className="card-title">Personal Details</p>
              <div className="center-body-content">
                {personalDetails.map((detail, index) => (
                  <p className="card-text mb-0" key={index}>
                    {detail}
                  </p>
                ))}
              </div>
              <ApplicationButton
                onClick={() => setShowPersonalDetailsModal(true)}
                btnText={"View Details"}
                className="blueheight40"
              />
              <PersonalDetailsTeacher
                show={showPersonalDetailsModal}
                handleClose={() => setShowPersonalDetailsModal(false)}
                title="Personal Details"
              />
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-3">
          <div className="card overview-card">
            <div className="card-body card-body-overview">
              <p className="card-title">Academic details</p>
              <div className="center-body-content">
                {academicDetails.map((detail, index) => (
                  <p className="card-text" key={index}>
                    {detail}
                  </p>
                ))}
              </div>
              <ApplicationButton
                btnText={"View Details"}
                className="blueheight40"
                onClick={() => setShowTeacherDetailsModal(true)}
              />
              <AcademicDetailsTeacherModal
                show={showTeacherDetailsModal}
                handleClose={() => setShowTeacherDetailsModal(false)}
                title="Academic Details"
              />
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-3">
          <div className="card overview-card">
            <div className="card-body card-body-overview">
              <p className="card-title">Address</p>
              <div className="center-body-content">
                {addressDetails.map((detail, index) => (
                  <p className="card-text mb-0" key={index}>
                    {detail}
                  </p>
                ))}
              </div>
              <ApplicationButton
                btnText={"View Details"}
                className="blueheight40"
                onClick={() => setShowAddressModal(true)}
              />
              <TeacherAddressModal
                show={showAddressModal}
                handleClose={() => setShowAddressModal(false)}
                title="Address"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-4 mb-3">
          <div className="card overview-card">
            <div className="card-body card-body-overview">
              <p className="card-title">Employment Info</p>
              <div className="center-body-content">
                {employmentInfo.map((info, index) => (
                  <p className="card-text mb-0" key={index}>
                    {info}
                  </p>
                ))}
              </div>
              <ApplicationButton
                btnText={"View Details"}
                className="blueheight40"
                onClick={() => setShowEmploymentInfoModal(true)}
              />
              <TeacherEmployInfoModal
                show={showEmploymentInfoModal}
                handleClose={() => setShowEmploymentInfoModal(false)}
                title="Employment Information"
              />
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-3">
          <div className="card overview-card">
            <div className="card-body card-body-overview">
              <p className="card-title">Teacher Documents</p>
              <div className="center-body-content">
                {teacherDocuments?.map((item, index) => {
                  return (
                    <React.Fragment key={item.id}>
                      <div className="informationBodyli d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                          {item.icon ?
                            <img src={item.icon} alt="icon" />
                            : null}
                          <span className='font12 fw500'>{index + 1} - </span>
                          <p className="mb-0 font12 fw600">{item.text}</p>
                        </div>
                        <div className="d-flex align-items-center">
                          <a className="text-decoration-none downloadUploadDoc" href={item.infoValue}><img src={Icons.downloadIcon} /></a>
                          <div className="text-decoration-none downloadUploadDoc ms-2"><img src={Icons.redDelete} onClick={() => handleRemoveRow(item, index)}/></div>
                        </div>
                      </div>

                    </React.Fragment>
                  );
                })}
              </div>
              <ApplicationButton
                btnText={"View Details"}
                className="blueheight40"
                onClick={() => setShowTeacherDocumentsModal(true)}
              />
              <TeacherDocumentsModal
                show={showTeacherDocumentsModal}
                handleClose={() => setShowTeacherDocumentsModal(false)}
                title="Teacher Documents"
              />
            </div>
          </div>
        </div>

        <div className="col-md-4 mb-3">
          <div className="card overview-card">
            <div className="card-body card-body-overview">
              <p className="card-title">Financial Information</p>
              <div className="center-body-content">
                {teacherFinancial.map((doc, index) => (
                  <p className="card-text mb-0" key={index}>
                    {doc}
                  </p>
                ))}
              </div>
              <ApplicationButton
                btnText={"View Details"}
                className="blueheight40"
                onClick={() => setShowFinancialInfoModal(true)}
              />
              <TeacherFinancialModal
                show={showFinancialInfoModal}
                handleClose={() => setShowFinancialInfoModal(false)}
                title="Financial Information"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OverViewTeacher;
