import { createSlice, createAction } from '@reduxjs/toolkit';
import { fetchAcademicDetailsStudent, fetchAdmissionDetailsStudent, fetchDocuments, fetchExportData, fetchMatchingStudentName, fetchParentDetailsDetails, fetchPersonalDetailsStudent, fetchPreviousEducationDetails, fetchStudentAddressDetails, fetchStudentCount, fetchStudentData, fetchStudentFinancialDetails, getStudentDetails, postAddStudent, updateGeneralInfo, uploadDocumentFile } from '../actions/StudentAction';
export const clearMatchingStudentName = createAction('student/clearMatchingStudentName');

const studentSlice = createSlice({
    name: 'student',
    initialState: {
        dataStudent: null,
        countStudent: null,
        matchStudent: null,
        studentAcademicDetails: null,
        studentAdmissionDetails: null,
        studentPersonalDetails: null,
        studentAddressDetails: null,
        studentFinancilDetails: null,
        studentPrevEducationDetails: null,
        studentParentDetails: null,
        addStudent: null,
        getStudentDetails: null,
        updateGeneralInfoData: null,
        uploadDocumentData: null,
        fetchDocumentData: null,
        fetchExportDatas: null,

        isLoadingdataStudentStatus: false,
        isLoadingcountStudentStatus: false,
        isLoadingmatchStudentStatus: false,
        isLoadingstudentAcademicDetailsStatus: false,
        isLoadingstudentAdmissionDetailsStatus: false,
        isLoadingstudentPersonalDetailsStatus: false,
        isLoadingstudentAdressDetailsStatus: false,
        isLoadingstudentFinancialDetailsStatus: false,
        isLoadingstudentPrevDetailsStatus: false,
        isLoadingstudentParentDetailStatus: false,
        isLoadingAddStudent: false,
        isLoadinggetStudentDetails: false,
        isLoadingupdateGeneralInfo: false,
        isLoadingUploadDocumentData: false,
        isLoadingFetchDocument: false,
        isLoadingFetchExportData: false,


        dataStudentError: null,
        countStudentError: null,
        matchStudentError: null,
        studentAcademicDetailsError: null,
        studentAdmissionDetailsError: null,
        studentPersonalDetailsError: null,
        studentAddressDetailsError: null,
        studentFinancilDetailsError: null,
        studentPrevEducationDetailsError: null,
        studentParentDetailsError: null,
        addStudentError: null,
        getStudentDetailsError: null,
        updateGeneralInfoError: null,
        uploadDocumentDataError: null,
        fetchDocumentError: null,
        fetchFetchExportDataError: null,
    },
    reducers: {
        studentState: (state) => {
            state.dataStudent = null;
            state.countStudent = null;
            state.matchStudent = null;
            state.studentAcademicDetails = null;
            state.studentAdmissionDetails = null;
            state.studentPersonalDetails = null;
            state.studentAddressDetails = null;
            state.studentFinancilDetails = null;
            state.studentPrevEducationDetails = null;
            state.studentParentDetails = null;
            state.addStudent = null;
            state.getStudentDetails = null;
            state.updateGeneralInfoData = null;
            state.uploadDocumentData = null;
            state.fetchDocumentData = null;
            state.fetchExportData = null;

            state.dataStudentError = null;
            state.countStudentError = null;
            state.matchStudentError = null;
            state.studentAcademicDetailsError = null;
            state.studentAdmissionDetailsError = null;
            state.studentPersonalDetailsError = null;
            state.studentAddressDetailsError = null;
            state.studentFinancilDetailsError = null;
            state.studentPrevEducationDetailsError = null;
            state.studentParentDetailsError = null;
            state.addStudentError = null;
            state.getStudentDetailsError = null;
            state.updateGeneralInfoError = null;
            state.uploadDocumentDataError = null;
            state.fetchDocumentError = null;
            state.fetchFetchExportDataError = null;
        }
    },

    extraReducers: (builder) => {
        builder
            // Handle fetchStudentData
            .addCase(fetchStudentData.pending, (state) => {
                state.isLoadingdataStudentStatus = true;
                state.dataStudentError = null;
            })
            .addCase(fetchStudentData.fulfilled, (state, action) => {
                state.isLoadingdataStudentStatus = false;
                state.dataStudent = action.payload;
            })
            .addCase(fetchStudentData.rejected, (state, action) => {
                state.isLoadingdataStudentStatus = false;
                state.dataStudentError = action.payload;
            })

            // Handle fetchStudentCount
            .addCase(fetchStudentCount.pending, (state) => {
                state.isLoadingcountStudentStatus = true;
                state.countStudentError = null;
            })
            .addCase(fetchStudentCount.fulfilled, (state, action) => {
                state.isLoadingcountStudentStatus = false;
                state.countStudent = action.payload;
            })
            .addCase(fetchStudentCount.rejected, (state, action) => {
                state.isLoadingcountStudentStatus = false;
                state.countStudentError = action.payload;
            })



            .addCase(fetchMatchingStudentName.pending, (state) => {
                state.isLoadingmatchStudentStatus = true;
                state.matchStudentError = null;
            })
            .addCase(fetchMatchingStudentName.fulfilled, (state, action) => {
                state.isLoadingmatchStudentStatus = false;
                state.matchStudent = action.payload;
            })
            .addCase(fetchMatchingStudentName.rejected, (state, action) => {
                state.isLoadingmatchStudentStatus = false;
                state.matchStudentError = action.payload;
            })
            
            .addCase(clearMatchingStudentName, (state) => {
                state.matchStudent = [];
                state.isLoadingmatchStudentStatus = false;
            })


            .addCase(fetchAcademicDetailsStudent.pending, (state) => {
                state.isLoadingstudentAcademicDetailsStatus = true;
                state.studentAcademicDetailsError = null;
            })
            .addCase(fetchAcademicDetailsStudent.fulfilled, (state, action) => {
                state.isLoadingstudentAcademicDetailsStatus = false;
                state.studentAcademicDetails = action.payload;
            })
            .addCase(fetchAcademicDetailsStudent.rejected, (state, action) => {
                state.isLoadingstudentAcademicDetailsStatus = false;
                state.studentAcademicDetailsError = action.payload;
            })


            .addCase(fetchAdmissionDetailsStudent.pending, (state) => {
                state.isLoadingstudentAdmissionDetailsStatus = true;
                state.studentAdmissionDetailsError = null;
            })
            .addCase(fetchAdmissionDetailsStudent.fulfilled, (state, action) => {
                state.isLoadingstudentAdmissionDetailsStatus = false;
                state.studentAdmissionDetails = action.payload;
            })
            .addCase(fetchAdmissionDetailsStudent.rejected, (state, action) => {
                state.isLoadingstudentAdmissionDetailsStatus = false;
                state.studentAdmissionDetailsError = action.payload;
            })



            .addCase(fetchPersonalDetailsStudent.pending, (state) => {
                state.isLoadingstudentPersonalDetailsStatus = true;
                state.studentPersonalDetailsError = null;
            })
            .addCase(fetchPersonalDetailsStudent.fulfilled, (state, action) => {
                state.isLoadingstudentPersonalDetailsStatus = false;
                state.studentPersonalDetails = action.payload;
            })
            .addCase(fetchPersonalDetailsStudent.rejected, (state, action) => {
                state.isLoadingstudentPersonalDetailsStatus = false;
                state.studentPersonalDetailsError = action.payload;
            })



            .addCase(fetchStudentFinancialDetails.pending, (state) => {
                state.isLoadingstudentFinancialDetailsStatus = true;
                state.studentFinancilDetailsError = null;
            })
            .addCase(fetchStudentFinancialDetails.fulfilled, (state, action) => {
                state.isLoadingstudentFinancialDetailsStatus = false;
                state.studentFinancilDetails = action.payload;
            })
            .addCase(fetchStudentFinancialDetails.rejected, (state, action) => {
                state.isLoadingstudentFinancialDetailsStatus = false;
                state.studentFinancilDetailsError = action.payload;
            })


            .addCase(fetchPreviousEducationDetails.pending, (state) => {
                state.isLoadingstudentPrevDetailsStatus = true;
                state.studentPrevEducationDetailsError = null;
            })
            .addCase(fetchPreviousEducationDetails.fulfilled, (state, action) => {
                state.isLoadingstudentPrevDetailsStatus = false;
                state.studentPrevEducationDetails = action.payload;
            })
            .addCase(fetchPreviousEducationDetails.rejected, (state, action) => {
                state.isLoadingstudentPrevDetailsStatus = false;
                state.studentPrevEducationDetailsError = action.payload;
            })

            .addCase(fetchParentDetailsDetails.pending, (state) => {
                state.isLoadingstudentParentDetailStatus = true;
                state.studentParentDetailsError = null;
            })
            .addCase(fetchParentDetailsDetails.fulfilled, (state, action) => {
                state.isLoadingstudentParentDetailStatus = false;
                state.studentParentDetails = action.payload;
            })
            .addCase(fetchParentDetailsDetails.rejected, (state, action) => {
                state.isLoadingstudentParentDetailStatus = false;
                state.studentParentDetailsError = action.payload;
            })


            .addCase(fetchStudentAddressDetails.pending, (state) => {
                state.isLoadingstudentAdressDetailsStatus = true;
                state.studentAddressDetailsError = null;
            })
            .addCase(fetchStudentAddressDetails.fulfilled, (state, action) => {
                state.isLoadingstudentParentDetailStatus = false;
                state.studentAddressDetails = action.payload;
            })
            .addCase(fetchStudentAddressDetails.rejected, (state, action) => {
                state.isLoadingstudentAdressDetailsStatus = false;
                state.studentAddressDetailsError = action.payload;
            })

            .addCase(postAddStudent.pending, (state) => {
                state.isLoadingAddStudent = true;
                state.addStudentError = null;
            })
            .addCase(postAddStudent.fulfilled, (state, action) => {
                state.isLoadingAddStudent = false;
                state.addStudent = action.payload;
            })
            .addCase(postAddStudent.rejected, (state, action) => {
                state.isLoadingAddStudent = false;
                state.addStudentError = action.payload;
            })

            .addCase(getStudentDetails.pending, (state) => {
                state.isLoadinggetStudentDetails = true;
                state.getStudentDetailsError = null;
            })
            .addCase(getStudentDetails.fulfilled, (state, action) => {
                state.isLoadinggetStudentDetails = false;
                state.getStudentDetails = action.payload;
            })
            .addCase(getStudentDetails.rejected, (state, action) => {
                state.isLoadinggetStudentDetails = false;
                state.getStudentDetailsError = action.payload;
            })

            .addCase(updateGeneralInfo.pending, (state) => {
                state.isLoadingupdateGeneralInfo = true;
                state.updateGeneralInfoData = null;
            })
            .addCase(updateGeneralInfo.fulfilled, (state, action) => {
                state.isLoadingupdateGeneralInfo = false;
                state.updateGeneralInfoData = action.payload;
            })
            .addCase(updateGeneralInfo.rejected, (state, action) => {
                state.isLoadingupdateGeneralInfo = false;
                state.updateGeneralInfoError = action.payload;
            })

            .addCase(uploadDocumentFile.pending, (state) => {
                state.isLoadingUploadDocumentData = true;
                state.uploadDocumentData = null;
            })
            .addCase(uploadDocumentFile.fulfilled, (state, action) => {
                state.isLoadingUploadDocumentData = false;
                state.uploadDocumentData = action.payload;
            })
            .addCase(uploadDocumentFile.rejected, (state, action) => {
                state.isLoadingUploadDocumentData = false;
                state.uploadDocumentDataError = action.payload;
            })

            .addCase(fetchDocuments.pending, (state) => {
                state.isLoadingFetchDocument = true;
                state.fetchDocumentData = null;
            })
            .addCase(fetchDocuments.fulfilled, (state, action) => {
                state.isLoadingFetchDocument = false;
                state.fetchDocumentData = action.payload;
            })
            .addCase(fetchDocuments.rejected, (state, action) => {
                state.isLoadingFetchDocument = false;
                state.fetchDocumentError = action.payload;
            })


            .addCase(fetchExportData.pending, (state) => {
                state.isLoadingFetchExportData = true;
                state.fetchExportDatas = null;
            })
            .addCase(fetchExportData.fulfilled, (state, action) => {
                state.isLoadingFetchExportData = false;
                state.fetchExportDatas = action.payload;
            })
            .addCase(fetchExportData.rejected, (state, action) => {
                state.isLoadingFetchExportData = false;
                state.fetchFetchExportDataError = action.payload;
            })
    },
});
// Export the reducer

export const { studentState } = studentSlice.actions;
export default studentSlice.reducer;
