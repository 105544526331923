import React, { useEffect, useState } from "react";
import "./StudentSideNavbar.css";
import ProfileProgress from "./ProfileProgess";
import { Images } from "../../constants/Images";
import { Icons } from "../../constants/Icons";
import InformationSection from "./StudentsSideNavbarInformation";
import PersonalDetailsModal from "../modal/PersonalDetailsModal";
import AcademicDetailsModal from "../modal/AcademicDetailsModal";
import AdmissionDetailsModal from "../modal/AdmissionDetailsModal";
import ParentsDetailsModal from "../modal/ParentDetailsModal";
import FinancialDetailsModal from "../modal/FinancialDetailsModal";
import PreviousEducationModal from "../modal/PreviousEducationModal";
import DocumentsModal from "../modal/DocumentsModal";
import StudentAddressDetailsModal from "../modal/StudentAddressDetails";
import GeneralInfoModal from "../modal/GeneralInfo";
import { formatDate } from "../../utils/DateFormate";
import { useDispatch, useSelector } from "react-redux";
import { setPersonalDetailsId } from "../../redux/reduxToolkit/slices/storeWithoutApiCallSlice";
import { fetchDocuments } from "../../redux/reduxToolkit/actions/StudentAction";
import InformationDocumentSection from "./StudentSideNavabarDocument";


const StudentSideNavbar = ({ studentsDetails, studentsDocuments }) => {
  const [progress, setProgress] = useState(0);
  const { studentPersonalDetails, updateGeneralInfoData } = useSelector((state) => state?.student);
  const dispatch = useDispatch();
  // useEffect(()=>{
  //   dispatch(setPersonalDetailsId(studentPersonalDetails ? studentPersonalDetails?.response?.id : studentsDetails?.studentPersonalDTO?.id))
  // },[studentsDetails?.studentPersonalDTO, studentPersonalDetails])
 

  const GeneralInfoBody = [
    { id: 1, icon: null, text: `Student Id`, infoValue: updateGeneralInfoData ? updateGeneralInfoData?.id : studentsDetails?.studentDTO?.id },
    { id: 2, icon: null, text: `Email`, infoValue: updateGeneralInfoData ? updateGeneralInfoData?.email : studentsDetails?.studentDTO?.email },
    { id: 3, icon: null, text: `Phone`, infoValue: updateGeneralInfoData ? updateGeneralInfoData?.phone : studentsDetails?.studentDTO?.phone },
  ]
  const PersonalDetailsBody = [
    { id: 1, icon: null, text: `Domicile`, infoValue: studentPersonalDetails ? studentPersonalDetails?.response?.domicile : studentsDetails?.studentPersonalDTO?.domicile },
    { id: 2, icon: null, text: `Allergies`, infoValue: studentPersonalDetails ? studentPersonalDetails?.response?.allergies : studentsDetails?.studentPersonalDTO?.allergies },
    { id: 3, icon: null, text: `Blood Group`, infoValue: studentPersonalDetails ? studentPersonalDetails?.response?.bloodGroup : studentsDetails?.studentPersonalDTO?.bloodGroup },
    { id: 4, icon: null, text: `Category`, infoValue: studentPersonalDetails ? studentPersonalDetails?.response?.category : studentsDetails?.studentPersonalDTO?.category },
    { id: 5, icon: null, text: `Food Habits`, infoValue: studentPersonalDetails ? studentPersonalDetails?.response?.foodHabits : studentsDetails?.studentPersonalDTO?.foodHabits },
    { id: 6, icon: null, text: `Medical Condition`, infoValue: studentPersonalDetails ? studentPersonalDetails?.response?.medicalCondition : studentsDetails?.studentPersonalDTO?.medicalCondition }
  ]
  const AcademicDetailsBody = [
    { id: 1, icon: null, text: `First Language`, infoValue: studentsDetails?.studentAcademicDTO?.firstLanguage },
    { id: 2, icon: null, text: `Languages Preferred`, infoValue: studentsDetails?.studentAcademicDTO?.languagesPreferred },
    { id: 3, icon: null, text: `RollNo`, infoValue: studentsDetails?.studentAcademicDTO?.rollNo },
    { id: 4, icon: null, text: `Second Language`, infoValue: studentsDetails?.studentAcademicDTO?.secondLanguage },
    { id: 5, icon: null, text: `Section`, infoValue: studentsDetails?.studentAcademicDTO?.section },
    { id: 6, icon: null, text: `Stream`, infoValue: studentsDetails?.studentAcademicDTO?.stream },
    { id: 7, icon: null, text: `Student Class`, infoValue: studentsDetails?.studentAcademicDTO?.studentClass },
  ]
  const AdmissionDetailsBody = [
    { id: 1, icon: null, text: `Admission No`, infoValue: studentsDetails?.studentAdmissionDTO?.admissionNo },
    { id: 2, icon: null, text: `Date Of Admission`, infoValue: formatDate(studentsDetails?.studentAdmissionDTO?.dateOfAdmission) },
    { id: 3, icon: null, text: `Fee Category`, infoValue: studentsDetails?.studentAdmissionDTO?.feeCategory },
    { id: 4, icon: null, text: `RTE Category`, infoValue: studentsDetails?.studentAdmissionDTO?.rteCategory },
    { id: 5, icon: null, text: `RTE Student`, infoValue: studentsDetails?.studentAdmissionDTO?.rteStudent },
    { id: 6, icon: null, text: `Sr No`, infoValue: studentsDetails?.studentAdmissionDTO?.srNo },
    { id: 7, icon: null, text: `Unique Id`, infoValue: studentsDetails?.studentAdmissionDTO?.uniqueId },
  ]
  const ParentsDetailsBody = [
    { id: 1, icon: null, text: `Mother Name`, infoValue: studentsDetails?.parentDTOList[1]?.name },
    { id: 2, icon: null, text: `Mother Mob`, infoValue: studentsDetails?.parentDTOList[1]?.whatsappNumber },
    { id: 3, icon: null, text: `Father Name`, infoValue: studentsDetails?.parentDTOList[0]?.name },
    { id: 4, icon: null, text: `Father Mob`, infoValue: studentsDetails?.parentDTOList[0]?.whatsappNumber },
    { id: 5, icon: null, text: `Mother Occupation`, infoValue: studentsDetails?.parentDTOList[1]?.occupation },
    { id: 6, icon: null, text: `Father Occupation`, infoValue: studentsDetails?.parentDTOList[0]?.occupation },
    { id: 7, icon: null, text: `Guardian Name`, infoValue: studentsDetails?.parentDTOList[2]?.name },
    { id: 8, icon: null, text: `Guardian Mob`, infoValue: studentsDetails?.parentDTOList[2]?.whatsappNumber },
    { id: 9, icon: null, text: `Relationship With Guardian`, infoValue: studentsDetails?.parentDTOList[2]?.relation },
  ]
  const FinancialDetailsBody = [
    { id: 1, icon: null, text: `Bank Account No`, infoValue: studentsDetails?.studentFinancialDTO?.bankAccountNo },
    { id: 2, icon: null, text: `Bank Name`, infoValue: studentsDetails?.studentAdmissionDTO?.bankName },
    { id: 3, icon: null, text: `Fine Amount`, infoValue: studentsDetails?.studentAdmissionDTO?.fineAmount },
    { id: 4, icon: null, text: `IFSC Code`, infoValue: studentsDetails?.studentAdmissionDTO?.ifscCode },
    { id: 5, icon: null, text: `Security Deposit`, infoValue: studentsDetails?.studentAdmissionDTO?.securityDeposit },
  ]
  const StudentAddressBody = [
    { id: 1, icon: null, text: `Address Type`, infoValue: studentsDetails?.studentAddressDTO?.addressType },
    { id: 2, icon: null, text: `City`, infoValue: studentsDetails?.studentAddressDTO?.city },
    { id: 3, icon: null, text: `District`, infoValue: studentsDetails?.studentAddressDTO?.district },
    { id: 4, icon: null, text: `Landmark`, infoValue: studentsDetails?.studentAddressDTO?.landmark },
    { id: 5, icon: null, text: `Pin Code`, infoValue: studentsDetails?.studentAddressDTO?.pinCode },
    { id: 6, icon: null, text: `State`, infoValue: studentsDetails?.studentAddressDTO?.state },
    { id: 7, icon: null, text: `Street`, infoValue: studentsDetails?.studentAddressDTO?.street },
  ]
  const PreviousEducationBody = [
    { id: 1, icon: null, text: `Last School Address`, infoValue: studentsDetails?.studentPrevEduDTO?.lastSchoolAddress },
    { id: 2, icon: null, text: `Last School Board`, infoValue: studentsDetails?.studentPrevEduDTO?.lastSchoolBoard },
    { id: 3, icon: null, text: `Last School Name`, infoValue: studentsDetails?.studentPrevEduDTO?.lastSchoolName },
  ]
  const DocumentsBody = studentsDocuments?.map((item) => ({
    id: item?.id,
    text: item?.documentType,
    infoValue: item?.documentUri
  }));
  console.log(studentsDocuments, "studentsDocumentssNavbar")
  const handleSave = (section, formData) => {
    const newProgress = Math.min(progress + 20, 100);
    setProgress(newProgress);
  };
  const [isOn, setIsOn] = useState(false);
  const handleToggle = () => {
    setIsOn(!isOn);
  };
  const [showPersonalDetails, setShowPersonalDetails] = useState(false);
  const handlePersonalDetailsClose = () => setShowPersonalDetails(false);
  const handlePersonalDetailsShow = () => setShowPersonalDetails(true);
  const [showAcademicsDetails, setShowAcademicsDetails] = useState(false);
  const handleAcademicsDetailsClose = () => setShowAcademicsDetails(false);
  const handleAcademicsDetailsShow = () => setShowAcademicsDetails(true);
  const [showAdmissionDetails, setShowAdmissionDetails] = useState(false);
  const handleAdmissionDetailsClose = () => setShowAdmissionDetails(false);
  const handleAdmissionDetailsShow = () => setShowAdmissionDetails(true);
  const [showParentsDetails, setShowParentsDetails] = useState(false);
  const handleParentsDetailsClose = () => setShowParentsDetails(false);
  const handleParentsDetailsShow = () => setShowParentsDetails(true);
  const [showFinancialDetails, setShowFinancialDetails] = useState(false);
  const handleFinancialDetailsClose = () => setShowFinancialDetails(false);
  const handleFinancialDetailsShow = () => setShowFinancialDetails(true);
  const [showStudentAddress, setShowStudentAddress] = useState(false);
  const handleStudentAddressClose = () => setShowStudentAddress(false);
  const handleStudentAddressShow = () => setShowStudentAddress(true);
  const [showPreviousEducation, setShowPreviousEducation] = useState(false);
  const handlePreviousEducationClose = () => setShowPreviousEducation(false);
  const handlePreviousEducationShow = () => setShowPreviousEducation(true);
  const [showDocuments, setShowDocuments] = useState(false);
  const handleDocumentsClose = () => setShowDocuments(false);
  const handleDocumentsShow = () => setShowDocuments(true);
  const [showGeneralInfo, setShowGeneralInfo] = useState(false);
  const handleGeneralInfoClose = () => setShowGeneralInfo(false);
  const handleGeneralInfoShow = () => setShowGeneralInfo(true);


  return (
    <div className="studentSideNavbar">
      <ProfileProgress id={studentsDetails?.studentDTO?.id} name={`${studentsDetails?.studentDTO?.firstName} ${studentsDetails?.studentDTO?.lastName}`} progress={studentsDetails?.informationCompletionPercentage} imageUrl={Images.userImg} class={studentsDetails?.studentAcademicDTO?.studentClass} section={studentsDetails?.studentAcademicDTO?.section} />
      <div className="informationSection">
        <InformationSection
          modalBodyComponent={GeneralInfoModal}
          modalTitle="General Info"
          show={showGeneralInfo}
          handleShow={handleGeneralInfoShow}
          handleClose={handleGeneralInfoClose}
          title={"General Info"}
          activeSwitch={true}
          informationBody={GeneralInfoBody}
        />
        <InformationSection
          modalBodyComponent={PersonalDetailsModal}
          modalTitle="Personal Details"
          show={showPersonalDetails}
          handleShow={handlePersonalDetailsShow}
          handleClose={handlePersonalDetailsClose}
          handleToggle={handleToggle}
          title={"Personal Details"}
          informationBody={PersonalDetailsBody}
        />
        <InformationSection
          modalBodyComponent={AcademicDetailsModal}
          modalTitle="Academic Details"
          show={showAcademicsDetails}
          handleShow={handleAcademicsDetailsShow}
          handleClose={handleAcademicsDetailsClose}
          title={"Academic Details"}
          informationBody={AcademicDetailsBody}
        />
        <InformationSection
          modalBodyComponent={AdmissionDetailsModal}
          modalTitle="Admission Details"
          show={showAdmissionDetails}
          handleShow={handleAdmissionDetailsShow}
          handleClose={handleAdmissionDetailsClose}
          title={"Admission Details"}
          informationBody={AdmissionDetailsBody}
        />
        <InformationSection
          modalBodyComponent={ParentsDetailsModal}
          modalTitle="Parents Details"
          show={showParentsDetails}
          handleShow={handleParentsDetailsShow}
          handleClose={handleParentsDetailsClose}
          title={"Parents Details"}
          informationBody={ParentsDetailsBody}
        />
        <InformationSection
          modalBodyComponent={FinancialDetailsModal}
          modalTitle="Financial Details"
          show={showFinancialDetails}
          handleShow={handleFinancialDetailsShow}
          handleClose={handleFinancialDetailsClose}
          title={"Financial Details"}
          informationBody={FinancialDetailsBody}
        />
        <InformationSection
          modalBodyComponent={StudentAddressDetailsModal}
          modalTitle="Student Address"
          show={showStudentAddress}
          handleShow={handleStudentAddressShow}
          handleClose={handleStudentAddressClose}
          title={"Student Address"}
          informationBody={StudentAddressBody}
        />
        <InformationSection
          modalBodyComponent={PreviousEducationModal}
          modalTitle="Previous Education"
          show={showPreviousEducation}
          handleShow={handlePreviousEducationShow}
          handleClose={handlePreviousEducationClose}
          title={"Previous Education"}
          informationBody={PreviousEducationBody}
        />
        <InformationDocumentSection
          modalBodyComponent={DocumentsModal}
          modalTitle="Documents"
          show={showDocuments}
          handleShow={handleDocumentsShow}
          handleClose={handleDocumentsClose}
          title={"Documents"}
          informationBody={DocumentsBody}
        />

      </div>
    </div>
  );
};

export default StudentSideNavbar;
