import { createSlice, createAction } from '@reduxjs/toolkit';
import { getAllClasses, postAddClass, postAddSection } from '../actions/ClassesAction';


const classesSlice = createSlice({
    name: 'classes',
    initialState: {
        addClassDetails: null,
        addSectionDetails: null,
        getAllClassesData:null,

        isLoadingAddClassDetails: false,
        isLoadingAddSectionDetails: false,
        isLoadingGetAllClassesData: false,

        addClassDetailsError: null,
        addSectionDetailsError: null,
        getAllClassesDataError: null,
    },
    reducers: {
        classesState: (state) => {
            state.addClassDetails = null;
            state.addSectionDetails = null;
            state.getAllClassesData = null;

            state.addClassDetailsError = null;
            state.addSectionDetailsError = null;
            state.getAllClassesDataError = null;

        }
    },

    extraReducers: (builder) => {
        builder
            .addCase(postAddClass.pending, (state) => {
                state.isLoadingAddClassDetails = true;
                state.addClassDetailsError = null;
            })
            .addCase(postAddClass.fulfilled, (state, action) => {
                state.isLoadingAddClassDetails = false;
                state.addClassDetails = action.payload;
            })
            .addCase(postAddClass.rejected, (state, action) => {
                state.isLoadingAddClassDetails = false;
                state.addClassDetailsError = action.payload;
            })


            //add section
            .addCase(postAddSection.pending, (state) => {
                state.isLoadingAddSectionDetails = true;
                state.addSectionDetailsError = null;
            })
            .addCase(postAddSection.fulfilled, (state, action) => {
                state.isLoadingAddSectionDetails = false;
                state.addSectionDetails = action.payload;
            })
            .addCase(postAddSection.rejected, (state, action) => {
                state.isLoadingAddSectionDetails = false;
                state.addSectionDetailsError = action.payload;
            })


            .addCase(getAllClasses.pending, (state) => {
                state.isLoadingGetAllClassesData = true;
                state.getAllClassesDataError = null;
            })
            .addCase(getAllClasses.fulfilled, (state, action) => {
                state.isLoadingGetAllClassesData = false;
                state.getAllClassesData = action.payload;
            })
            .addCase(getAllClasses.rejected, (state, action) => {
                state.isLoadingGetAllClassesData = false;
                state.getAllClassesDataError = action.payload;
            })


    },
});
// Export the reducer

export const { classesState } = classesSlice.actions;
export default classesSlice.reducer;