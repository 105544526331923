export const Icons = {
    custokingLogo: require("../assets/icons/custokingLogo.png"),
    attendence: require("../assets/icons/attendence.png"),
    classes: require("../assets/icons/classes.png"),
    communication: require("../assets/icons/communication.png"),
    home: require("../assets/icons/home.png"),
    reports: require("../assets/icons/reports.png"),
    settings: require("../assets/icons/settings.png"),
    students: require("../assets/icons/students.png"),
    teacher: require("../assets/icons/teacher.png"),
    searchIcon: require("../assets/icons/searchIcon.png"),
    logout: require("../assets/icons/logout.png"),
    tag: require("../assets/icons/tag.png"),
    delete: require("../assets/icons/delete.png"),
    horizontalDot: require("../assets/icons/horizontalDot.png"),
    redDelete: require("../assets/icons/redDelete.png"),
    blueEdit: require("../assets/icons/blueEdit.png"),
    greenMessage: require("../assets/icons/greenMessage.png"),
    sortAscending: require("../assets/icons/sortAscending.png"),
    sortDescending: require("../assets/icons/sortDescending.png"),
    eyeClose: require("../assets/icons/eyeClose.png"),
    eyeOpen: require("../assets/icons/eyeOpen.png"),
    blueCalenderIcon: require("../assets/icons/calender-blue-icon.png"),
    greyCalenderIcon: require("../assets/icons/calender-icon-grey.png"),
    crossSky: require('../assets/icons/crossSky.png'),
    imageCamera: require('../assets/icons/imageCamera.png'),
    addStudent: require('../assets/icons/addStudent.png'),
    editBlack: require("../assets/icons/editBlack.png"),
    email: require("../assets/icons/email.png"),
    externalLink: require('../assets/icons/external-link.png'),
    gameIcon: require('../assets/icons/game-icon.png'),
    feePaidIcon: require('../assets/icons/paid-fee-icon.png'),
    actionsIcon: require('../assets/icons/actionsIcon.png'),
    alertOtagon: require('../assets/icons/alert-octagon.png'),
    downloadIcon: require('../assets/icons/downloadIcon.png'),
    feesIcon: require('../assets/icons/fees.png'),
    calendarAcademic: require('../assets/icons/calendarAcademic.png'),
    downArrow: require('../assets/icons/chevron-down.png'),
    export: require('../assets/icons/export.png'),
    bellRinging: require('../assets/icons/bellRinging.png'),
    arrowDownCircle: require('../assets/icons/arrow-down-circle.png'),
    arrowDownCircleGrey: require('../assets/icons/arrow-down-circle-grey.png'),
    yellowTi: require('../assets/icons/yellowTi.png'),
};