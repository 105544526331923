import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import CommonDropdown from "../applicationDropdown/ApplicationDropdown";
import IndianStatesAndUTs from "../../DTO/IndianStateAndUTs";
import ToastBootstrap from "../toast/Toast";
import Swal from "sweetalert2";
import { api } from "../../services/commonApiService/CommonApiServices";
import { getApiUrl } from "../../services/urlConfig/UrlConfig";
import { apiUrls } from "../../services/endPoints/ApiUrls";
import { useDispatch, useSelector } from "react-redux";
import { fetchPersonalDetailsStudent } from "../../redux/reduxToolkit/actions/StudentAction";

const categoryData = ["General", "OBC", "EWS", "SC", "ST"];
const bloodGroups = ['A+', 'A-', 'B+', 'B-', 'O+', 'O-', 'AB+', 'AB-'];
const PersonalDetailsModal = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const studentsDetails = useSelector((state) => state.student.getStudentDetails);
  const {studentPersonalDetails, isLoadingstudentPersonalDetailsStatus, studentPersonalDetailsError} = useSelector((state) => state?.student);

  const formik = useFormik({
    initialValues: {
      domicile: studentPersonalDetails ? studentPersonalDetails?.response?.domicile : studentsDetails?.studentPersonalDTO? studentsDetails?.studentPersonalDTO?.domicile: "",
      category: studentPersonalDetails ? studentPersonalDetails?.response?.category : studentsDetails?.studentPersonalDTO? studentsDetails?.studentPersonalDTO?.category: "",
      bloodGroup: studentPersonalDetails ? studentPersonalDetails?.response?.bloodGroup : studentsDetails?.studentPersonalDTO? studentsDetails?.studentPersonalDTO?.bloodGroup: "",
      allergies: studentPersonalDetails ? studentPersonalDetails?.response?.allergies : studentsDetails?.studentPersonalDTO? studentsDetails?.studentPersonalDTO?.allergies: "",
      foodHabits: studentPersonalDetails ? studentPersonalDetails?.response?.foodHabits : studentsDetails?.studentPersonalDTO? studentsDetails?.studentPersonalDTO?.foodHabits: "",
      medicalConditions: studentPersonalDetails ? studentPersonalDetails?.response?.medicalConditions : studentsDetails?.studentPersonalDTO? studentsDetails?.studentPersonalDTO?.medicalConditions: "",
    },
    validationSchema: yup.object({
      domicile: yup.string().required("Domicile is required"),
      category: yup.string().required("Category is required"),
      bloodGroup: yup.string().required("Blood Group is required"),
      allergies: yup.string().required("Allergies is required"),
      foodHabits: yup.string().required("Food Habits is required"),
      medicalConditions: yup
        .string()
        .required("Medical Conditions is required"),
    }),
    onSubmit: async (values, { resetForm }) => {

      const studentDto = localStorage.getItem("studentDto");
      const payload = {
        studentDTO: {
          id: studentDto,
        },
        id: studentPersonalDetails ? studentPersonalDetails?.response?.id : studentsDetails?.studentPersonalDTO? studentsDetails?.studentPersonalDTO?.id: null ,
        uuid: studentPersonalDetails ? studentPersonalDetails?.response?.uuid : studentsDetails?.studentPersonalDTO? studentsDetails?.studentPersonalDTO?.uuid: null,
        createdDate: studentPersonalDetails ? studentPersonalDetails?.response?.createdDate : studentsDetails?.studentPersonalDTO? studentsDetails?.studentPersonalDTO?.createdDate: null,
        bloodGroup: values.bloodGroup,
        category: values.category,
        domicile: values.domicile,
        medicalConditions: values.medicalConditions,
        foodHabits: values.foodHabits,
        allergies: values.allergies,
      };
      dispatch(fetchPersonalDetailsStudent(payload));
      setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
    if (studentPersonalDetails?.httpStatusCode === 200) {
      props.handleClose();
      formik.resetForm();
      Swal.fire({
        icon: 'success',
        title: 'Personal Details',
        text: 'Student Personal Details saved',
        confirmButtonText: 'OK',
        timer: 5000,
        timerProgressBar: true,
      });
    } else if (studentPersonalDetailsError) {
       setErrorMessage(studentPersonalDetailsError);
       setShowErrorMessage(true);
    }
  }
  }, [hasSubmitted, studentPersonalDetails, studentPersonalDetailsError]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6 mt-2">
            <CommonDropdown
              label={"Domicile"}
              placeHolder={"Enter Domicile"}
              name="domicile"
              customLabelStyle="greyLabel"
              items={IndianStatesAndUTs}
              title={formik.values.domicile}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onSelect={(selectedValue) =>
                formik.setFieldValue("domicile", selectedValue)
              }
              value={formik.values.domicile}
              error={
                formik.touched.domicile && formik.errors.domicile
                  ? formik.errors.domicile
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <CommonDropdown
              label={"Category"}
              placeHolder={"Enter Category"}
              name="category"
              customLabelStyle="greyLabel"
              items={categoryData}
              title={formik.values.category}
              onSelect={(selectedValue) =>
                formik.setFieldValue("category", selectedValue)
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.category}
              error={
                formik.touched.category && formik.errors.category
                  ? formik.errors.category
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
           <CommonDropdown
              label={"Blood Group"}
              placeHolder={"Enter Blood Group"}
              name="bloodGroup"
              customLabelStyle="greyLabel"
              items={bloodGroups}
              title={formik.values.bloodGroup}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              onSelect={(selectedValue) =>
                formik.setFieldValue("bloodGroup", selectedValue)
              }
              value={formik.values.bloodGroup}
              error={
                formik.touched.bloodGroup && formik.errors.bloodGroup
                  ? formik.errors.bloodGroup
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Allergies"}
              placeHolder={"Enter Your Allergies"}
              name="allergies"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.allergies}
              error={
                formik.touched.allergies && formik.errors.allergies
                  ? formik.errors.allergies
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Food Habits"}
              placeHolder={"Enter Food Habits"}
              name="foodHabits"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.foodHabits}
              error={
                formik.touched.foodHabits && formik.errors.foodHabits
                  ? formik.errors.foodHabits
                  : null
              }
            />
          </div>
          <div className="col-md-6 mt-2">
            <TextInputWithLabel
              label={"Medical Conditions"}
              placeHolder={"Enter Medical Conditions"}
              name="medicalConditions"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.medicalConditions}
              error={
                formik.touched.medicalConditions &&
                formik.errors.medicalConditions
                  ? formik.errors.medicalConditions
                  : null
              }
            />
          </div>
        </div>
        <div className="row pt30 justify-content-end">
          <div className="col-md-2">
            <ApplicationButton
              btnText={"Save"}
              type="submit"
              className="blueheight40"
            />
          </div>
        </div>
      </form>
      {errorMessage ? (
        <ToastBootstrap
          showErrorMessage={showErrorMessage}
          setShowErrorMessage={setShowErrorMessage}
          className={"toastErrorMessage"}
          message={errorMessage}
        />
      ) : null}
    </>
  );
};

export default PersonalDetailsModal;
