import React from "react";



const DocumentTeacher=()=>{
    return(
        <>
        documnet</>
    )
  
    
}

export default DocumentTeacher;