
import React, { useEffect, useState } from "react";
import TextInputWithLabel from "../applicationInput/ApplicationInput";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import ToastBootstrap from "../toast/Toast";
import { updateGeneralInfo } from "../../redux/reduxToolkit/actions/StudentAction";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
const GeneralInfoModal = (props) => {
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const dispatch = useDispatch();
  const studentsDetails = useSelector((state) => state.student.getStudentDetails);
  const {updateGeneralInfoData, isLoadingupdateGeneralInfo, updateGeneralInfoError} = useSelector((state) => state?.student);
  const formik = useFormik({
    initialValues: {
      email: updateGeneralInfoData ? updateGeneralInfoData?.email : studentsDetails?.studentDTO? studentsDetails?.studentDTO?.email: "",
      mobileNumber: updateGeneralInfoData ? updateGeneralInfoData?.phone : studentsDetails?.studentDTO? studentsDetails?.studentDTO?.phone: "",
      studentId: updateGeneralInfoData ? updateGeneralInfoData?.id : studentsDetails?.studentDTO? studentsDetails?.studentDTO?.id: "",
    },
    validationSchema: yup.object({
        email: yup
        .string()
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address')
        .required("Please enter your email address"),
        mobileNumber: yup
        .string()
        .matches(/^[0-9]{10}$/, "Enter a valid phone number")
        .required("Mother PhoneNumber is required"),
        studentId: yup
        .string()
        .required("Mother PhoneNumber is required"),
      
    }),
    onSubmit: async (values, { resetForm }) => {
    const studentDto=localStorage.getItem("studentDto")
    const payload = {
     id: studentDto,
      email: values.email,
      mobileNumber: values.mobileNumber,
      studentId: values.studentId,
    };
    dispatch(updateGeneralInfo(payload));
    setHasSubmitted(true);
    },
  });
  useEffect(() => {
    if (hasSubmitted) {
    if (updateGeneralInfoData) {
      props.handleClose();
      formik.resetForm();
      Swal.fire({
        icon: 'success',
        title: 'Personal Details',
        text: 'Student Personal Details saved',
        confirmButtonText: 'OK',
        timer: 5000,
        timerProgressBar: true,
      });
    } else if (updateGeneralInfoError) {
       setErrorMessage(updateGeneralInfoError);
       setShowErrorMessage(true);
    }
  }
  }, [hasSubmitted, updateGeneralInfoData, updateGeneralInfoError]);
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Email"}
              placeHolder={"Enter Email"}
              name="email"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              error={
                formik.touched.email && formik.errors.email
                  ? formik.errors.email
                  : null
              }
            />
          </div>
     
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Mobile Number"}
              placeHolder={"Enter Mobile Number"}
              name="mobileNumber"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.mobileNumber}
              error={
                formik.touched.mobileNumber && formik.errors.mobileNumber
                  ? formik.errors.mobileNumber
                  : null
              }
            />
          </div>
          <div className="col-md-6">
            <TextInputWithLabel
              label={"Student Id"}
              placeHolder={"Enter Student Id"}
              name="studentId"
              customInputStyle="greyInput"
              customLabelStyle="greyLabel"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.studentId}
              error={
                formik.touched.studentId && formik.errors.studentId
                  ? formik.errors.studentId
                  : null
              }
            />
          </div>
        </div>
      {errorMessage ?
            <ToastBootstrap showErrorMessage={showErrorMessage} setShowErrorMessage={setShowErrorMessage} className={"toastErrorMessage"} message={errorMessage} /> : null
     }
        <div className="row pt30 justify-content-end">
          <div className="col-md-2">
            <ApplicationButton btnText={"Save"} type="submit" className="blueheight40" />
          </div>
        </div>
      </form>
    </>
  );
};

export default GeneralInfoModal;
