import { createSlice } from "@reduxjs/toolkit";
import { deleteTeacherDocuments, fetchExportDataTeacher, fetchMatchingTeacherName, fetchTeacherCount, fetchTeacherDocuments, fetchTeachersData, getTeacherDetails, postCreateTeacher, postFinancialDetailsTeacher, postTeacherAcademicDetails, postTeacherAddress, postTeacherEmploymentDetails, postTeacherPersonalDetails, teacherUploadDocumentFile } from "../actions/TeacherAction";


const teacherSlice = createSlice({
    name: 'teacher',
    initialState: {
        //data store
        addTeacher: null,
        teachersData: null,
        financialdeatilsDataTeacher: null,
        techerAddressData: null,
        teacherPersonalDetailsData: null,
        teacherEmploymentDetail: null,
        teacherAcademicDetails: null,
        exportDataTeacher: null,
        getTeacherDetailsData: null,
        teacherCountData: null,
        matchtTeacherName: null,
        teacherUploadDocumentFileData: null,
        teacherFetchUploadDocumentFileData: null,
        teacherDeleteUploadDocumentFileData: null,

        //loading data
        isLoadingdataAddTeacherStatus: false,
        isLoadingTeachersDataStatus: false,
        isLoadingFinancialdeatilsDataTeacherStatus: false,
        isLoadingtecherAddressDataStatus: false,
        isLoadingteacherPersonalDetailsDataStatus: false,
        isLoadingTeacherEmploymentDetailStatus: false,
        isLoadingTeacherAcademicDetailsStatus: false,
        isLoadingExportDataTeacherStatus: false,
        isLoadingGetTeacherDetailsStatus: false,
        isLoadingTeacherCountDataStatus: false,
        isLoadingMatchtTeacherNameStatus: false,
        isLoadingTeacherUploadDocumentFile: false,
        isLoadingFetchTeacherUploadDocumentFile: false,
        isLoadingDeleteTeacherUploadDocumentFile: false,

        //error comes
        addTeacherError: null,
        teachersDataError: null,
        financialdeatilsDataTeacherError: null,
        techerAddressDataError: null,
        teacherPersonalDetailsDataError: null,
        teacherEmploymentDetailError: null,
        teacherAcademicDetailsError: null,
        exportDataTeacherError: null,
        getTeacherDetailsError: null,
        getTeacherCountDataError: null,
        getMatchtTeacherNameError: null,
        teacherUploadDocumentFileError: null,
        teacherFetchUploadDocumentFileError: null,
        teacherDeleteUploadDocumentFileError: null,
    },
    reducers: {
        teacherState: (state) => {
            state.addTeacher = null;
            state.teachersData = null;
            state.financialdeatilsDataTeacher = null;
            state.techerAddressData = null;
            state.teacherPersonalDetailsData = null;
            state.teacherAcademicDetails = null;
            state.exportDataTeacher = null;
            state.getTeacherDetailsData = null;
            state.teacherCountData = null;
            state.matchtTeacherName = null;
            state.teacherUploadDocumentFileData = null;
            state.teacherFetchUploadDocumentFileData = null;
            state.teacherDeleteUploadDocumentFileData = null;

            state.addTeacherError = null;
            state.teachersDataError = null
            state.financialdeatilsDataTeacherError = null;
            state.techerAddressDataError = null;
            state.teacherPersonalDetailsDataError = null;
            state.teacherAcademicDetailsError = null;
            state.exportDataTeacherError = null;
            state.getTeacherDetailsError = null;
            state.getTeacherCountDataError = null;
            state.getMatchtTeacherNameError = null;
            state.teacherUploadDocumentFileError = null;
            state.teacherFetchUploadDocumentFileError = null;
            state.teacherDeleteUploadDocumentFileError = null;
        }
    },

    extraReducers: (builder) => {
        builder
            // Handle fetchTeacherData
            .addCase(postCreateTeacher.pending, (state) => {
                state.isLoadingdataAddTeacherStatus = true;
                state.addTeacher = null;
            })
            .addCase(postCreateTeacher.fulfilled, (state, action) => {
                state.isLoadingdataAddTeacherStatus = false;
                state.addTeacher = action.payload;
            })
            .addCase(postCreateTeacher.rejected, (state, action) => {
                state.isLoadingdataAddTeacherStatus = false;
                state.addTeacherError = action.payload;
            })
            //financial details
            .addCase(postFinancialDetailsTeacher.pending, (state) => {
                state.isLoadingFinancialdeatilsDataTeacherStatus = true;
                state.financialdeatilsDataTeacher = null;
            })
            .addCase(postFinancialDetailsTeacher.fulfilled, (state, action) => {
                state.isLoadingFinancialdeatilsDataTeacherStatus = false;
                state.financialdeatilsDataTeacher = action.payload;
            })
            .addCase(postFinancialDetailsTeacher.rejected, (state, action) => {
                state.isLoadingFinancialdeatilsDataTeacherStatus = false;
                state.financialdeatilsDataTeacherError = action.payload;
            })


            //techerADDRESS
            .addCase(postTeacherAddress.pending, (state) => {
                state.isLoadingtecherAddressDataStatus = true;
                state.techerAddressData = null;
            })
            .addCase(postTeacherAddress.fulfilled, (state, action) => {
                state.isLoadingtecherAddressDataStatus = false;
                state.techerAddressData = action.payload;
            })
            .addCase(postTeacherAddress.rejected, (state, action) => {
                state.isLoadingtecherAddressDataStatus = false;
                state.techerAddressDataError = action.payload;
            })

            //teacher personal details
            .addCase(postTeacherPersonalDetails.pending, (state) => {
                state.isLoadingteacherPersonalDetailsDataStatus = true;
                state.teacherPersonalDetailsData = null;
            })
            .addCase(postTeacherPersonalDetails.fulfilled, (state, action) => {
                state.isLoadingteacherPersonalDetailsDataStatus = false;
                state.teacherPersonalDetailsData = action.payload;
            })
            .addCase(postTeacherPersonalDetails.rejected, (state, action) => {
                state.isLoadingteacherPersonalDetailsDataStatus = false;
                state.teacherPersonalDetailsDataError = action.payload;
            })

            //teacher employement details
            .addCase(postTeacherEmploymentDetails.pending, (state) => {
                state.isLoadingTeacherEmploymentDetailStatus = true;
                state.teacherEmploymentDetail = null;
            })
            .addCase(postTeacherEmploymentDetails.fulfilled, (state, action) => {
                state.isLoadingTeacherEmploymentDetailStatus = false;
                state.teacherEmploymentDetail = action.payload;
            })
            .addCase(postTeacherEmploymentDetails.rejected, (state, action) => {
                state.isLoadingTeacherEmploymentDetailStatus = false;
                state.teacherEmploymentDetailError = action.payload;
            })


            //teacher academic details
            .addCase(postTeacherAcademicDetails.pending, (state) => {
                state.isLoadingTeacherAcademicDetailsStatus = true;
                state.teacherAcademicDetails = null;
            })
            .addCase(postTeacherAcademicDetails.fulfilled, (state, action) => {
                state.isLoadingTeacherAcademicDetailsStatus = false;
                state.teacherAcademicDetails = action.payload;
            })
            .addCase(postTeacherAcademicDetails.rejected, (state, action) => {
                state.isLoadingTeacherAcademicDetailsStatus = false;
                state.teacherAcademicDetailsError = action.payload;
            })


            //get teacher data
            .addCase(fetchTeachersData.pending, (state) => {
                state.isLoadingTeachersDataStatus = true;
                state.teachersDataError = null;
            })
            .addCase(fetchTeachersData.fulfilled, (state, action) => {
                state.isLoadingTeachersDataStatus = false;
                state.teachersData = action.payload;
            })
            .addCase(fetchTeachersData.rejected, (state, action) => {
                state.isLoadingTeachersDataStatus = false;
                state.teachersDataError = action.payload;
            })

            .addCase(fetchExportDataTeacher.pending, (state) => {
                state.isLoadingExportDataTeacherStatus = true;
                state.exportDataTeacher = null;
            })
            .addCase(fetchExportDataTeacher.fulfilled, (state, action) => {
                state.isLoadingExportDataTeacherStatus = false;
                state.exportDataTeacher = action.payload;
            })
            .addCase(fetchExportDataTeacher.rejected, (state, action) => {
                state.isLoadingExportDataTeacherStatus = false;
                state.exportDataTeacherError = action.payload;
            })

            .addCase(getTeacherDetails.pending, (state) => {
                state.isLoadingGetTeacherDetailsStatus = true;
                state.getTeacherDetailsError = null;
            })
            .addCase(getTeacherDetails.fulfilled, (state, action) => {
                state.isLoadingGetTeacherDetailsStatus = false;
                state.getTeacherDetailsData = action.payload;
            })
            .addCase(getTeacherDetails.rejected, (state, action) => {
                state.isLoadingGetTeacherDetailsStatus = false;
                state.getTeacherDetailsError = action.payload;
            })


            .addCase(fetchTeacherCount.pending, (state) => {
                state.isLoadingTeacherCountDataStatus = true;
                state.getTeacherCountDataError = null;
            })
            .addCase(fetchTeacherCount.fulfilled, (state, action) => {
                state.isLoadingTeacherCountDataStatus = false;
                state.teacherCountData = action.payload;
            })
            .addCase(fetchTeacherCount.rejected, (state, action) => {
                state.isLoadingTeacherCountDataStatus = false;
                state.getTeacherCountDataError = action.payload;
            })

            .addCase(fetchMatchingTeacherName.pending, (state) => {
                state.isLoadingMatchtTeacherNameStatus = true;
                state.getMatchtTeacherNameError = null;
            })
            .addCase(fetchMatchingTeacherName.fulfilled, (state, action) => {
                state.isLoadingmatchStudentStatus = false;
                state.matchtTeacherName = action.payload;
            })
            .addCase(fetchMatchingTeacherName.rejected, (state, action) => {
                state.isLoadingMatchtTeacherNameStatus = false;
                state.getMatchtTeacherNameError = action.payload;
            })
            .addCase(teacherUploadDocumentFile.pending, (state) => {
                state.isLoadingTeacherUploadDocumentFile = true;
                state.teacherUploadDocumentFileError = null;
            })
            .addCase(teacherUploadDocumentFile.fulfilled, (state, action) => {
                state.isLoadingTeacherUploadDocumentFile = false;
                state.teacherUploadDocumentFileData = action.payload;
            })
            .addCase(teacherUploadDocumentFile.rejected, (state, action) => {
                state.isLoadingTeacherUploadDocumentFile = false;
                state.teacherUploadDocumentFileError = action.payload;
            })
            .addCase(fetchTeacherDocuments.pending, (state) => {
                state.isLoadingFetchTeacherUploadDocumentFile = true;
                state.teacherFetchUploadDocumentFileError = null;
            })
            .addCase(fetchTeacherDocuments.fulfilled, (state, action) => {
                state.isLoadingFetchTeacherUploadDocumentFile = false;
                state.teacherFetchUploadDocumentFileData = action.payload;
            })
            .addCase(fetchTeacherDocuments.rejected, (state, action) => {
                state.isLoadingFetchTeacherUploadDocumentFile = false;
                state.teacherFetchUploadDocumentFileError = action.payload;
            })


            .addCase(deleteTeacherDocuments.pending, (state) => {
                state.isLoadingDeleteTeacherUploadDocumentFile = true;
                state.teacherDeleteUploadDocumentFileError = null;
            })
            .addCase(deleteTeacherDocuments.fulfilled, (state, action) => {
                state.isLoadingDeleteTeacherUploadDocumentFile = false;
                state.teacherDeleteUploadDocumentFileData = action.payload;
            })
            .addCase(deleteTeacherDocuments.rejected, (state, action) => {
                state.isLoadingDeleteTeacherUploadDocumentFile = false;
                state.teacherDeleteUploadDocumentFileError = action.payload;
            })
    },
});
// Export the reducer

export const { teacherState } = teacherSlice.actions;
export default teacherSlice.reducer;
