import React, { useRef } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import ApplicationButton from "../applicationButton/ApplicationButton";
import { Modal } from "react-bootstrap";
import { Icons } from "../../constants/Icons";
import CommonDropdown from "../applicationDropdown/ApplicationDropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Modal.css";

// Dropdown items for subjects
const subjects = [
  "English",
  "Hindi",
  "Science",
  "Social Science",
  "Mathematics",
];

const SubjectModal = ({ show, handleClose, modalTitle }) => {
  const datePickerRef = useRef(null);

  // Formik initialization with Yup validation schema
  const formik = useFormik({
    initialValues: {
      subject: null,
      from: null,
      to: null,
      optionalSubject: false, // Checkbox for optional subject
      subjectType: "Compulsory", // Default value for subject type
    },
    validationSchema: yup.object({
      subject: yup
        .string()
        .required("Subject is required"),
      from: yup
        .date()
        .required("Start date is required"),
      to: yup
        .date()
        .required("End date is required")
    }),
    onSubmit: async (values, { resetForm }) => {
      // Determine if the subject is optional or compulsory
      const subjectType = values.optionalSubject ? "Optional" : "Compulsory";

      // Log form values including the subject type

      // Reset the form
      resetForm();

      // Handle form submission (e.g., API call)
      // Add your API submission logic here
    },
  });

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="customModal"
        backdrop="static"
        centered
        size="lg"
      >
        <Modal.Header>
          <div className="d-flex align-items-center justify-content-between w-100">
            <p className="mb-0 font16 fw500">{modalTitle}</p>
            <div>
              <img
                src={Icons.crossSky}
                alt="cross"
                className="crossModal"
                onClick={handleClose}
              />
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <CommonDropdown
                  label={"Select Subject"}
                  placeHolder={"Select Subject"}
                  name="subject"
                  customLabelStyle="greyLabel"
                  title={formik.values.subject}
                  onSelect={(selectedValue) => formik.setFieldValue('subject', selectedValue)}
                  items={subjects}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.subject}
                  error={
                    formik.touched.subject && formik.errors.subject
                      ? formik.errors.subject
                      : null
                  }
                />
              </div>
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-6">
                    <label
                      htmlFor="from"
                      className="label-text-style fw500 greyLabel"
                    >
                      From
                    </label>
                    <div className="position-relative">
                      <DatePicker
                        selected={formik.values.from}
                        onChange={(date) =>
                          formik.setFieldValue("from", date)
                        }
                        onBlur={formik.handleBlur}
                        dateFormat="yyyy-MM-dd"
                        className="inline-style fw500 w-100 greyInput"
                        placeholderText="YYYY-MM-DD"
                        ref={datePickerRef}
                      />
                      <div
                        className="datePickerIcon"
                        onClick={() => datePickerRef.current.setFocus()}
                      >
                        <img src={Icons.greyCalenderIcon} alt="date" />
                      </div>
                      {formik.touched.from && formik.errors.from ? (
                        <div className="Inputerror">{formik.errors.from}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label
                      htmlFor="to"
                      className="label-text-style fw500 greyLabel"
                    >
                      To
                    </label>
                    <div className="position-relative">
                      <DatePicker
                        selected={formik.values.to}
                        onChange={(date) => formik.setFieldValue("to", date)}
                        onBlur={formik.handleBlur}
                        dateFormat="yyyy-MM-dd"
                        className="inline-style fw500 w-100 greyInput"
                        placeholderText="YYYY-MM-DD"
                      />
                      <div
                        className="datePickerIcon"
                        onClick={() => datePickerRef.current.setFocus()}
                      >
                        <img src={Icons.greyCalenderIcon} alt="date" />
                      </div>
                      {formik.touched.to && formik.errors.to ? (
                        <div className="Inputerror">{formik.errors.to}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="checkboxBox">
                  <input
                    id="custom-checkbox"
                    name="optionalSubject"
                    type="checkbox"
                    className=""
                    checked={formik.values.optionalSubject}
                    onChange={formik.handleChange}
                  />
                  <p className="labelCheckbox font12 fw500 mb-0">
                    Mark as Optional
                  </p>
                </div>
              </div>
            </div>
            <div className="row pt30 justify-content-end">
              <div className="col-md-2">
                <ApplicationButton btnText={"Save"} type="submit" className="blueheight40" />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SubjectModal;
