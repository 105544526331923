import React, { useEffect, useState } from "react";
import UserLayout from "../../components/layout/UserLayout";
import ApplicationHeader from "../../components/applicationHeader/ApplicationHeader";
import "./Classes.css";
import { Images } from "../../constants/Images";
import ApplicationButton from "../../components/applicationButton/ApplicationButton";
import AddClassModal from "../../components/modal/addClass/AddClassModal";
import { useDispatch, useSelector } from "react-redux";
import decodeJWT from "../../utils/Decode";
import { getAllClasses } from "../../redux/reduxToolkit/actions/ClassesAction";
import CommonSpinner from "../../components/spinner/Spinner";
import { useNavigate } from "react-router-dom";
const Classes = () => {
    localStorage.removeItem("eventResponse");
    const [showAddClassModal, setShowAddClassModal] = useState(false);
    const navigation = useNavigate();
    const dispatch = useDispatch();
    const token = localStorage.getItem("token");
    const encodedToken = decodeJWT(token);
    const workspaceIdd = encodedToken?.payload?.workspaceId;
    let {
        getAllClassesData,
        isLoadingGetAllClassesData,
        getAllClassesDataError, addClassDetails, isLoadingAddClassDetails, addClassDetailsError, addSectionDetails
    } = useSelector((state) => state?.classes);
    useEffect(() => {
        dispatch(getAllClasses(workspaceIdd));
    }, [dispatch, workspaceIdd, addClassDetails, addSectionDetails]);
    const handleClasses = (item) => {
        console.log(item, "handleClasses")
        // localStorage.setItem(item, "selectedClass")
        navigation('/classes/classesDetails', { state: { item } });
    }
    return (
        <UserLayout>
            <ApplicationHeader
                title="Classes"
                btnRightText="+Add Class"
                btnRightClassName={"button40"}
                onClickBtnRight={() => setShowAddClassModal(true)}
            />
            {!isLoadingGetAllClassesData ? (
                <>
                    {Array.isArray(getAllClassesData) && getAllClassesData.length > 0 ? (
                        <>
                            <div className="row">
                                {getAllClassesData?.map((item, index) => (
                                    <div className="col-md-3" key={index} onClick={() => handleClasses(item)}>
                                        <div className="class-card">
                                            <div className="class-title">Class-{item.name}</div>
                                            <div className="student-count">
                                                Total No. of Students:{" "}
                                                {item.totalStudents ? item.totalStudents : 0}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="classesContainer">
                                <div className="row">
                                    <div className="col-md-12">
                                        <p className="font30 fw600 mb-0">Class Management</p>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 img-div-magement">
                                        <img
                                            src={Images.classManagementImage}
                                            alt="class-management"
                                            className="class-maganegement-img"
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 img-div-magement">
                                        <ApplicationButton
                                            btnText="Add Class"
                                            className={"blueheight50 width-common-btn"}
                                            onClick={() => setShowAddClassModal(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </>
            ) : (
                <CommonSpinner />
            )}
            <AddClassModal
                show={showAddClassModal}
                handleClose={() => setShowAddClassModal(false)}
                title="Class"
            />
        </UserLayout>
    );
};

export default Classes;
