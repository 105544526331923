import React from "react";
import "./Student.css";
import { Icons } from "../../../constants/Icons";
const paymentData = [
  { date: "3 Mar 2023", amount: "INR 52,526" },
  { date: "3 Mar 2023", amount: "INR 52,526" },
  { date: "3 Mar 2023", amount: "INR 52,526" },
  { date: "3 Mar 2023", amount: "INR 52,526" },
  { date: "3 Mar 2023", amount: "INR 52,526" },
];

const FeesTab = () => {
  return (
    <>
      <div className="fesstabContainer studentOverviewContainer">
        <div class="row w-100">
          <div className="col-md-4">
            <div className="amountRemainingClass">
              <p className="font18 fw400 mb-0 amountRemaining">Amount Remaining</p>
              <p className="font22 fw600 mb-0 amountRemainingText">₹ 1,59,698</p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="totalAmountClass">
              <p className="font18 fw400 mb-0 amountRemaining">Total Amount</p>
              <p className="font22 fw600 mb-0 amountRemainingText">₹ 1,59,698</p>
            </div>
          </div>
        </div>
        <div class="row  w-100 payment-section-row">
          <div className="col-md-6">
            <div className="payment-history-container">
              <div className="header-payment">
                <div>
                  <p className="font18 fw600 mb-0">
                    Payment Expected
                  </p>
                </div>

              </div>
              <div className="paymentHistoryBox">
                <table className="payment-history-table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paymentData.map((payment, index) => (
                      <tr key={index} >
                        <td>{payment.date}</td>
                        <td>{payment.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="payment-history-container">
              <div className="header-payment">
                <div>
                  <p className="font18 fw600 mb-0">
                    Payment History
                  </p>
                </div>
                <button className="download-button">
                  <img src={Icons.downloadIcon} className="download-icon" alt="d-icon" />
                </button>
              </div>
              <div className="paymentHistoryBox">
              <table className="payment-history-table">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Amount</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {paymentData.map((payment, index) => (
                    <tr key={index} >
                      <td>{payment.date}</td>
                      <td>{payment.amount}</td>
                      <td className="text-end cursor-pointer">
                        <img src={Icons.downloadIcon} alt="d-icon" className="download-icon" />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeesTab;
